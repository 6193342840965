import React from "react";
import { Button, Tag, Image, Popover } from "antd";
import "../../../../App.css";
import { colors } from "../../utils/colors";
import editIcon from "../../../../assets/icons/edit.svg";
import noteIcon from "../../../../assets/icons/note.svg";

export const getColumns = ({
  editDetail,
  popoverContent,
  redirect,
  getNotesById,
  setIsEdit,
}) => [
    {
      title: "AGENT NAME",
      key: "1",
      dataIndex: "ACAG_Name",
      width: "11.11%",
      render: (_, object) => {
        return (
          <Button type="link" onClick={() => redirect(object.ACAG_KeyID)}>
            {object.ACAG_Name}
          </Button>
        );
      },
    },
    {
      title: "AGENT EMAIL",
      key: "9",
      dataIndex: "ACAG_Email",
      width: "11.11%",
    },
    {
      title: "AGENCY NAME",
      key: "2",
      dataIndex: "ACAG_Agency_Name",
      width: "11.11%",
    },
    {
      title: "AVAILABLE POINTS",
      key: "3",
      dataIndex: "ACAG_Available_Points",
      width: "11.11%",
      align: "right",
      render: (_, data) => {
        return (
          <p
            style={{
              textAlign: "right",
            }}
          >
            {_?.toLocaleString()}
          </p>
        );
      },
    },
    {
      title: "REDEEMABLE POINTS",
      key: "4",
      dataIndex: "ACAG_Points_Redeemed",
      width: "11.11%",
      align: "right",
      render: (_, data) => {
        return (
          <p
            style={{
              textAlign: "right",
            }}
          >
            {_?.toLocaleString()}
          </p>
        );
      },
    },
    {
      title: "PENDING REQUESTS",
      key: "5",
      dataIndex: "PendingRequest",
      width: "11.11%",
      align: "right",
      render: (_, data) => {
        return (
          <p
            style={{
              textAlign: "right",
            }}
          >
            {_?.toLocaleString()}
          </p>
        );
      },
    },
    {
      title: "STATUS",
      key: "6",
      dataIndex: "ACAG_Is_Active",
      width: "11.11%",
      render: (_, object) => (
        <Tag
          style={{
            border: "none",
            width: 66,
            textAlign: "center",
            borderRadius: 10,
            color: colors.grey900,
            backgroundColor: object.ACAG_Is_Active
              ? colors.green500
              : colors.red300,
          }}
        >
          {object.ACAG_Is_Active ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "NOTES",
      key: "7",
      dataIndex: "notes",
      width: "11.11%",
      render: (_, object) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Popover
            placement="leftTop"
            title={
              <p style={{ fontSize: 14, fontWeight: 500, color: colors.black }}>
                Notes
              </p>
            }
            content={popoverContent}
            trigger="click"
          >
            <Button type="link" onClick={() => getNotesById(object?.ACAG_KeyID)}>
              <Image src={noteIcon} alt="normandy" preview={false} />
            </Button>
          </Popover>
        </div>
      ),
    },
    {
      title: "EDIT",
      key: "8",
      dataIndex: "edit",
      width: "11.11%",
      render: (_, object) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="link">
            <Image
              src={editIcon}
              onClick={() => {
                setIsEdit((prevState) => !prevState);
                editDetail(object.ACAG_KeyID);
              }}
              alt="normandy"
              preview={false}
            />
          </Button>
        </div>
      ),
    },
  ];
