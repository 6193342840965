import React from "react";
import { useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Table } from "antd";
import './style.css'

import { colors } from "../../utils/colors";

const TableComponent = (props) => {
  let [searchParams] = useSearchParams({
    page: '1'
  });
  searchParams.get('page') === null && searchParams.set('page', '1');
  let currentPage = searchParams.get("page");

  return (
    <div style={{ position: "relative" }}>
      <Table
        rowKey="ACAG_KeyID"
        ref={props.tableRef}
        rowSelection={
          !props.rowSelection
            ? null
            : {
              onChange: (_, selectedRows) => {
                props.setSelectedRows(selectedRows);
              },
            }
        }
        style={{
          border: `1px solid ${colors.grayNeutral100}`,
          borderRadius: 6,
        }}
        size="small"
        className="tableHeight table-component"
        dataSource={props.data}
        loading={props.loading}
        columns={props.columns}
        pagination={
          props.isServerPagination
            ? {
              current: parseInt(currentPage),
              total: props.totalRecords,
              pageSize: props.pagination.pageSize,
              onChange: props.onPaginationChange,
              showQuickJumper: true,
              showSizeChanger: true,
              showTitle: true,
              showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} results`,
              style: { paddingRight: 20 },
            }
            : {
              defaultCurrent: 1,
            }
        }
        scroll={{ x: "max-content", y: "calc(100vh - 310px)" }}
      />
    </div>
  );
};

TableComponent.propTypes = {
  data: PropTypes.array,
  totalRecords: PropTypes.number,
  tableData: PropTypes.array,
  loading: PropTypes.bool,
  border: PropTypes.bool,
  rowSelection: PropTypes.bool,
  columns: PropTypes.array,
  isServerPagination: PropTypes.bool,
  pagination: PropTypes.any,
  onPaginationChange: PropTypes.func,
  setSelectedRows: PropTypes.func,
};

export default TableComponent;
