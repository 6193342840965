import { DEFAULT_USER_TYPE, USER_ROLE } from '../Constants/ApplicationConstants'
import { RoutesLookup } from './RoutesLookup'

const getDefaultLoginRoutesForRole = (role) => {
  switch (role) {
    case USER_ROLE.SUPER_ADMIN:
      return RoutesLookup.REWARD_INVENTORY
    case USER_ROLE.ADMIN:
      return RoutesLookup.REVIEW_REQUESTS
    case USER_ROLE.AGENT:
      return RoutesLookup.AGENT_DASHBOARD
    default:
      return `/${DEFAULT_USER_TYPE}${RoutesLookup.LOGIN}`
  }
}

export default getDefaultLoginRoutesForRole