import {
  Button,
  Col,
  Form,
  Input,
  Row,
  theme,
  Image,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import UserContext from '../../../Contexts/UserContext';
import { colors } from "../../../AdminPortal/utils/colors";
import logo from "../../../../assets/logo.png";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import { RoutesLookup } from '../../../Routes/RoutesLookup';
import { USER_ROLE } from '../../../Constants/ApplicationConstants';
import { getRoleFromAuth } from '../../../../utils/commonUtils';

const { Title } = Typography;

const AgentForgotPassword = () => {
  const { auth, setAuth } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = theme.useToken();
  const colorBgLoginLayout = token['colorBgLoginLayout']

  useEffect(() => {
    if (auth?.token && getRoleFromAuth(auth) !== USER_ROLE.ADMIN) {
      navigate(RoutesLookup.AGENT_DASHBOARD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [forgotPasswordForm] = Form.useForm();

  const loginUser = async (values) => {
    const email = values?.email;
    setLoading(true);
    let url = `${BASE_URL}${RoutesLookup.EMAIL_CODE}?agentEmail=${email}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data?.success) {
          setAuth(res?.data);
          setLoading(false);
          message.success("Send Code successfully.");
          forgotPasswordForm.resetFields();

          navigate(`/${RoutesLookup.VERIFICATION_CODE}/${email}`);
        } else {
          message.error(`${res?.data?.message}`);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error in agent forgot password", error.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          verticalAlign: "middle",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: colorBgLoginLayout,
            width: "55%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: isMobile ? "none" : "flex",
          }}
        >
          <div>
            <Image
              style={{
                marginBottom: 20,
                width: "357px",
                height: "53px",
                objectFit: "cover",
              }}
              preview={false}
              src={logo}
            />
          </div>
        </div>
        <div
          style={{
            width: isMobile ? "100%" : "45%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: 350 }}>
            <div>
              <Title
                level={3}
                style={{
                  marginBottom: 15,
                  marginTop: 0,
                  color: colors.grey900,
                }}
              >
                Enter Email Address
              </Title>
              <div
                style={{
                  width: "365px",
                  height: "43px",
                  marginBottom: "44px",
                  color: "#737373",
                }}
              >
                Please enter your email to send your verification code.
              </div>
            </div>
            <Form
              form={forgotPasswordForm}
              layout="vertical"
              onFinish={loginUser}
            >
              <div>
                <Form.Item
                  label="Email address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      height: 42,
                      border: `1px solid ${colors.grayNeutral300}`,
                    }}
                    autoComplete="off"
                    size="large"
                  />
                </Form.Item>
              </div>
              <Row style={{ marginTop: 28 }}>
                <Col span={24}>
                  <Button
                    loading={loading}
                    block
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    Recover Password
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentForgotPassword;
