import {
  Button,
  DatePicker,
  Form,
  Modal,
  Select,
  Space,
  Typography,
  Avatar,
  Spin,
} from "antd";
import React, { useEffect } from "react";
import useSWR from "swr";
import "./report.css";
import { useSearchParams } from "react-router-dom";
import { getSearchParams } from "../../../utils/helpers";

import { BASE_URL } from "../../../../Constants/EnvironmentVariableProvider";
import { ALL_AGENTS_API } from "../../../../API/paths";
import { DATAGRID_DEFAULT_PAGE_SIZE } from "../../../../Constants/ApplicationConstants";

const { RangePicker } = DatePicker;

function AddAgentModal({ open, setOpen, handleSubmit, form }) {
  let [spObj] = useSearchParams({
    page: "1",
    pageSize: DATAGRID_DEFAULT_PAGE_SIZE.toString(),
  });
  spObj.get('page') === null && spObj.set('page', '1');
  spObj.get('pageSize') === null && spObj.set('pageSize', DATAGRID_DEFAULT_PAGE_SIZE.toString());

  const [id, page, pageSize, filterName, statusType] = getSearchParams(spObj);
  const {
    data: agents,
    mutate,
    isValidating: loading,
  } = useSWR({
    url: BASE_URL + ALL_AGENTS_API,
  });

  const data = agents?.result?.map((item) => {
    return {
      ...item,
      label: (
        <>
          {" "}
          <Avatar
            src={item?.ACAG_Image_Path}
            key={"add-agent-model-avatar-" + item}
          />{" "}
          {item?.ACAG_Name}
        </>
      ),
      value: item?.ACAG_KeyID,
    };
  });
  useEffect(() => {
    if (mutate) mutate();
  }, [filterName, pageSize, page, mutate, statusType]);

  return (
    <Modal closable={false} open={open} width={645} footer={null}>
      <Spin spinning={loading && id === "true"}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          style={{
            padding: 42,
          }}
        >
          <div
            style={{
              marginBottom: 16,
            }}
          >
            <Typography.Text
              style={{
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 24,
                color: "#000000",
              }}
            >
              {id === "true" ? "Add Agent" : "Select Range"}
            </Typography.Text>
          </div>

          {id === "true" && (
            <Form.Item
              label="Agent Name"
              name="selected_agent"
              rules={[
                {
                  required: true,
                  message: "Please select an agent",
                },
              ]}
            >
              <Select
                size="large"
                showSearch
                optionLabelProp="label"
                optionFilterProp="children"
                style={{ width: "100%" }}
                placeholder="Select Agent"
                options={data}
                filterOption={(input, option) =>
                  (option?.label?.props?.children[3] ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              ></Select>
            </Form.Item>
          )}

          <Form.Item
            label="Date"
            name="date"
            rules={[
              {
                required: true,
                message: "Please select Range",
              },
            ]}
          >
            <RangePicker
              format="MM-DD-YYYY"
              size="large"
              style={{
                width: "100%",
              }}
            />
          </Form.Item>

          <div style={{ textAlign: "end", marginTop: "32px" }}>
            <Space direction="horizontal" size={20}>
              <Button
                style={{ color: "#171717" }}
                type="text"
                onClick={() => {
                  setOpen(false);
                  form.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  width: "97px",
                  padding: "8px, 32px, 8px, 32px",
                  gap: "8px",
                  borderRadius: "4px",
                  backgroundColor: "#213E7C",
                }}
              >
                Save
              </Button>
            </Space>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
}

export default AddAgentModal;
