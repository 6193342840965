import React from "react";
import { Button, Row, Col, Typography, Space } from "antd";
import { TwitterOutlined, InstagramOutlined } from "@ant-design/icons";
import { colors } from "../utils/colors";

export default function Footer() {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#eee",
          display: "flex",
          justifyContent: "center",
          borderTop: `1px solid ${colors.primaryGreen}`,
        }}
      >
        <div style={{ padding: "40px 10px" }}>
          <Space size={12}>
            <p style={{ fontSize: 26, color: colors.primaryGreen }}>
              <strong>Contact the rewards team</strong>
            </p>
            <p style={{ fontSize: 16, color: colors.primaryGreen }}>
              <b>Call: </b>
              <span className="underline-hover border-bottom-hover underline">
                866-688-6442
              </span>
            </p>
            <p style={{ fontSize: 16, color: colors.primaryGreen }}>
              <b>Email: </b>
              <span className="underline-hover border-bottom-hover underline">
                info@normandyins.com
              </span>
            </p>
          </Space>
        </div>
      </div>
      <div style={{ background: colors.primaryGreen }}>
        <Row style={{ padding: "40px 10px" }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
            className="gutter-row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <Space direction="vertical" style={{ textAlign: "center" }}>
                <Typography.Text
                  style={{ color: colors.white, cursor: "pointer" }}
                  className="underline-hover border-bottom-hover underline"
                  onClick={() =>
                    window.open(
                      "https://www.normandyins.com/about-us",
                      "_blank"
                    )
                  }
                >
                  About Us
                </Typography.Text>
                <Space style={{ color: colors.white, cursor: "pointer" }}>
                  <Typography.Text
                    className="underline-hover border-bottom-hover underline"
                    style={{ color: colors.white, cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.normandyins.com/our-mission",
                        "_blank"
                      )
                    }
                  >
                    Our Mission
                  </Typography.Text>
                  |
                  <Typography.Text
                    className="underline-hover border-bottom-hover underline"
                    style={{ color: colors.white, cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.normandyins.com/careers",
                        "_blank"
                      )
                    }
                  >
                    Careers
                  </Typography.Text>
                </Space>
                <Typography.Text
                  style={{ color: colors.white, cursor: "pointer" }}
                  className="underline-hover border-bottom-hover underline"
                  onClick={() =>
                    window.open(
                      "https://www.normandyins.com/press-release",
                      "_blank"
                    )
                  }
                >
                  Press Release
                </Typography.Text>
              </Space>
            </div>
          </Col>
          <Col
            className="gutter-row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
          >
            <div>
              <Space direction="vertical" style={{ textAlign: "center" }}>
                <p style={{ color: colors.white, fontSize: 16 }}>
                  <strong>Follow Us!</strong>
                </p>
                <div className="social-icons">
                  <Button
                    className="facebook"
                    shape="circle"
                    onClick={() =>
                      window.open(
                        "https://www.facebook.com/normandyins/",
                        "_blank"
                      )
                    }
                    icon={<strong style={{ color: colors.white }}>f</strong>}
                  />
                  <Button
                    className="linkedin"
                    shape="circle"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/normandyins/",
                        "_blank"
                      )
                    }
                    icon={<strong style={{ color: colors.white }}>in</strong>}
                  />
                  <Button
                    className="twitter"
                    shape="circle"
                    onClick={() =>
                      window.open("https://twitter.com/NormandyIns", "_blank")
                    }
                    icon={<TwitterOutlined />}
                  />
                  <Button
                    className="instagram"
                    shape="circle"
                    onClick={() =>
                      window.open(
                        "https://www.instagram.com/normandy_ins/?hl=en",
                        "_blank"
                      )
                    }
                    icon={<InstagramOutlined />}
                  />
                </div>
              </Space>
            </div>
          </Col>
          <Col
            className="gutter-row"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            xxl={8}
          >
            <div>
              <Space direction="vertical" style={{ textAlign: "center" }}>
                <Typography.Text style={{ color: colors.white }}>
                  Normandy Insurance Company
                  <br />
                  Copyright © 2023. All Rights Reserved
                  <br />
                </Typography.Text>
                <space style={{ color: colors.white }}>
                  <Typography.Text
                    style={{ color: colors.white, cursor: "pointer" }}
                    className="underline-hover border-bottom-hover underline"
                    onClick={() =>
                      window.open(
                        "https://www.normandyins.com/terms-and-conditions",
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    Terms & Conditions
                  </Typography.Text>{" "}
                  |{" "}
                  <Typography.Text
                    style={{ color: colors.white, cursor: "pointer" }}
                    className="underline-hover border-bottom-hover underline"
                    onClick={() =>
                      window.open(
                        "https://www.normandyins.com/privacy-policy",
                        "_blank"
                      )
                    }
                  >
                    Privacy Policy
                  </Typography.Text>
                </space>
              </Space>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
