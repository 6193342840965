import React, { useState } from "react";
import PropTypes from "prop-types";
import { Space, Image, Typography, Button, Tag, Tooltip } from "antd";

import { colors } from "../../utils/colors";
import "../../../../App.css";

import rightArrow from "../../../../assets/icons/rightArrow.svg";
import request from "../../../../assets/reqest.png";
import downArrow from "../../../../assets/icons/downArrow.svg";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import VectorImage from "../../../../assets/icons/Vector.svg";
import { styles } from "./style";

const { Text } = Typography;
const RewardForRedeemConfirmationCard = ({
  id,
  imgSource,
  title,
  onCancel,
  name,
  status,
  requestDate,
  productName,
  email,
  availablePoints,
  radeemedPoints,
  onAccept,
  ARIT_Cancel_Reason,
  tbl_ORAD_Admins_ApprovedBy,
  quantity,
  selectedVariant,
  onHold,
  address,
}) => {
  const [changeIcon, setChangeIcon] = useState(false);
  let dumyArry = [
    {
      titleLeft: "Agent Name:",
      leftValue: name,
      titleRight: "Agent Email:",
      RightValue: email,
      showStatus: true,
    },
    {
      titleLeft: "Available Points",
      leftValue: availablePoints,
      titleRight: "Redeemed Points",
      RightValue: radeemedPoints,
    },
    {
      titleLeft: "Product",
      leftValue: productName,
      titleRight: "Selected Variant",
      RightValue: selectedVariant == null ? "None" : selectedVariant,
    },
    {
      titleLeft: "Quantity",
      leftValue: quantity,
      titleRight: "Approval Status",
      RightValue: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              borderRadius: 100,
              width: 8,
              height: 8,
              backgroundColor:
                status === 1
                  ? colors.orange500
                  : status === 2
                    ? colors?.green500
                    : status === 3
                      ? colors.red300
                      : "",
            }}
          />
          <Tag
            style={{
              border: "none",
              width: 66,
              marginLeft: 4,
              textAlign: "center",
              borderRadius: 10,
              color: colors.grey900,
              backgroundColor:
                status === 1
                  ? colors.orange500
                  : status === 2
                    ? colors?.green500
                    : status === 3
                      ? colors.red300
                      : "",
            }}
          >
            {status === 2
              ? "Shipped"
              : status === 1
                ? "Ordered"
                : status === 3
                  ? "Canceled"
                  : status === 4
                    ? "Partially Shipped"
                    : status === 5
                      ? "On-Hold"
                      : ""}
          </Tag>
        </div>
      ),
    },
    {
      titleLeft: "Address",
      leftValue: address,
    },
    {
      titleLeft:
        status === 1
          ? "Redemption Date"
          : status === 2
            ? "Approval Date"
            : status === 3
              ? "Cancellation Date"
              : status === 5
                ? "On Hold Date"
                : "",
      leftValue: requestDate,
      titleRight:
        status === 2
          ? "Approved By"
          : status === 3
            ? "Canceled By"
            : status === 1
              ? ""
              : status === 5
                ? "On-Hold By"
                : "",

      RightValue: tbl_ORAD_Admins_ApprovedBy,
    },
  ];
  const detailBtnHandler = () => {
    setChangeIcon(!changeIcon);
  };

  return (
    <div className="hoverCard" style={styles.outerContainer}>
      <div style={styles.smallCardContainer}>
        <Space direction="horizontal" size={16}>
          <div style={{ width: 64, height: 64 }}>
            <Image
              style={{
                borderRadius: 4,
                border: `1px solid ${colors.grayNeutral300}`,
              }}
              preview={false}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = request;
              }}
              src={imgSource}
              alt="normandy"
              width={64}
              height={64}
            />
          </div>
          <Space direction="vertical" size={0}>
            <div style={{ width: 288 }}>
              {" "}
              <Text style={styles.cardTitle}>{title}</Text>
            </div>
            <Space direction="horizontal" size={6}>
              <Button
                style={styles.detailBtn}
                onClick={detailBtnHandler}
                type="link"
              >
                View Detail
              </Button>

              <Image
                style={{ marginBottom: 3 }}
                preview={false}
                src={changeIcon ? downArrow : rightArrow}
                alt="icon"
              />
            </Space>
          </Space>
        </Space>

        {status === 2 || status === 3 ? (
          status === 2 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
              }}
            >
              <Tag
                style={{
                  border: "none",
                  display: "flex",
                  height: 29,
                  verticalAlign: "middle",
                  alignItems: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  color: colors.grey900,
                  backgroundColor: colors?.green500,
                }}
              >
                {" "}
                Shipped
              </Tag>
            </div>
          ) : status === 3 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                verticalAlign: "middle",
                alignItems: "center",
              }}
            >
              <Tag
                style={{
                  border: "none",
                  width: 66,
                  display: "flex",
                  height: 29,
                  verticalAlign: "middle",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  borderRadius: 10,
                  backgroundColor: colors?.red300,
                }}
              >
                {" "}
                Canceled
              </Tag>
            </div>
          ) : (
            ""
          )
        ) : (
          <Space direction="horizontal" size={6}>
            <div onClick={() => onCancel(id)} style={{ cursor: "pointer" }}>
              <CloseOutlined style={styles.approvalBtnContainer} />
            </div>
            {status !== 5 ? (
              <div onClick={() => onHold(id)} style={{ cursor: "pointer" }}>
                <Image
                  style={{
                    padding: 10,
                    background: "#EAEFF6",
                    borderRadius: 500,
                  }}
                  preview={false}
                  src={VectorImage}
                ></Image>
              </div>
            ) : status === 5 ? (
              <Tooltip title="This request is on hold state.">
                <p
                  style={{
                    padding: 10,
                    borderRadius: "13%",
                    background: "#EAEFF6",
                  }}
                >
                  Hold
                </p>
              </Tooltip>
            ) : (
              ""
            )}
            <div onClick={() => onAccept(id)} style={{ cursor: "pointer" }}>
              <CheckOutlined
                style={{
                  ...styles.approvalBtnContainer,
                  background: colors.green500,
                }}
              />
            </div>
          </Space>
        )}
      </div>
      {/* card details */}
      {changeIcon && (
        <div style={styles.detailsContainer}>
          <div style={{ padding: 24 }}>
            {dumyArry.map((item, i) => (
              <div
                key={item.titleLeft}
                style={
                  i === 0
                    ? styles.detailItemContainer
                    : { ...styles.detailItemContainer, marginTop: 20 }
                }
              >
                <Space direction="vertical" size={6}>
                  <Text style={styles.title}>{item.titleLeft}</Text>
                  <Text style={styles.text}>{item.leftValue}</Text>
                </Space>
                <Space direction="vertical" size={6}>
                  <Text
                    style={
                      i === 3 || 2
                        ? {
                          fontSize: 12,
                          fontWeight: 600,
                          color: colors.grey900,
                          float: "right",
                        }
                        : styles.title
                    }
                  >
                    {item.titleRight}
                  </Text>
                  {item.showStatus ? (
                    <Space
                      style={{
                        float: "right",
                      }}
                      direction="horizontal"
                      size={4}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: colors.grey900,
                        }}
                      >
                        {item.RightValue}
                      </Text>
                    </Space>
                  ) : (
                    <Space
                      style={{
                        float: "right",
                      }}
                      direction="horizontal"
                      size={4}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: colors.grey900,
                        }}
                      >
                        {item.RightValue}
                      </Text>
                    </Space>
                  )}
                </Space>
              </div>
            ))}
            {status === 3 ? (
              <Space direction="vertical" style={{ marginTop: 5 }} size={6}>
                <Text style={styles.title}>Cancelation Reason</Text>
                <Text style={styles.text}>{ARIT_Cancel_Reason}</Text>
              </Space>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
};

RewardForRedeemConfirmationCard.propTypes = {
  imgSource: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  requestDate: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  lastActivityDate: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  tbl_ORAD_Admins_ApprovedBy: PropTypes.string.isRequired,
  ARIT_Cancel_Reason: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  availablePoints: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  radeemedPoints: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
};

export default RewardForRedeemConfirmationCard;
