import axios from "axios";
import initAxiosGlobalConfigs, {
  clearAxiosAuth,
} from "../App/API/axios.Config";
import { message } from "antd";

import { BASE_URL } from "../App/Constants/EnvironmentVariableProvider";
import { GET_ADMIN_CLAIM_DATA, GET_AGENT_CLAIM_DATA } from "../App/API/paths";
import getDefaultLoginRoutesForRole from "../App/Routes/DefaultLoginRoutesForRole";
import { getRoleFromAuth } from './commonUtils';
import { USER_TYPES } from '../App/Constants/ApplicationConstants';
import { RoutesLookup } from '../App/Routes/RoutesLookup';

export const msalLogoutUsers = (
  msalInstance,
  auth,
  setAuth,
  setOpen,
  navigate
) => {
  setOpen(false);
  doLogout(msalInstance, setAuth);
  navigate(RoutesLookup.LOGOUT);
};
export const clearAuth = (setAuth) => {
  setAuth({});
};
export const clearMsal = (msalInstance) => {
  msalInstance["browserStorage"]?.clear();
  msalInstance["memoryStorage"]?.clear();
  msalInstance.clearCache();
};
export const clearStorages = () => {
  localStorage.clear();
  sessionStorage.clear();
};
export const doClear = (msalInstance) => {
  clearMsal(msalInstance);
  clearStorages();
  clearAxiosAuth();
}
export const doLogout = (msalInstance, setAuth) => {
  const account = msalInstance.getActiveAccount();
  clearAuth(setAuth);
  doClear(msalInstance)
  msalInstance.logout({
    account,
  });
};

export const LoginByRole = (
  accessToken,
  setAuth,
  navigate,
  msalInstance,
  userType
) => {
  let urlForGet = `${BASE_URL}${GET_AGENT_CLAIM_DATA}`;
  if (userType === USER_TYPES.ADMIN) {
    urlForGet = `${BASE_URL}${GET_ADMIN_CLAIM_DATA}`;
  } else if (userType === USER_TYPES.AGENT) {
    urlForGet = `${BASE_URL}${GET_AGENT_CLAIM_DATA}`;
  }

  localStorage.setItem('token', JSON.stringify(accessToken))

  const dataCall = axios.get(urlForGet, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  dataCall.then((res) => {
    if (res?.data?.success) {
      const auth = res.data;
      message.success("Login successful");
      setAuth(auth);
      initAxiosGlobalConfigs(auth);
      navigate(getDefaultLoginRoutesForRole(getRoleFromAuth(auth)));
    } else {
      showUnauthorizedMessage(msalInstance, message);
    }
  });
};

const showUnauthorizedMessage = (msalInstance, message) => {
  message.error("You are not authorized to access the application");
  message.info("Clearing your credentials and refreshing in 1 second");
  setTimeout(() => {
    msalInstance.logout();
  }, 3000);
};
