import React, { useEffect, useState } from "react";
import useSWR from "swr";
import { useDebouncedCallback } from "use-debounce";
import {
  Space,
  Input,
  Select,
  Row,
  Col,
  Modal,
  Form,
  Card,
  message,
  Button,
  Image,
  Typography,
} from "antd";
import {
  SearchOutlined,
  CloseOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useSearchParams } from "react-router-dom";

import { colors } from "../../utils/colors";
import TableComponent from "../../components/Table/TableComponent";
import {
  generateSearchParamsGetter,
  getNewSearchParamStringWithArgs,
  getSearchParams,
} from "../../utils/helpers";
import AdminAppLayout from "../../Layouts/AdminLayout";
import VectorImage from "../../../../assets/icons/icon.svg";

import "./style.css";
import { getColumns } from "./columns";
import CancelReasonForm from "./form";
import SideDrawer from "./SideDrawer";
import axios from "axios";

import { BASE_URL } from "../../../Constants/EnvironmentVariableProvider";
import {
  AGENTS_REWARDS_FILTER_DATA_API,
  UPDATE_AGENT_ITEM_STATUS_API,
  AGENT_DETAIL_API,
  UPDATE_AGENT_ALL_ITEM_STATUS_API,
  ORDER_STATUS_PUT,
} from "../../../API/paths";
import { DATAGRID_DEFAULT_PAGE_SIZE } from "../../../Constants/ApplicationConstants";

const { Text } = Typography;
const ReviewRequests = () => {
  let [spObj, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: DATAGRID_DEFAULT_PAGE_SIZE.toString(),
  });
  spObj.get('page') === null && spObj.set('page', '1');
  spObj.get('pageSize') === null && spObj.set('pageSize', DATAGRID_DEFAULT_PAGE_SIZE.toString());

  const [open, setOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [agentId, setAgentId] = useState();
  const [cancelRequestId, setCancelRequestId] = useState();
  const [redeemCardLoading, setRedeemCardLoading] = useState(false);
  const [disableStatus, setDisableStatus] = useState(false);
  const [holdButtonStatus, setHoldButtonStatus] = useState(false);
  const [agentRequest, setAgentRequest] = useState();
  const [orderStatusModal, setOrderStatusModal] = useState(false);
  const [orderStatusVal, setOrderStatusVal] = useState("Select Order Status");
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  let [id, page, pageSize, search, statusType, statusItem] =
    getSearchParams(spObj);

  const getNewSearchParamString = generateSearchParamsGetter(spObj);
  let url =
    BASE_URL + AGENTS_REWARDS_FILTER_DATA_API + getNewSearchParamString();
  const {
    data: requests,
    isValidating: loading,
    mutate,
  } = useSWR({
    url: url,
  });

  useEffect(() => {
    if (mutate) mutate();
  }, [pageSize, page, mutate, search]);


  const debounceFetcher = useDebouncedCallback((search) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page: 1,
        pageSize,
        statusType,
        statusItem,
        search,
      })
    );
  }, 600);
  const refreshRequest = (data) => {
    mutate(async () => {
      try {
        setRedeemCardLoading(true);
        const response = await axios.put(UPDATE_AGENT_ITEM_STATUS_API, data);
        if (response?.data?.success) {
          message.success(response?.data?.message);
          try {
            const response = await axios.get(
              `${BASE_URL}${AGENT_DETAIL_API}?KeyId=${agentId}`
            );
            setAgentRequest(response?.data?.result);
            const result = response?.data?.result?.filter(
              (item) =>
                item?.ARIT_Approved_Status === 2 ||
                item?.ARIT_Approved_Status === 3
            );
            if (result?.length === response?.data?.result?.length) {
              setDisableStatus(true);
            } else {
              setDisableStatus(false);
            }
            const holdResult = response?.data?.result?.filter(
              (item) =>
                item?.ARIT_Approved_Status === 5 ||
                item?.ARIT_Approved_Status === 2 ||
                item?.ARIT_Approved_Status === 3
            );
            if (holdResult?.length === response?.data?.result?.length) {
              setHoldButtonStatus(true);
            } else {
              setHoldButtonStatus(false);
            }
          } catch (e) {
            console.log(e);
          }
          setModalOpen(false);
          form.resetFields();
        }
        setRedeemCardLoading(false);
      } catch (e) {
        setRedeemCardLoading(false);
        console.log(e);
      }
    });
  };
  const refreshAllRequest = (data) => {
    mutate(async () => {
      try {
        const response = await axios.put(
          UPDATE_AGENT_ALL_ITEM_STATUS_API,
          data
        );
        if (response?.data?.success) {
          message.success(response?.data?.message);
          try {
            const response = await axios.get(
              `${BASE_URL}${AGENT_DETAIL_API}?KeyId=${agentId}`
            );
            setAgentRequest(response?.data?.result);
            const result = response?.data?.result?.filter(
              (item) =>
                item?.ARIT_Approved_Status === 2 ||
                item?.ARIT_Approved_Status === 3
            );
            if (result?.length === response?.data?.result?.length) {
              setDisableStatus(true);
            } else {
              setDisableStatus(false);
            }
            const holdResult = response?.data?.result?.filter(
              (item) =>
                item?.ARIT_Approved_Status === 5 ||
                item?.ARIT_Approved_Status === 2 ||
                item?.ARIT_Approved_Status === 3
            );
            if (holdResult?.length === response?.data?.result?.length) {
              setHoldButtonStatus(true);
            } else {
              setHoldButtonStatus(false);
            }
          } catch (e) {
            console.log(e);
          }
          setModalOpen(false);
          form.resetFields();

          setModalOpen(false);
          form.resetFields();
          setOpen(false);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
  const orderStatusHandler = (e) => {
    setOrderStatusVal(e);
    if (!orderStatusModal) {
      setOrderStatusModal(true);
      return;
    }
    const data = {
      ARAG_KeyID: agentId,
      ARAG_Dispatch_Status: e,
    };
    mutate(async () => {
      try {
        const response = await axios.put(ORDER_STATUS_PUT, data);
        if (response?.data?.success) {
          message.success(response?.data?.message);
          setOrderStatusModal(false);
        } else {
          message.error(response?.data?.message);
        }
      } catch (e) {
        console.log(e);
      }
    });
    // setOrderStatusVal('Select Order Status');
  };
  const onCancelRequest = (requestId) => {
    setCancelRequestId(requestId);
    setModalOpen(true);
    form.resetFields();
  };
  const onAcceptRequest = (requestId) => {
    const data = {
      ARIT_KeyID: requestId,
      ARIT_Approved_Status: 2,
    };
    refreshRequest(data);
  };
  const onHold = (requestId) => {
    const data = {
      ARIT_KeyID: requestId,
      ARIT_Approved_Status: 5,
    };
    refreshRequest(data);
  };

  const onAcceptAllRequest = () => {
    const data = {
      ARAG_KeyID: agentId,
      ARAG_Approved_Status: 2,
    };
    refreshAllRequest(data);
  };
  const onHoldAllRequest = () => {
    const data = {
      ARAG_KeyID: agentId,
      ARAG_Approved_Status: 5,
    };
    refreshAllRequest(data);
  };

  const handleSubmit = async (values) => {
    if (typeof cancelRequestId === "string") {
      const data = {
        ARAG_KeyID: agentId,
        ARAG_Cancel_Reason: values.reason,
        ARAG_Approved_Status: 3,
      };
      refreshAllRequest(data);
    } else {
      const data = {
        ARIT_KeyID: cancelRequestId,
        ARIT_Cancel_Reason: values.reason,
        ARIT_Approved_Status: 3,
      };
      refreshRequest(data);
    }
  };

  const onPaginationChange = async (page, pageSize) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page,
        pageSize,
        statusType,
        statusItem,
        search,
      })
    );
  };

  const handleSearch = (e) => {
    debounceFetcher(e.target.value);
    setSearchState(e.target.value)
  };
  const showDrawer = (requestId) => {
    setAgentId(requestId);
    setOpen(true);
    setOrderStatusVal("Select Order Status");
  };

  const columns = getColumns({ showDrawer });
  const onClose = () => {
    setOpen(false);
  };

  const StatusHandler = (event) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page: 1,
        pageSize,
        statusType: event,
        statusItem,
        search,
      })
    );
  };

  const ItemHandler = (event) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page: 1,
        pageSize,
        statusType,
        statusItem: event,
        search,
      })
    );
  };

  const [searchState, setSearchState] = useState(search ? search : '')
  useEffect(() => {
    setSearchState(search)
  }, [search])

  return (
    <AdminAppLayout>
      <div
        style={{
          paddingLeft: 60,
          paddingRight: 60,
          marginTop: 56,
          marginBottom: 40,
        }}
      >
        <Row
          style={{ marginTop: 50, marginBottom: 28 }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col
            className="gutter-row"
            span={8}
            style={{ display: "flex", alignItems: "end" }}
          >
            <div style={{ width: "100%" }}>
              <div style={{ marginBottom: "3px" }}>
                <Text>Search</Text>
              </div>
              <Input
                width={"100%"}
                size="large"
                placeholder="Search..."
                defaultValue={search}
                value={searchState}
                onChange={handleSearch}
                allowClear
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                    }}
                  />
                }
              />
            </div>
          </Col>
          <Col
            style={{ display: "flex", alignItems: "end" }}
            className="gutter-row"
            span={8}
          >
            <div>
              <div style={{ marginBottom: "3px" }}>
                <Text>Filter by Order Status:</Text>
              </div>
              <Select
                defaultValue={"0"}
                value={statusType ?? "0"}
                size="large"
                style={{ width: 200 }}
                onChange={StatusHandler}
                options={[
                  { value: "0", label: "All" },
                  { value: "3", label: "Shipped" },
                  { value: "2", label: "Partially Shipped" },
                  { value: "1", label: "Ordered" },
                  { value: "4", label: "Cancelled" },
                ]}
              />
            </div>

            <div style={{ marginLeft: "10px" }}>
              <div style={{ marginBottom: "3px" }}>
                <Text>Filter by Item Status:</Text>
              </div>
              <Select
                defaultValue={"0"}
                value={statusItem ?? "0"}
                size="large"
                style={{ width: 200 }}
                onChange={ItemHandler}
                options={[
                  { value: "0", label: "All" },
                  { value: "1", label: "Ordered" },
                  { value: "2", label: "Shipped" },
                  { value: "5", label: "On hold" },
                  { value: "3", label: "Cancelled" },
                ]}
              />
            </div>
          </Col>

          {/* Status card */}
          <Col span={8}>
            <div
              style={{
                width: "80%",
                background: colors.bgGrey,
                textAlign: "center",
                padding: 10,
              }}
            >
              <p style={{ fontSize: 11 }}>
                Total Agent Requests:{" "}
                <span style={{ fontWeight: 500, color: colors.black }}>
                  {requests?.totalRecords}
                </span>
              </p>
            </div>
            <Card
              style={{
                width: "80%",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              }}
            >
              <Space
                style={{
                  display: "flex",
                  verticalAlign: "middle",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <div
                  onClick={() => { }}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  <CheckOutlined
                    style={{
                      padding: 8,
                      background: colors.green500,
                      borderRadius: 500,
                    }}
                  />
                  <p style={{ fontSize: 11, marginTop: 3 }}>
                    <span style={{ fontWeight: 400, color: colors.black }}>
                      {requests && requests?.dispatchCount + " "}
                    </span>
                    Shipped
                  </p>
                </div>
                <div
                  onClick={() => { }}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  <Image
                    style={{
                      borderRadius: "100%",
                    }}
                    preview={false}
                    src={VectorImage}
                  ></Image>
                  <p style={{ fontSize: 11, marginTop: 3 }}>
                    <span style={{ fontWeight: 400, color: colors.black }}>
                      {requests && requests?.partiallyCount + " "}
                    </span>
                    Partially Shipped
                  </p>
                </div>
                <div
                  onClick={() => { }}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  <ClockCircleOutlined
                    style={{
                      background: colors.orange500,
                      padding: 8,
                      borderRadius: 500,
                    }}
                  />
                  <p style={{ fontSize: 11, marginTop: 3 }}>
                    <span style={{ fontWeight: 400, color: colors.black }}>
                      {requests && requests?.pendingCount + " "}
                    </span>
                    Ordered
                  </p>
                </div>

                <div
                  onClick={() => { }}
                  style={{ cursor: "pointer", textAlign: "center" }}
                >
                  <CloseOutlined
                    style={{
                      background: colors.red300,
                      padding: 8,
                      borderRadius: 500,
                    }}
                  />
                  <p style={{ fontSize: 11, marginTop: 3 }}>
                    <span style={{ fontWeight: 400, color: colors.black }}>
                      {requests && requests?.cancelledCount + " "}
                    </span>
                    Canceled
                  </p>
                </div>
              </Space>
            </Card>
          </Col>
        </Row>

        {/* Table component */}
        <div>
          <TableComponent
            loading={loading}
            data={requests?.result}
            columns={columns}
            totalRecords={requests?.totalRecords}
            border
            pagination={{
              totalPage: requests?.totalPages * pageSize,
              pageSize: pageSize,
            }}
            onPaginationChange={onPaginationChange}
            isServerPagination // true for serverSide pagination
          />
        </div>

        {/* Side drawer */}
        <SideDrawer
          redeemCardLoading={redeemCardLoading}
          orderStatusVal={orderStatusVal}
          onHold={onHold}
          orderStatusHandler={orderStatusHandler}
          setRedeemCardLoading={setRedeemCardLoading}
          requests={requests}
          setDisableStatus={setDisableStatus}
          holdButtonStatus={holdButtonStatus}
          setHoldButtonStatus={setHoldButtonStatus}
          disableStatus={disableStatus}
          onAcceptAllRequest={onAcceptAllRequest}
          agentRequest={agentRequest}
          setAgentRequest={setAgentRequest}
          id={agentId}
          onCancelRequest={onCancelRequest}
          onAcceptRequest={onAcceptRequest}
          open={open}
          setModalOpen={setModalOpen}
          onClose={onClose}
          onHoldAllRequest={onHoldAllRequest}
        />

        {/* Cancel request modal */}
        <Modal
          title={null}
          centered
          closable={true}
          width={766}
          footer={null}
          open={modalOpen}
          onOk={() => setModalOpen(false)}
          onCancel={() => setModalOpen(false)}
          bodyStyle={{}}
        >
          <CancelReasonForm
            form={form}
            handleSubmit={handleSubmit}
            setModalOpen={setModalOpen}
          />
        </Modal>
        <Modal
          open={orderStatusModal}
          centered
          closable={false}
          footer={null}
          onOk={() => { }}
          onCancel={() => setOpen(false)}
        >
          <div style={{ padding: 20 }}>
            <div style={{ display: "flex" }}>
              <ExclamationCircleFilled
                style={{ fontSize: 20, color: "orange" }}
              />
              <p style={{ fontSize: 16, fontWeight: 600, marginLeft: 8 }}>
                {" "}
                Order status
              </p>
            </div>
            <p style={{ fontSize: 14, fontWeight: 400, marginLeft: 25 }}>
              {" "}
              Are you sure to change order status?
            </p>
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                onClick={() => setOrderStatusModal(false)}
                style={{ marginRight: 8 }}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  orderStatusHandler(orderStatusVal);
                }}
                type="primary"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </AdminAppLayout>
  );
};
export default ReviewRequests;
