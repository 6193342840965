import AgentLayout from "../../../AdminPortal/Layouts/Layout";
import {
  Row,
  Col,
  Typography,
  Modal,
  Space,
  Select,
  Form,
  Divider,
  Button,
  Tag,
  Spin,
  message,
  Carousel,
} from "antd";
import NoImageAvailable from "../../../../assets/reqest.png";
import axios from "axios";
import useSWR from "swr";
import { colors } from "../../../AdminPortal/utils/colors";
import ItemCard from "../../components/Card/ItemCard";
import { useContext, useState } from "react";
import UserContext from "../../../Contexts/UserContext";
import { isMobile } from "react-device-detect";
import { CartContext } from "../../../Contexts/CartContext";

import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import {
  FILTER_DATA_FAV_API,
  ADD_FAVORITE_API,
  GET_REWARD_BYID,
} from '../../../API/paths';

const { Option } = Select;
const { Text, Title } = Typography;
// const { Option } = Select;

const AgentFavorite = () => {
  const { addToCart } = useContext(CartContext);
  const [modalOpen, setModalopen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [singleReward, setSingleReward] = useState(null);
  const [variant_form] = Form.useForm();
  const { auth } = useContext(UserContext);
  const fav_rewards_api =
    BASE_URL + FILTER_DATA_FAV_API + auth?.result?.ACAG_KeyID;
  const {
    data: rewards,
    isValidating: loading,
    mutate: mutateReward,
  } = useSWR({
    url: fav_rewards_api,
  });
  const ModalContoller = async (
    rewardId,
    quantity,
    Agent_Redeemed_Quantity,
    ARLR_Redeemable_Quantity
  ) => {
    if (quantity < 1) {
      message.error("The reward item is no longer available to redeem");
      return;
    }
    if (Agent_Redeemed_Quantity >= ARLR_Redeemable_Quantity) {
      message.error("You have already redeemed the available quantity.");
      return;
    }
    try {
      setModalopen(true);
      setModalLoading(true);
      const response = await axios.get(`${GET_REWARD_BYID}?Id=${rewardId}`);
      const result = response?.data?.result;
      result?.tbl_ARRV_Reward_Variants?.forEach((item, index) => {
        if (index === 0) {
          item["status"] = true;
        } else {
          item["status"] = false;
        }
      });
      variant_form.setFieldsValue({
        variant_id:
          result?.tbl_ARRV_Reward_Variants[0]?.ARRV_Reward_Variant_KeyID,
      });
      setModalLoading(false);
      setSingleReward(result ? result : null);
    } catch (e) {
      setModalLoading(false);
      console.log(e);
    }
  };
  const handleVariantChange = (values) => {
    if (!values) {
      return;
    }
    let dataCopy = { ...singleReward };
    const index = dataCopy?.tbl_ARRV_Reward_Variants?.findIndex(
      (item) => item?.ARRV_Reward_Variant_KeyID === values
    );
    dataCopy?.tbl_ARRV_Reward_Variants?.forEach((item, item_index) => {
      if (item_index === index) {
        item.status = true;
      } else {
        item.status = false;
      }
    });
    setSingleReward(dataCopy);
  };
  const addFav = async (reward_id, fav_status) => {
    const fav_data = {
      ARFA_Agent_ACAG_KeyID: auth?.result?.ACAG_KeyID,
      ARFA_Reward_ARLR_KeyID: reward_id,
      ARFA_zalt_Altered_Type: !fav_status,
    };

    mutateReward(async () => {
      try {
        const response = await axios.post(ADD_FAVORITE_API, fav_data);
        if (response?.data?.success) {
          message.success(
            !fav_status
              ? "Reward has been added to favorites"
              : "Remove from favorites"
          );
        } else {
          message.error(response?.data?.message);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  let imageSrc = singleReward?.ARLR_Images_Path ? [...singleReward.ARLR_Images_Path] : [];
  if (singleReward?.ARLR_Image_Path) {
    imageSrc.unshift(singleReward?.ARLR_Image_Path);
  }
  imageSrc = (imageSrc?.length ?? 0) === 0 ? [NoImageAvailable] : imageSrc;

  const handleCancelClick = () => {
    imageSrc = [NoImageAvailable];
    setModalopen(false)
  }

  return (
    <Spin
      spinning={loading}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <AgentLayout>
        <div
          style={{
            paddingLeft: isMobile ? 14 : 60,
            paddingRight: isMobile ? 14 : 60,
            marginTop: 28,
            marginBottom: 20,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Space direction="vertical" size={0}>
                {!isMobile && (
                  <Text style={{ color: colors.black }}>
                    Home /
                    <span style={{ color: colors.grey200 }}> My Favorite</span>
                  </Text>
                )}
                <Title level={5} style={{ color: colors.black }}>
                  My Favorites
                </Title>
              </Space>
              <Tag>
                <p
                  style={{
                    color: colors.primaryBlue,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <span style={{ fontSize: 14 }}>Total Points: </span>
                  {JSON.parse(localStorage.getItem("totalPoints"))}
                </p>
              </Tag>
            </div>
          </div>
          <Divider />
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {rewards?.result?.map((item, index) => {
              return (
                <Col
                  key={"agent-favorite-columns" + index}
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                  xxl={4}
                >
                  <ItemCard
                    key={"agent-favorite-itemcard" + index}
                    rewardId={item?.ARFA_Reward_ARLR_KeyID}
                    addFav={addFav}
                    quantity={item?.tbl_ARLR_Rewards?.ARLR_Quantity}
                    category_title={
                      item?.tbl_ARLR_Rewards?.tbl_ARLC_Categories?.ARLC_Title
                    }
                    favorite={item?.tbl_ARLR_Rewards?.IsFavorite}
                    productName={item?.tbl_ARLR_Rewards?.ARLR_Title}
                    src={item?.tbl_ARLR_Rewards?.ARLR_Image_Path}
                    loading={loading}
                    index={index}
                    norm_points={item?.tbl_ARLR_Rewards?.ARLR_Norm_Points}
                    ModalContoller={ModalContoller}
                    Agent_Redeemed_Quantity={
                      item?.tbl_ARLR_Rewards?.Agent_Redeemed_Quantity
                    }
                    ARLR_Redeemable_Quantity={
                      item?.tbl_ARLR_Rewards?.ARLR_Redeemable_Quantity
                    }
                  />
                </Col>
              );
            })}
          </Row>
        </div>
        <Modal
          open={modalOpen}
          footer={null}
          width="80vw"
          onCancel={handleCancelClick}
        >
          <Spin spinning={modalLoading}>
            <div style={{ backgroundColor: colors.bgGrey }}>
              <Row align="middle">
                <Col
                  span={isMobile ? 24 : 12}
                  style={{ backgroundColor: colors.white }}
                >
                  {modalLoading ? null :
                    <Carousel style={{
                      height: isMobile ? 350 : 580,
                      width: "100%",
                      backgroundColor: "rgb(246, 248, 251)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'center'
                    }}>
                      {imageSrc.map((item) => (
                        <div key={item}>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(246, 248, 251)" }}>
                            <img style={{ height: 350, width: 280, objectFit: "contain" }} src={item} alt="link" />
                          </div>
                        </div>
                      ))
                      }
                    </Carousel>}
                </Col>
                <Col span={isMobile ? 24 : 12}>
                  <div style={{ padding: "0 24px" }}>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "60px" }}>
                      <Text
                        style={{
                          color: colors.black,
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                      >
                        {singleReward?.ARLR_Title}
                      </Text>
                      <Text>
                        <pre
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            fontFamily: "inherit",
                            maxHeight: "300px",
                            overflowY: "auto",
                          }}
                        >
                          {singleReward?.ARLR_Description}
                        </pre>
                      </Text>
                      <Divider style={{ marginTop: 5 }} />
                      <Form
                        form={variant_form}
                        layout="vertical"
                        style={{ marginTop: -10 }}
                      >
                        {singleReward?.tbl_ARRV_Reward_Variants?.length < 1 ||
                          !singleReward?.tbl_ARRV_Reward_Variants ? (
                          ""
                        ) : (
                          <Form.Item
                            label="Select Variant"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            name="variant_id"
                          >
                            <Select
                              style={{ width: "100%" }}
                              onChange={handleVariantChange}
                              disabled={
                                singleReward?.tbl_ARRV_Reward_Variants?.length <
                                1 || !singleReward?.tbl_ARRV_Reward_Variants
                              }
                            >
                              {singleReward?.tbl_ARRV_Reward_Variants?.map(
                                (item) => (
                                  <Option
                                    key={"agent-favorite-options" + item}
                                    value={item?.ARRV_Reward_Variant_KeyID}
                                  >
                                    {item?.ARRV_Title}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        )}
                      </Form>
                      <Divider style={{ marginTop: -10 }} />
                      <Text
                        style={{
                          float: "right",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Total Reward Points
                      </Text>
                      <Text
                        style={{
                          float: "right",
                          fontWeight: 600,
                          fontSize: 16,
                          color: colors.black,
                        }}
                      >
                        {singleReward?.ARLR_Norm_Points}
                      </Text>
                      <Button
                        onClick={() => addToCart(singleReward, setModalopen)}
                        type="primary"
                        disabled={singleReward?.ARLR_Quantity < 1}
                        style={{ width: "100%", marginTop: 10 }}
                      >
                        Add to cart
                      </Button>
                      <Button
                        type="text"
                        onClick={handleCancelClick}
                        style={{
                          width: "100%",
                          color: colors.black,
                          background: colors.white,
                          marginBottom: 10,
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </div>
          </Spin>
        </Modal>
      </AgentLayout>
    </Spin>
  );
};
export default AgentFavorite;
