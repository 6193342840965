import { Button, Image, Input, Popconfirm } from "antd";
import trashIcon from "../../../../assets/icons/trash.svg";
import deleteIcon from "../../../../assets/icons/delete.svg";

export const getColumns = ({
  handleInputChange,
  // editRow,
  deleteRow,
  deleteData,
}) => [
    {
      title: "#",
      key: "0",
      dataIndex: "MSPP_Line_Number",
      width: "5%",
      render: (_, object, index) => (
        <p>{object?.MSPP_Line_Number ? object?.MSPP_Line_Number : index + 1}</p>
      ),
    },
    {
      title: "PREMIUM-FROM",
      key: "1",
      dataIndex: "MSPP_Policy_Premium_From",
      width: "16%",
      align: "right",
      render: (text, record, index) =>
        record?.status ? (
          <Input
            type="number"
            value={text}
            min={0}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                "MSPP_Policy_Premium_From",
                record,
                index
              )
            }
          />
        ) : (
          <p>{text?.toLocaleString()}</p>
        ),
    },
    {
      title: "PREMIUM-TO",
      key: "2",
      dataIndex: "MSPP_Policy_Premium_To",
      width: "16%",
      align: "right",
      render: (text, record, index) =>
        record?.status ? (
          <Input
            value={text}
            type="number"
            min={0}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                "MSPP_Policy_Premium_To",
                record,
                index
              )
            }
          />
        ) : (
          <p>{text && text?.toLocaleString()}</p>
        ),
    },
    {
      title: "EARNED-POINTS MULTIPLIER",
      key: "3",
      dataIndex: "MSPP_Earned_Point_Multiplier",
      width: "16%",
      align: "right",
      render: (text, record, index) =>
        record?.status ? (
          <Input
            value={text}
            disabled={record?.MSPP_Earned_Point_Value}
            type="number"
            min={0}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                "MSPP_Earned_Point_Multiplier",
                record,
                index
              )
            }
          />
        ) : (
          <p>{text && text?.toLocaleString()}</p>
        ),
    },
    {
      title: "EARNED-POINTS VALUE",
      key: "4",
      dataIndex: "MSPP_Earned_Point_Value",
      width: "16%",
      align: "right",
      render: (text, record, index) =>
        record?.status ? (
          <Input
            value={text}
            disabled={record?.MSPP_Earned_Point_Multiplier}
            type="number"
            min={0}
            onChange={(e) =>
              handleInputChange(
                e.target.value,
                "MSPP_Earned_Point_Value",
                record,
                index
              )
            }
          />
        ) : (
          <p>{text}</p>
        ),
    },
    {
      title: "ACTION",
      key: "8",
      dataIndex: "edit",
      align: "center",
      width: "10%",
      render: (_, object, index) =>
        object?.status ? (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Button
              type="link"
              onClick={() => deleteRow(index, object?.status, object?.MSPP_KeyID)}
            >
              <Image src={deleteIcon} alt="normandy" preview={false} />
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <Popconfirm
              title="Are You Sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteData(object?.MSPP_KeyID)}
            >
              <Button type="link">
                <Image src={trashIcon} alt="normandy" preview={false} />
              </Button>
            </Popconfirm>
          </div>
        ),
    },
  ];
