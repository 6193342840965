import { Divider, Breadcrumb, Typography, Form, message, Tag } from "antd";
import React, { useEffect, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import AdminAppLayout from "../../../Layouts/AdminLayout";
import AddCategoryModal from "./AddCategoryModal";
import AddNewRewardForm from "./form";
import { useNavigate } from "react-router";

import { useParams } from "react-router-dom";
import { colors } from "../../../utils/colors";
import { dateFormat } from "../../../utils/helpers";

import {
  ALL_CATEGORIES_API,
  ADD_CATEGORY_API,
  REWARD_POST_API,
  PUT_REWARD_API,
} from "../../../../API/paths";
import { RoutesLookup } from "../../../../Routes/RoutesLookup";
import { BASE_URL } from "../../../../Constants/EnvironmentVariableProvider";
import { DATAGRID_DEFAULT_PAGE_SIZE } from '../../../../Constants/ApplicationConstants';

const { Title } = Typography;

const AddNewReward = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const returnPage = queryParams.get("page");
  const returnPageSize = queryParams.get("pageSize");
  const statusType = queryParams.get("statusType");

  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [categoryForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const {
    data,
    isValidating: loading,
    mutate,
  } = useSWR({
    url: BASE_URL + ALL_CATEGORIES_API,
  });

  Form.useWatch("reward_images", form)
  Form.useWatch("reward_image", form)

  useEffect(() => {
    if (data?.result) {
      setCategories(data?.result);
    }
  }, [data]);
  const toggleModal = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleSubmit = async (values) => {
    let startDate = dateFormat(values.start_date, "YYYY-MM-DD");
    let expiryDate =
      values.expiry_date === null || values.expiry_date === undefined
        ? null
        : dateFormat(values.expiry_date, "YYYY-MM-DD");
    let verianteData = [];
    if (values?.veriante) {
      // eslint-disable-next-line array-callback-return
      values?.veriante?.map((item) => {
        verianteData.push({
          ARRV_Title: item?.title,
          ARRV_Image_Path: item?.image,
        });
      });
    }
    let data = {
      ARLR_Title: values.reward_name,
      ARLR_Description: values.description,
      ARLR_Source: values.reward_source,
      ARLR_Category_ARLC_KeyID: values.reward_category,
      ARLR_Cost: values.cost,
      ARLR_Norm_Points: values.radeem_on_points,
      ARLR_Quantity: values.quantity,
      ARLR_Is_Featured: values.featured,
      ARLR_Redeemable_Quantity: values.redeemablequantity,
      ARLR_Image_Path: (values.reward_images?.length ?? 0) === 0 ? null : values.reward_image,
      ARLR_Images_Path: values.reward_images?.filter((imagePath) => imagePath !== values.reward_image && imagePath !== null),
      ARLR_Status: values.saveType,
      ARLR_Is_Active: values.status,
      ARLR_Start_Date: startDate,
      ARLR_Expiry_Date: expiryDate,
      ARLR_Comment: values.comment,
      tbl_ARRV_Reward_Variants: verianteData,
    };
    try {
      const commonPaginationParams = `?page=${returnPage ?? 1}&pageSize=${returnPageSize ?? DATAGRID_DEFAULT_PAGE_SIZE
        }&statusType=${statusType}`;
      if (id !== "create") {
        data["ARLR_KeyID"] = id;
        const response = await axios.put(BASE_URL + PUT_REWARD_API, data);
        if (response?.data?.success) {
          message.success(response?.data?.message);

          navigate(
            `${RoutesLookup.REWARD_INVENTORY}/${commonPaginationParams}` // ${response.data.result.ARLR_KeyID}
          );
        } else {
          message.error(response?.data?.message);
        }
      } else {
        const response = await axios.post(BASE_URL + REWARD_POST_API, data);
        if (response?.data?.success) {
          message.success(
            values?.saveType === 1
              ? `The reward has been successfully created in the draft state`
              : "The reward has been successfully published and is now visible on the Agent Portal"
          );

          navigate(
            `${RoutesLookup.REWARD_INVENTORY}/${commonPaginationParams}` // ${response.data.result.ARLR_KeyID}
          );
        } else {
          message.error(response?.data?.errors);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleCategoryChange = (values) => {
    mutate(() => {
      try {
        axios
          .post(ADD_CATEGORY_API, values)
          .then((response) => {
            message.success(response?.data?.message);
            toggleModal();
            categoryForm.resetFields();
            mutate(BASE_URL + ALL_CATEGORIES_API)
          })
          .catch((e) => {
            message.error("Record already exists.");
          });
      } catch (e) {
        console.log(e);
      }
    });
  };

  return (
    <AdminAppLayout>
      <div
        style={{
          paddingLeft: 152,
          paddingRight: 152,
          marginTop: 40,
        }}
      >
        <Breadcrumb
          items={[
            {
              title: "Reward Inventory",
            },
            {
              title: id !== "create" ? "Edit Reward" : "Add New Reward",
            },
          ]}
        />

        <Title level={4} style={{ color: colors.black, marginTop: 3 }}>
          {id !== "create" ? "Edit Reward" : "Add New Reward"}
        </Title>
        {id !== "create" && <Tag>Reference No. {id}</Tag>}
        <Divider type="horizontal" />
        <div>
          <div style={{ marginBottom: 60 }}>
            <AddNewRewardForm
              returnPage={returnPage}
              returnPageSize={returnPageSize}
              statusType={statusType}
              id={id}
              form={form}
              handleSubmit={handleSubmit}
              toggleModal={toggleModal}
              categories={categories}
              loading={loading}
            />
          </div>
        </div>
      </div>

      {/*  Add NewCategory Modal*/}
      <AddCategoryModal
        open={open}
        footer={null}
        form={categoryForm}
        toggleModal={toggleModal}
        handleCategoryChange={handleCategoryChange}
      />
    </AdminAppLayout>
  );
};

export default AddNewReward;
