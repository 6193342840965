import { useMsal } from '@azure/msal-react';
import React, { useContext, useEffect } from 'react'
import { RouterProvider } from 'react-router-dom';
import ApplicationRoutes from './ApplicationRoutes';
import UserContext from '../Contexts/UserContext';
import { setAxiosInterceptors } from '../API/axios.Config';

function Router() {
    const { instance: msalInstance } = useMsal()
    const { auth } = useContext(UserContext)

    const router = ApplicationRoutes(auth)

    useEffect(() => {
        setAxiosInterceptors(msalInstance);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <RouterProvider router={router} />
}

export default Router