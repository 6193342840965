import { Image, Typography } from "antd";
import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import "./report.css";
import { useSearchParams } from "react-router-dom";

function Report(props) {
  const { Title } = Typography;
  // eslint-disable-next-line no-unused-vars
  let [spObj, setSearchParams] = useSearchParams();

  return (
    <>
      <div
        style={{
          display: "flex",
          borderRadius: 15,
          padding: 10,
          boxShadow: "0px 2px 12px rgba(0, 0, 0, 0.1)",
          width: "426px",
        }}
        className="report"
        onClick={() => {
          props?.setOpen(true);
          setSearchParams(`id=${props.status}`);
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              borderRadius: 8,
              backgroundColor: "#213E7C",
              width: 64,
              height: 64,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              width={24}
              height={24}
              style={{ borderRadius: "100%" }}
              src={props.iconUrl}
              preview={false}
            />
          </div>

          <div style={{ marginLeft: "20px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Title
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  letterSpacing: "0.03em",
                  color: "#171717",
                }}
                level={5}
              >
                {props.titleText}
              </Title>
              <ArrowRightOutlined
                style={{
                  marginLeft: 8,
                  marginBottom: 5,
                  width: "13px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;
