import React from "react";
import { Space, Typography, Modal } from "antd";

import { colors } from "../../utils/colors";
import { formateDateWithTime } from "../../utils/helpers";

const { Text } = Typography;

const AdminNoteModal = ({ notes, id, noteModalOpen, setNoteModal }) => {
  let note = notes?.find((item) => item.AGNO_KeyID === id);

  return (
    <Modal
      title={null}
      centered
      closable={true}
      width={521}
      footer={null}
      open={noteModalOpen}
      onOk={() => setNoteModal(false)}
      onCancel={() => setNoteModal(false)}
    >
      <div style={{ padding: 30 }}>
        <Space direction="vertical">
          <Text style={{ fontSize: 24, fontWeight: 600, color: colors.black }}>
            Notes for admin
          </Text>
          <Text style={{ fontSize: 14, fontWeight: 400 }}>
            {note?.AGNO_Notes}
          </Text>
          <Text style={{ fontSize: 16, fontWeight: 500, color: colors.black }}>
            Last note:
          </Text>
          <Text style={{ fontSize: 14, fontWeight: 400 }}>
            {formateDateWithTime(note?.AGNO_zalt_altered_date)}
          </Text>
        </Space>
      </div>
    </Modal>
  );
};

export default AdminNoteModal;
