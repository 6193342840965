import {
  Button,
  Col,
  Form,
  Input,
  Row,
  theme,
  Image,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router";

import { colors } from "../../../AdminPortal/utils/colors";
import logo from "../../../../assets/logo.png";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import { RoutesLookup } from '../../../Routes/RoutesLookup';
import UserContext from '../../../Contexts/UserContext';
import { USER_ROLE } from '../../../Constants/ApplicationConstants';
import { getRoleFromAuth } from '../../../../utils/commonUtils';

const { Title } = Typography;

const AgentVerificationCode = () => {
  const { auth, setAuth } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const email = params?.email;
  const { token } = theme.useToken();
  const colorBgLoginLayout = token['colorBgLoginLayout']

  useEffect(() => {
    if (auth?.token && getRoleFromAuth(auth) !== USER_ROLE.ADMIN) {
      navigate(RoutesLookup.AGENT_DASHBOARD);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [verificationCodeForm] = Form.useForm();

  const loginUser = async (values) => {
    const userData = {
      Email: email,
      Code: values.code,
    };
    setLoading(true);
    let url = `${BASE_URL}${RoutesLookup.VALIDATE_CODE}`;
    await axios
      .post(url, userData)
      .then((res) => {
        if (res?.data?.success) {
          setAuth(res?.data);
          setLoading(false);
          message.success(`${res?.data?.message}`);
          verificationCodeForm.resetFields();

          navigate(`/${RoutesLookup.NEW_PASSWORD}/${email}`);
        } else {
          message.error("Invalid Code");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error in agent verification code", error.message);
      })
      .finally(() => setLoading(false));
  };

  const resendCode = async () => {
    let url = `${BASE_URL}${RoutesLookup.EMAIL_CODE}?agentEmail=${email}`;
    await axios
      .get(url)
      .then((res) => {
        if (res?.data?.success) {
          setAuth(res?.data);
          setLoading(false);
          message.success("Send Code successfully.");

          navigate(`/${RoutesLookup.VERIFICATION_CODE}/${email}`);
        } else {
          message.error(`${res?.data?.message}`);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error in agent forgot password", error.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          verticalAlign: "middle",
          alignItems: "center",
        }}
      >
        <div
          style={{
            background: colorBgLoginLayout,
            width: "55%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            display: isMobile ? "none" : "flex",
          }}
        >
          <div>
            <Image
              style={{
                marginBottom: 20,
                width: "357px",
                height: "53px",
                objectFit: "cover",
              }}
              preview={false}
              src={logo}
            />
          </div>
        </div>
        <div
          style={{
            width: isMobile ? "100%" : "45%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: 350 }}>
            <div>
              <Title
                level={3}
                style={{
                  marginBottom: 15,
                  marginTop: 0,
                  color: colors.grey900,
                }}
              >
                Verification Code
              </Title>
              <div
                style={{
                  width: "365px",
                  height: "43px",
                  marginBottom: "44px",
                  color: "#737373",
                }}
              >
                Enter the 4 digit verification code send to your email
              </div>
            </div>
            <Form
              form={verificationCodeForm}
              layout="vertical"
              onFinish={loginUser}
            >
              <div>
                <Form.Item
                  label="Enter Code"
                  name="code"
                  rules={[
                    {
                      required: true,
                      type: "string",
                      message: "Please input verification code",
                    },
                  ]}
                >
                  <Input
                    style={{
                      height: 42,
                      border: `1px solid ${colors.grayNeutral300}`,
                    }}
                    autoComplete="off"
                    size="large"
                  />
                </Form.Item>
                <div
                  style={{
                    marginTop: "-12px",
                    display: "flex",
                    marginBottom: "36px",
                    marginLeft: "145px",
                    width: "236px",
                  }}
                >
                  <div style={{ marginRight: "3px" }}>
                    If you don't receive code!{" "}
                  </div>
                  <p
                    style={{ color: "#4A90E3", cursor: "pointer" }}
                    onClick={() => resendCode()}
                  >
                    Resend
                  </p>
                </div>
              </div>
              <Row style={{ marginTop: 28 }}>
                <Col span={24}>
                  <Button
                    loading={loading}
                    block
                    type="primary"
                    htmlType="submit"
                    size="large"
                  >
                    Verify and proceed
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentVerificationCode;
