import React, { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import AdminAppLayout from "../../Layouts/AdminLayout";
import { Button, Col, Form, Input, Modal, Row, Space, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import {
  generateSearchParamsGetter,
  getNewSearchParamStringWithArgs,
  getSearchParams,
  isPositiveInteger,
} from "../../utils/helpers";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { colors } from "../../utils/colors";
import TableComponent from "../../components/Table/TableComponent";
import { getColumns } from "./columns";
import axios from "axios";
import AdminForm from "./form";

import { BASE_URL } from "../../../Constants/EnvironmentVariableProvider";
import {
  ADD_ADMIN_API,
  ADMINS_FILTER_DATA_API,
  EDIT_ADMINS_API,
} from "../../../API/paths";
import { DATAGRID_DEFAULT_PAGE_SIZE } from "../../../Constants/ApplicationConstants";

function Admin() {
  let [spObj, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: DATAGRID_DEFAULT_PAGE_SIZE.toString(),
  });
  spObj.get('page') === null && spObj.set('page', '1');
  spObj.get('pageSize') === null && spObj.set('pageSize', DATAGRID_DEFAULT_PAGE_SIZE.toString());

  const [isEdit, setIsEdit] = useState(false);
  const tableRef = useRef(null);
  const [activeTab, setActiveTab] = useState(0);

  let [id, page, pageSize, filterName, statusType] = getSearchParams(spObj);

  const getNewSearchParamString = generateSearchParamsGetter(spObj);

  const debounceFetcher = useDebouncedCallback((search) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({ page: 1, pageSize, search, statusType })
    );
  }, 600);

  const mutateSearchParams = () => setSearchParams(getNewSearchParamString());

  const [form] = Form.useForm();

  const {
    data: admins,
    isValidating: loading,
    mutate,
  } = useSWR({
    url: BASE_URL + ADMINS_FILTER_DATA_API + getNewSearchParamString(),
  });

  const openModal = (id) => {
    form.resetFields();
    setSearchParams(`${getNewSearchParamString()}&id=${id || "create"}`);
  };

  const closeModal = () => {
    form.resetFields();
    mutateSearchParams();
  };

  useEffect(() => {
    if (mutate) mutate();
  }, [filterName, pageSize, page, mutate, statusType]);

  useEffect(() => {
    setActiveTab(parseInt(statusType ?? 0))
  }, [statusType])

  const handleSubmit = (values) => {
    mutate(async () => {
      try {
        let updateData = { ...values };
        if (id === "create") {
          const response = await axios.post(ADD_ADMIN_API, values);
          message.success(response?.data?.message);
          closeModal();
        } else {
          const data = {
            data: {
              ...updateData,
              ORAD_KeyID: Number(id),
            },
          };
          await axios.put(EDIT_ADMINS_API, data.data);
          message.success("Admin updated successfully.");
          closeModal();
        }
      } catch (e) {
        message.error(e?.response?.data?.errors);
        console.log(e);
      }
    });
  };

  const onPaginationChange = async (page, pageSize) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page,
        pageSize,
        search: filterName,
        statusType,
      })
    );
  };

  const editDetail = (adminId) => {
    setSearchParams(`${getNewSearchParamString()}&id=${adminId}`);
  };

  const columns = getColumns({
    editDetail,
    openModal,
    setIsEdit,
  });

  const handleSearch = (e) => {
    debounceFetcher(e.target.value);
    setSearchState(e.target.value)
  };

  const [searchState, setSearchState] = useState(filterName ? filterName : '')
  useEffect(() => {
    setSearchState(filterName)
  }, [filterName])

  return (
    <AdminAppLayout>
      <div
        style={{
          paddingLeft: 60,
          paddingRight: 60,
          marginTop: 56,
          marginBottom: 20,
        }}
      >
        <Row
          style={{ marginBottom: 15 }}
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row" span={8}>
            <div>
              <Input
                size="large"
                placeholder="Search Admin..."
                defaultValue={filterName}
                value={searchState}
                onChange={handleSearch}
                allowClear
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                    }}
                  />
                }
              />
            </div>
          </Col>
          <Col className="gutter-row" span={8}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Space direction="horizontal" size={40}>
                {[
                  {
                    btnText: "All",
                    total: admins?.allCount,
                    onClick: () => {
                      setActiveTab(0);
                      setSearchParams(
                        getNewSearchParamStringWithArgs({
                          page: 1,
                          pageSize,
                          statusType: 0,
                        })
                      );
                    },
                  },
                  {
                    btnText: "Active",
                    total: admins?.activeCount,
                    onClick: () => {
                      setActiveTab(1);
                      setSearchParams(
                        getNewSearchParamStringWithArgs({
                          page: 1,
                          pageSize,
                          statusType: 1,
                        })
                      );
                    },
                  },
                  {
                    btnText: "Inactive",
                    total: admins?.inActiveCount,
                    onClick: () => {
                      setActiveTab(2);
                      setSearchParams(
                        getNewSearchParamStringWithArgs({
                          page: 1,
                          pageSize,
                          statusType: 2,
                        })
                      );
                    },
                  },
                ].map((item, i) => (
                  <Button
                    style={{
                      color: activeTab === i && colors.grey900,
                      backgroundColor: activeTab === i && colors.grayNeutral100,
                    }}
                    key={item.btnText}
                    onClick={item.onClick}
                    type="text"
                  >
                    {item.btnText} ({item.total})
                  </Button>
                ))}
              </Space>
            </div>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Button onClick={() => openModal()} type="primary" size={"middle"}>
              + Add Admin
            </Button>
          </Col>
        </Row>
        <div>
          <TableComponent
            loading={loading}
            data={admins?.result}
            totalRecords={admins?.totalRecords}
            columns={columns}
            tableRef={tableRef}
            border
            onPaginationChange={onPaginationChange}
            pagination={{
              totalPage: admins?.totalPages * pageSize,
              pageSize: pageSize,
            }}
            isServerPagination
          />
        </div>
        <Modal
          open={id === "create" || isPositiveInteger(id)}
          onCancel={() => {
            closeModal();
            form.resetFields();
          }}
          centered
          width={766}
          footer={null}
        >
          <AdminForm
            id={id}
            form={form}
            handleSubmit={handleSubmit}
            listLoading={loading}
            closeModal={closeModal}
            isEdit={isEdit}
          />
        </Modal>
      </div>
    </AdminAppLayout>
  );
}

export default Admin;
