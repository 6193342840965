import React from "react";
import useSWR from "swr";
import { Space, Typography, Row, Col, Divider, Image, Card, Spin } from "antd";
import { useParams } from "react-router-dom";

import AdminAppLayout from "../../Layouts/AdminLayout";
import { colors } from "../../utils/colors";

import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import { GET_AGENT_BY_ID_API } from '../../../API/paths';

const { Text } = Typography;

export default function AgentProfile() {
  const { id } = useParams();

  const { data: agent, isValidating: loading } = useSWR({
    url: BASE_URL + GET_AGENT_BY_ID_API + "?KeyId=" + id,
  });

  return (
    <Spin spinning={loading}>
      <AdminAppLayout>
        <div
          style={{
            paddingLeft: 60,
            paddingRight: 60,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <Space>
            <Text
              style={{ fontSize: 12, fontWeight: 500, color: colors.black }}
            >
              Manage Agent
            </Text>
            <Text style={{ fontSize: 12, fontWeight: 500 }}>
              /Agent profile
            </Text>
          </Space>
          <p
            style={{
              fontSize: 18,
              fontWeight: 600,
              color: colors.black,
              marginTop: 4,
            }}
          >
            Manage Agent
          </p>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col
              className="gutter-row"
              span={6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <Image
                  width={48}
                  height={48}
                  style={{ borderRadius: "100%" }}
                  src={agent?.result?.ACAG_Image_Path}
                />
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginTop: 8,
                    color: colors.grey500,
                  }}
                >
                  {agent?.result?.ACAG_Name}
                </p>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <Card
                style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
              >
                <Space direction="vertical">
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    Available Points
                  </Text>
                  <Text
                    style={{
                      fontSize: 24,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {agent?.result?.ACAG_Available_Points
                      ? agent?.result?.ACAG_Available_Points
                      : 0}
                  </Text>
                </Space>
              </Card>
            </Col>
            <Col className="gutter-row" span={6}>
              <Card
                style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
              >
                <Space direction="vertical">
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    Redeemable Points
                  </Text>
                  <Text
                    style={{
                      fontSize: 24,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {agent?.result?.ACAG_Available_Points
                      ? agent?.result?.ACAG_Available_Points
                      : 0}
                  </Text>
                </Space>
              </Card>
            </Col>
            <Col className="gutter-row" span={6}>
              <Card
                style={{ boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
              >
                <Space direction="vertical">
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    Redeemed Points
                  </Text>
                  <Text
                    style={{
                      fontSize: 24,
                      fontWeight: 600,
                      color: colors.black,
                    }}
                  >
                    {agent?.result?.ACAG_Points_Redeemed
                      ? agent?.result?.ACAG_Points_Redeemed
                      : 0}
                  </Text>
                </Space>
              </Card>
            </Col>
          </Row>

          <Row style={{ marginTop: 30 }}>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  Email Address
                </Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {agent?.result?.ACAG_Email}
                </Text>
              </Space>
            </Col>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  Phone Number
                </Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {agent?.result?.ACAG_Phone}
                </Text>
              </Space>
            </Col>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  Agency Name
                </Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {agent?.result?.ACAG_Agency_Name}
                </Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  Street Address 1{" "}
                </Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line1}
                </Text>
              </Space>
            </Col>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  Street address 2
                </Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line2}
                </Text>
              </Space>
            </Col>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>City</Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {agent?.result?.tbl_MSAD_Addresses?.MSAD_City}
                </Text>
              </Space>
            </Col>
          </Row>
          <Row style={{ marginTop: 30 }}>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>ZIP</Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {agent?.result?.tbl_MSAD_Addresses?.MSAD_ZipCode}
                </Text>
              </Space>
            </Col>
            <Col className="gutter-row" span={8}>
              <Space direction="vertical">
                <Text style={{ fontSize: 14, fontWeight: 500 }}>State</Text>
                <Text
                  style={{ fontSize: 14, fontWeight: 400, color: colors.black }}
                >
                  {" "}
                  {agent?.result?.tbl_MSAD_Addresses?.MSAD_State}
                </Text>
              </Space>
            </Col>

            <Col className="gutter-row" span={8}></Col>
          </Row>
        </div>
      </AdminAppLayout>
    </Spin>
  );
}
