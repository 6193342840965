import React, { useState, useEffect } from "react";
import useSWR from "swr";
import axios from "axios";
import AdminAppLayout from "../../Layouts/AdminLayout";
import { Button, Col, Row, message, Typography } from "antd";
import { getColumns } from "./columns";
import PointsFormTable from "../../components/Table/PointsFormTable";

import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import {
  ADD_POINTS_FROM_API,
  ALL_POINTS_FROM_API,
  DELETE_POINTS_FROM_API
} from '../../../API/paths';

function PointsFrom() {
  const [tableData, setTableData] = useState([]);
  const {
    data,
    isValidating: loading,
    mutate,
  } = useSWR({
    url: BASE_URL + ALL_POINTS_FROM_API,
  });
  const handleInputChange = (value, fieldName, record, index) => {
    const updatedRecord = {
      ...record,
      [fieldName]: value,
      MSPP_Line_Number: record?.MSPP_Line_Number
        ? record?.MSPP_Line_Number
        : index + 1,
    };
    const updatedData = tableData?.map((row) =>
      row === record ? updatedRecord : row
    );
    setTableData(updatedData);
  };
  const deleteRow = (index, value, id) => {
    let copyData = [...tableData];
    if (!value || id) {
      copyData[index].status = false;
      setTableData(copyData);
      return;
    }
    copyData.splice(index, 1);
    setTableData(copyData);
  };

  const deleteData = (id) => {
    mutate(async () => {
      try {
        const response = await axios.put(DELETE_POINTS_FROM_API + "?Id=" + id);
        if (response?.data?.success) {
          message.success(response?.data?.message);
          return;
        } else {
          message.error(response?.data?.message);
          return;
        }
      } catch (e) {
        console.log(e);
        return;
      }
    });
  };
  const handleSubmit = () => {
    let dataCopy = [...tableData];
    console.log("dataCopy", dataCopy);
    const result = dataCopy?.filter(
      (item) => item?.status && !item?.MSPP_KeyID
    );
    if (!result || result?.length === 0) {
      message.error("Please enter record first");
      return;
    }
    for (let i = 0; i < result?.length; i++) {
      if (
        !(
          result[i]?.MSPP_Policy_Premium_From &&
          result[i]?.MSPP_Policy_Premium_To &&
          (result[i]?.MSPP_Earned_Point_Multiplier ||
            result[i]?.MSPP_Earned_Point_Value)
        )
      ) {
        message.error("All fields are required.");
        return;
      }
      if (
        result[i]?.MSPP_Policy_Premium_From < 0 ||
        result[i]?.MSPP_Policy_Premium_To < 0 ||
        result[i]?.MSPP_Earned_Point_Multiplier < 0 ||
        result[i]?.MSPP_Earned_Point_Value < 0
      ) {
        message.error("Negative values are not allowed.");
        return;
      }
      if (result[i]?.MSPP_Policy_Premium_From > result[i]?.MSPP_Policy_Premium_To) {
        message.error("Points From should be less than Points To");
        return;
      }
    }
    mutate(async () => {
      try {
        const response = await axios.post(ADD_POINTS_FROM_API, dataCopy);
        if (response?.data?.success) {
          message.success(response?.data?.message);
          return;
        } else {
          message.error(response?.data?.message);
          return;
        }
      } catch (e) {
        console.log(e);
        return;
      }
    });
  };

  const columns = getColumns({
    handleInputChange,
    // editRow,
    deleteRow,
    deleteData,
  });
  const handleAddRow = () => {
    if (
      (tableData?.length ?? 0) > 0 &&
      (!tableData[tableData?.length - 1]?.MSPP_Policy_Premium_From ||
        !tableData[tableData?.length - 1]?.MSPP_Policy_Premium_To)
    ) {
      message.error(
        "Please enter Policy Premium From and Policy Premium To values!"
      );
      return;
    }
    const newRow = {
      MSPP_Line_Number: "",
      MSPP_Policy_Premium_From:
        tableData ? Number(tableData[tableData?.length - 1]?.MSPP_Policy_Premium_To) : 0 + 1,
      MSPP_Policy_Premium_To: "",
      MSPP_Earned_Point_Multiplier: "",
      MSPP_Earned_Point_Value: "",
      status: true,
    };
    tableData?.length > 0
      ? setTableData([...tableData, newRow])
      : setTableData([newRow]);
  };

  useEffect(() => {
    setTableData(data?.result);
  }, [data, mutate]);

  return (
    <AdminAppLayout>
      <div
        style={{
          paddingLeft: 60,
          paddingRight: 60,
          marginTop: 56,
          marginBottom: 20,
        }}
      >
        <Row
          style={{ marginBottom: 15 }}
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row" span={8}>
            <div>
              <Typography.Title level={4} style={{ color: "black" }}>
                Premium Points Form
              </Typography.Title>
            </div>
          </Col>
          <Col span={16} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={handleAddRow} size={"middle"}>
              + Add New
            </Button>
          </Col>
        </Row>
        <div>
          <PointsFormTable
            loading={loading}
            data={tableData}
            columns={columns}
          />
        </div>
        <Button
          style={{ float: "right", marginTop: 20, marginBottom: 10 }}
          onClick={handleSubmit}
          type="primary"
        >
          Save
        </Button>
      </div>
    </AdminAppLayout>
  );
}

export default PointsFrom;
