import React, { useContext } from "react";
import { Result, Spin, Button } from "antd";
import { useMsal } from "@azure/msal-react";
import { doLogout } from "../../../utils/manageUserData";
import UserContext from '../../Contexts/UserContext';

function Logout() {
  const { instance: msalInstance } = useMsal();
  const { setAuth } = useContext(UserContext);

  const handleOnForceLogoutClick = () => {
    doLogout(msalInstance, setAuth);
  };

  return (
    <>
      <Result
        style={{ marginTop: 100 }}
        icon={<Spin size="large" />}
        title="Logging Out"
        subTitle="We are logging you out."
      />
      <div className="flex justify-center items-center flex-col w-full">
        <p className=" ">
          If this page does not redirect then you can click here to manually
          logout
        </p>
        <Button
          danger
          type="primary"
          style={{
            width: "299px",
            marginTop: "20px",
          }}
          onClick={handleOnForceLogoutClick}
        >
          Logout
        </Button>
      </div>
    </>
  );
}

export default Logout;
