import { Button, Image, Tag } from "antd";
import { colors } from "../../utils/colors";
import editIcon from "../../../../assets/icons/edit.svg";

export const getColumns = ({ editDetail, setIsEdit }) => [
  {
    title: "ADMIN NAME",
    key: "1",
    dataIndex: "ORAD_Name",
    width: "16%",
  },
  {
    title: "ADMIN EMAIL",
    key: "2",
    dataIndex: "ORAD_Email",
    width: "16%",
  },
  {
    title: "ADMIN PHONE",
    key: "3",
    dataIndex: "ORAD_Phone",
    width: "16%",
  },
  {
    title: "ADMIN ROLE",
    key: "4",
    dataIndex: "ORAD_RoleName",
    width: "16%",
  },
  {
    title: "STATUS",
    key: "6",
    dataIndex: "ORAD_Is_Active",
    width: "16%",
    render: (_, object) => (
      <Tag
        style={{
          border: "none",
          width: 66,
          textAlign: "center",
          borderRadius: 10,
          color: colors.grey900,
          backgroundColor: object.ORAD_Is_Active
            ? colors.green500
            : colors.red300,
        }}
      >
        {object.ORAD_Is_Active ? "Active" : "Inactive"}
      </Tag>
    ),
  },
  {
    title: "EDIT",
    key: "8",
    dataIndex: "edit",
    width: "10%",
    render: (_, object) => (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button type="link">
          <Image
            src={editIcon}
            onClick={() => {
              setIsEdit((prevState) => !prevState);
              editDetail(object.ORAD_KeyID);
            }}
            alt="normandy"
            preview={false}
          />
        </Button>
      </div>
    ),
  },
];
