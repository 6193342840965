import React from "react";
import { Button, Tag, Image, Space } from "antd";

import { colors } from "../../utils/colors";
import placeHolder from "../../../../assets/place_holder.jpg";
import {
  CloseOutlined,
  CheckOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import VectorImage from "../../../../assets/icons/Vector.svg";

export const getColumns = ({ showDrawer }) => [
  {
    title: "AGENT NAME",
    key: "1",
    width: "28%",
    render: (_, object) => (
      <Space>
        <Image
          style={{ borderRadius: "100%" }}
          width={40}
          height={40}
          preview={false}
          alt="icon"
          src={object?.tbl_ACAG_Agents?.ACAG_Image_Path ?? placeHolder}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = placeHolder;
          }}
        />
        <div style={{ marginLeft: 10 }} direction="vertical">
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 14,
              fontWeight: 500,
              color: colors.black,
            }}
          >
            {object?.tbl_ACAG_Agents?.ACAG_Name}
          </p>
          <p
            style={{
              padding: 0,
              margin: 0,
              fontSize: 14,
              fontWeight: 400,
              color: colors.gry1000,
            }}
          >
            {object?.tbl_ACAG_Agents?.ACAG_Email}
          </p>
        </div>
      </Space>
    ),
  },
  {
    title: "REDEEM",
    key: "3",
    dataIndex: "ARAG_Accumulative_Points",
    width: "14%",
    align: "right",
    render: (_, data) => {
      return (
        <p
          style={{
            textAlign: "right",
          }}
        >
          {_?.toLocaleString()}
        </p>
      );
    },
  },
  {
    title: "QUANTITY",
    key: "4",
    dataIndex: "ARAG_Quantity",
    width: "11%",
    align: "right",
    render: (_, data) => {
      return (
        <p
          style={{
            textAlign: "right",
          }}
        >
          {_?.toLocaleString()}
        </p>
      );
    },
  },
  {
    title: "ORDER STATUS",
    key: "5",
    dataIndex: "ARAG_Dispatch_Status",
    width: "15%",
    align: "center",
    render: (_, object) => (
      <Tag
        style={{
          border: "none",
          textAlign: "center",
          borderRadius: 10,
          color: colors.grey900,
          backgroundColor:
            object?.ARAG_Dispatch_Status === 1
              ? colors.orange500
              : object?.ARAG_Dispatch_Status === 2
                ? colors?.secondaryBlue
                : object?.ARAG_Dispatch_Status === 3
                  ? colors.green500
                  : object?.ARAG_Dispatch_Status === 4
                    ? colors.red300
                    : "",
        }}
      >
        {object?.ARAG_Dispatch_Status === 1
          ? "Ordered"
          : object?.ARAG_Dispatch_Status === 2
            ? "Partially shipped"
            : object?.ARAG_Dispatch_Status === 3
              ? "Shipped"
              : object?.ARAG_Dispatch_Status === 4
                ? "Canceled"
                : ""}
      </Tag>
    ),
  },
  {
    title: " ITEM STATUS",
    key: "6",
    dataIndex: "ARAG_Approved_Status",
    width: "14%",
    align: "center",
    render: (_, object) => (
      <div style={{ display: "flex" }}>
        <CheckOutlined
          style={{
            padding: 8,
            marginRight: 2,
            background: colors.green500,
            borderRadius: 500,
          }}
        />
        <span style={{ fontSize: 14, marginTop: 3, marginRight: 15 }}>
          <span style={{ fontWeight: 400, color: colors.black }}></span>
          {object?.shippedCount}
        </span>
        <Image
          style={{
            padding: 8,
            background: "#C2BAB5",
            borderRadius: "100%",
          }}
          preview={false}
          src={VectorImage}
        ></Image>
        <span
          style={{ fontSize: 14, marginTop: 3, marginRight: 15, marginLeft: 3 }}
        >
          <span style={{ fontWeight: 400, color: colors.black }}>
            {object?.holdCount}
          </span>
        </span>
        <ClockCircleOutlined
          style={{
            background: colors.orange500,
            padding: 8,
            marginRight: 4,
            borderRadius: 500,
          }}
        />
        <span
          style={{ fontSize: 14, marginRight: 15, marginTop: 3, marginLeft: 0 }}
        >
          <span style={{ fontWeight: 400, color: colors.black }}> </span>
          {object?.orderCount}
        </span>
        <CloseOutlined
          style={{
            background: colors.red300,
            marginRight: 5,
            padding: 8,
            borderRadius: 500,
          }}
        />
        <span style={{ fontSize: 14, marginRight: 15, marginTop: 3 }}>
          <span style={{ fontWeight: 400, color: colors.black }}>
            {object?.cancelCount}
          </span>
        </span>
      </div>
    ),
  },
  {
    key: "7",
    width: "4%",
    align: "center",
    render: (_, object) => (
      <Button type="link" onClick={() => showDrawer(object?.ARAG_KeyID)}>
        View detail
      </Button>
    ),
  },
];
