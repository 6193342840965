import React from "react";
import {
  Button,
  Tag,
  Image,
  Typography,
  // Avatar
} from "antd";
import editIcon from "../../../../assets/icons/edit.svg";
import { colors } from "../../utils/colors";
import { RoutesLookup } from '../../../Routes/RoutesLookup';
import { DATAGRID_DEFAULT_PAGE_SIZE } from '../../../Constants/ApplicationConstants';

// const RewardAvatar = (props) => {
//   const [imageBroken, setImageBroken] = useState(false);

//   return imageBroken ? undefined : (
//     <a href={props.src} target="_blank" rel="noreferrer">
//       <img
//         style={{
//           padding: 3,
//           width: 40,
//           height: 40,
//           borderRadius: 8,
//           objectFit: "cover",
//         }}
//         onError={() => setImageBroken(true)}
//         src={props.src}
//         alt="avatar"
//       />
//     </a>
//   );
// };

export const getColumns = ({ navigate, page, pageSize, statusType }) => [
  // {
  //   title: "",
  //   key: "8",
  //   dataIndex: "ARLR_Image_Path",
  //   render: (_, object) =>
  //     object.ARLR_Image_Path ? (
  //       <RewardAvatar src={object.ARLR_Image_Path} />
  //     ) : undefined,
  //   width: "11.1%",
  // },
  {
    title: "Reference No.",
    key: "9",
    dataIndex: "ARLR_KeyID",
    width: "11.1%",
  },
  {
    title: "REWARDS",
    key: "1",
    dataIndex: "ARLR_Title",
    width: "11.1%",
  },
  {
    title: "CATEGORY",
    key: "2",
    dataIndex: "tbl_ARLC_Categories.ARLC_Title",
    width: "11.1%",
    render: (_, object) => (
      <Typography.Text>
        {object?.tbl_ARLC_Categories?.ARLC_Title}
      </Typography.Text>
    ),
  },
  {
    title: "QUANTITY",
    key: "3",
    dataIndex: "ARLR_Quantity",
    width: "11.1%",
    align: "right",
    render: (_, data) => {
      return (
        <p
          style={{
            textAlign: "right",
          }}
        >
          {_?.toLocaleString()}
        </p>
      );
    },
  },
  {
    title: "REDEEMED ON POINTS",
    key: "4",
    dataIndex: "ARLR_Norm_Points",
    width: "11.1%",
    align: "right",
    render: (_, data) => {
      return (
        <p
          style={{
            textAlign: "right",
          }}
        >
          {_?.toLocaleString()}
        </p>
      );
    },
  },
  {
    title: "APPROVAL STATUS",
    key: "5",
    dataIndex: "ARLR_Status",
    width: "11.1%",
    align: "center",
    render: (_, object) => (
      <p>
        {object.ARLR_Status === 1
          ? " Draft "
          : object.ARLR_Status === 2
            ? "Published"
            : ""}
      </p>
    ),
  },
  {
    title: "STATUS",
    key: "6",
    dataIndex: "ARLR_Is_Active",
    width: "16%",
    align: "center",
    render: (_, object) => (
      <Tag
        style={{
          border: "none",
          width: 66,
          textAlign: "center",
          borderRadius: 10,
          color: colors.grey900,
          backgroundColor: object.ARLR_Is_Active
            ? colors.green500
            : colors.red300,
        }}
      >
        {object.ARLR_Is_Active ? "Active" : "Inactive"}
      </Tag>
    ),
  },
  {
    title: "EDIT",
    key: "7",
    width: "11.1%",
    align: "center",
    render: (_, object) => (
      <Button
        type="link"
        onClick={() => {
          navigate(
            `${RoutesLookup.REWARD_INVENTORY}/${object.ARLR_KeyID}?page=${page ?? 1
            }&pageSize=${pageSize ?? DATAGRID_DEFAULT_PAGE_SIZE}&statusType=${statusType ?? undefined
            }`
          );
        }}
      >
        <Image src={editIcon} alt="normandy" preview={false} />
      </Button>
    ),
  },
];
