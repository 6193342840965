import { Button, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import { useNavigate } from "react-router";
import AdminAppLayout from "../../Layouts/AdminLayout";
import { colors } from "../../utils/colors";
import {
  generateSearchParamsGetter,
  getNewSearchParamStringWithArgs,
  getSearchParams,
} from "../../utils/helpers";
import { getColumns } from "./columns";
import { SearchOutlined } from "@ant-design/icons";
import TableComponent from "../../components/Table/TableComponent";
import { BASE_URL } from "../../../Constants/EnvironmentVariableProvider";
import { RoutesLookup } from "../../../Routes/RoutesLookup";
import { REWARDS_FILTER_DATA_API } from "../../../API/paths";
import { DATAGRID_DEFAULT_PAGE_SIZE } from "../../../Constants/ApplicationConstants";

const RewardInventory = () => {
  let [spObj, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: DATAGRID_DEFAULT_PAGE_SIZE.toString(),
  });
  spObj.get('page') === null && spObj.set('page', '1');
  spObj.get('pageSize') === null && spObj.set('pageSize', DATAGRID_DEFAULT_PAGE_SIZE.toString());

  // eslint-disable-next-line no-unused-vars
  let [id, page, pageSize, filterName, statusType] = getSearchParams(spObj);

  const navigate = useNavigate();
  const getNewSearchParamString = generateSearchParamsGetter(spObj);
  const debounceFetcher = useDebouncedCallback((search) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({ page: 1, pageSize, search, statusType })
    );
  }, 600);
  const statusApi =
    BASE_URL + REWARDS_FILTER_DATA_API + getNewSearchParamString();
  const {
    data: rewards,
    isValidating: loading,
    mutate,
  } = useSWR({
    url: statusApi,
  });
  useEffect(() => {
    if (mutate) mutate();
  }, [filterName, pageSize, page, mutate, statusType]);

  const onPaginationChange = async (page, pageSize) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page,
        pageSize,
        statusType,
        search: filterName,
      })
    );
  };

  const [searchState, setSearchState] = useState(filterName ? filterName : '')
  useEffect(() => {
    setSearchState(filterName)
  }, [filterName])

  const handleSearch = (e) => {
    debounceFetcher(e.target.value);
    setSearchState(e.target.value)
  };

  const [activeTab, setActiveTab] = useState(
    statusType ? parseInt(statusType) : 0
  );
  const [dataLoading, setDataLoading] = useState(false);

  const columns = getColumns({ navigate, page, pageSize, statusType });

  useEffect(() => {
    setTimeout(() => {
      setDataLoading(false);
    }, 1000);
  }, [dataLoading]);

  useEffect(() => {
    setActiveTab(parseInt(statusType ?? 0))
  }, [statusType])

  let statusTabs = [
    {
      btnText: "All",
      total: rewards?.allCount,
      onClick: () => {
        setActiveTab(0);
        setDataLoading(true);
        setSearchParams(
          getNewSearchParamStringWithArgs({
            page: 1,
            pageSize,
            statusType: 0,
          })
        );
      },
    },
    {
      btnText: "Active",
      total: rewards?.activeCount,
      onClick: () => {
        setActiveTab(1);
        setSearchParams(
          getNewSearchParamStringWithArgs({
            page: 1,
            pageSize,
            statusType: 1,
          })
        );
        setDataLoading(true);
      },
    },
    {
      btnText: "Inactive",
      total: rewards?.inActiveCount,
      onClick: () => {
        setActiveTab(2);
        setDataLoading(true);
        setSearchParams(
          getNewSearchParamStringWithArgs({
            page: 1,
            pageSize,
            statusType: 2,
          })
        );
      },
    },
    {
      btnText: "Draft",
      total: rewards?.draftCount,
      onClick: () => {
        setActiveTab(3);
        setDataLoading(true);
        setSearchParams(
          getNewSearchParamStringWithArgs({
            page: 1,
            pageSize,
            statusType: 3,
          })
        );
      },
    },
    {
      btnText: "Published",
      total: rewards?.publishedCount,
      onClick: () => {
        setActiveTab(4);
        setDataLoading(true);
        setSearchParams(
          getNewSearchParamStringWithArgs({
            page: 1,
            pageSize,
            statusType: 4,
          })
        );
      },
    },
  ]

  return (
    <AdminAppLayout>
      <div
        style={{
          paddingLeft: 60,
          paddingRight: 60,
          marginTop: 56,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Space direction="horizontal" size={40}>
            {statusTabs.map((item, i) => (
              <Button
                style={{
                  color: activeTab === i && colors.grey900,
                  backgroundColor: activeTab === i && colors.grayNeutral100,
                }}
                key={item.btnText}
                onClick={item.onClick}
                type="text"
              >
                {item.btnText} ({item.total})
              </Button>
            ))}
          </Space>

          <div
            style={{
              display: "flex",
              width: 480,
              justifyContent: "flex-end",
            }}
          >
            <Input
              size="large"
              placeholder="Search Rewards..."
              defaultValue={filterName}
              value={searchState}
              onChange={handleSearch}
              allowClear
              prefix={
                <SearchOutlined
                  style={{
                    color: "rgba(0,0,0,.45)",
                  }}
                />
              }
            />
            <Button
              onClick={() => {
                navigate(
                  `${RoutesLookup.REWARD_INVENTORY}/create?page=${page ?? 1
                  }&pageSize=${pageSize}`
                );
              }}
              style={{
                marginLeft: 20,
                marginTop: 5,
              }}
              type="primary"
            >
              + Add new reward
            </Button>
          </div>
        </div>
        <div style={{ marginTop: 28 }}>
          <TableComponent
            loading={loading}
            data={rewards?.result}
            totalRecords={rewards?.totalRecords}
            columns={columns}
            border
            pagination={{
              totalPage: rewards?.totalPages * pageSize,
              pageSize: pageSize,
            }}
            onPaginationChange={onPaginationChange}
            isServerPagination
          />
        </div>
      </div>
    </AdminAppLayout>
  );
};

export default RewardInventory;
