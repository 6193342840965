import React from "react";
import PropTypes from "prop-types";
import { Table } from "antd";

import { colors } from "../../utils/colors";

const PointsFormTable = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <Table
        rowKey="ACAG_KeyID"
        ref={props.tableRef}
        rowSelection={
          !props.rowSelection
            ? null
            : {
                onChange: (_, selectedRows) => {
                  props.setSelectedRows(selectedRows);
                },
              }
        }
        style={{
          border: `1px solid ${colors.grayNeutral100}`,
          borderRadius: 6,
        }}
        size="small"
        className="tableHeight"
        dataSource={props.data}
        loading={props.loading}
        columns={props.columns}
        pagination={false}
        scroll={{ x: "max-content", y: "calc(100vh - 310px)" }}
      />
    </div>
  );
};

PointsFormTable.propTypes = {
  data: PropTypes.array,
  totalRecords: PropTypes.number,
  tableData: PropTypes.array,
  loading: PropTypes.bool,
  border: PropTypes.bool,
  rowSelection: PropTypes.bool,
  columns: PropTypes.array,
  isServerPagination: PropTypes.bool,
  pagination: PropTypes.any,
  onPaginationChange: PropTypes.func,
  setSelectedRows: PropTypes.func,
};

export default PointsFormTable;
