import React, { useEffect, useState } from "react";
import axios from "axios";
import "./style.css";
import {
  Form,
  Input,
  Col,
  Row,
  Spin,
  Button,
  Space,
  DatePicker,
  Select,
  Typography,
  Upload,
  Image,
  message,
  InputNumber,
} from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { colors } from "../../../utils/colors";
import variantIcon from "../../../../../assets/icons/variant2.svg";
import rightArrow from "../../../../../assets/icons/rightArrow.svg";
import downArrow from "../../../../../assets/icons/downArrow.svg";
import trashImg from "../../../../../assets/icons/trash.svg";
import { dateFormat } from "../../../utils/helpers";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { BASE_URL } from '../../../../Constants/EnvironmentVariableProvider';
import { RoutesLookup } from '../../../../Routes/RoutesLookup';
import {
  UPLOAD_IMAGE_API,
  GET_REWARD_BYID
} from '../../../../API/paths';
import { DATAGRID_DEFAULT_PAGE_SIZE } from '../../../../Constants/ApplicationConstants';
import RewardImages from './RewardImages';

const { Option } = Select;
const { Text, Title } = Typography;

const AddNewRewardForm = ({
  id,
  form,
  handleSubmit,
  toggleModal,
  categories,
  loading,
  statusType,
  returnPage,
  returnPageSize,
}) => {
  const navigate = useNavigate();
  const [changeIcon, setChangeIcon] = useState(false);

  const [previewUrl, setImageUrl] = useState([""]);
  const [date, setDate] = useState();
  const [featuredImagePath, setFeaturedImagePath] = useState();
  const [imagesPath, setImagesPath] = useState();
  const commonPaginationParams = `?page=${returnPage ?? 1}&pageSize=${returnPageSize ?? DATAGRID_DEFAULT_PAGE_SIZE
    }&statusType=${statusType ?? undefined}`;
  const getDataById = async (rewardId) => {
    try {
      const response = await axios.get(`${GET_REWARD_BYID}?Id=${rewardId}`);
      const result = response?.data?.result;
      setDate(result);
      if (result?.tbl_ARRV_Reward_Variants?.length > 0) {
        setChangeIcon(true);
        const veriante = [];
        // eslint-disable-next-line array-callback-return
        result?.tbl_ARRV_Reward_Variants?.map((item) => {
          veriante.push({
            title: item?.ARRV_Title,
            image: item?.ARRV_Image_Path,
          });
        });
        form.setFieldsValue({
          veriante: veriante,
        });
      }

      console.log("result", result?.ARLR_Start_Date);
      console.log(
        "DATE*********",
        dateFormat(result?.ARLR_Start_Date, "MM-DD-YYYY")
      );

      if (!result.ARLR_Images_Path) {
        result["ARLR_Images_Path"] = [];
      }
      if (result?.ARLR_Image_Path) {
        result?.ARLR_Images_Path?.unshift(result.ARLR_Image_Path)
      } else if (result.ARLR_Images_Path?.length > 0) {
        result["ARLR_Image_Path"] = result.ARLR_Images_Path[0];
      }

      setFeaturedImagePath(() => result?.ARLR_Image_Path);
      setImagesPath(() => result?.ARLR_Images_Path);
      form.setFieldsValue({
        reward_name: result?.ARLR_Title,
        description: result?.ARLR_Description,
        reward_source: result?.ARLR_Source,
        cost: result?.ARLR_Cost,
        comment: result?.ARLR_Comment,
        reward_category: result?.ARLR_Category_ARLC_KeyID,
        quantity: result?.ARLR_Quantity,
        saveType: result?.ARLR_Status,
        radeem_on_points: result?.ARLR_Norm_Points,
        status: result?.ARLR_Is_Active,
        reward_image: result?.ARLR_Image_Path,
        reward_images: result?.ARLR_Images_Path,
        featured: result?.ARLR_Is_Featured,
        redeemablequantity: result?.ARLR_Redeemable_Quantity,
        start_date: dayjs(
          dateFormat(result?.ARLR_Start_Date, "MM-DD-YYYY"),
          "MM-DD-YYYY"
        ),
        expiry_date: result?.ARLR_Expiry_Date
          ? dayjs(
            dateFormat(result?.ARLR_Expiry_Date, "MM-DD-YYYY"),
            "MM-DD-YYYY"
          )
          : "",
      });
      // setUploadedImageUrl(result?.ARLR_Image_Path);
    } catch (e) {
      console.log(e);
    }
  };
  console.log("get byID", date?.ARLR_Expiry_Date?.slice(0, -9));

  useEffect(() => {
    if (id !== "create") {
      getDataById(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    if (e.file.status === "uploading") {
      return;
    }
    if (e.file.status === "done") {
      // Get this url from response in real world.
    }
  };
  const sendBase64Data = async (base64Code, index, fileName, veriante) => {
    const value = {
      Image_String: base64Code,
      Image_Name: fileName,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}${UPLOAD_IMAGE_API}`,
        value
      );
      message.success("Image upload successfully");
      let varData = veriante;
      varData[index] = {
        image: response?.data,
        title: form.getFieldValue("veriante")[index]?.title,
      };
      form.setFieldsValue({
        veriante: varData,
      });
    } catch (e) {
      console.log(e);
    }
  };


  return (
    <Spin spinning={loading}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ veriante: [undefined] }}
        onFinish={handleSubmit}
        className='edit-reward'
      >
        <Row
          style={{
            position: "relative",
          }}
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row" span={12}>
            <Form.Item
              label="Reward Name"
              name="reward_name"
              rules={[
                { required: true, message: "Please enter a Reward Name" },
              ]}
            >
              <Input size="large" placeholder="5 Days tour to Malaysia" />
            </Form.Item>
            <Text
              onClick={toggleModal}
              style={{
                position: "absolute",
                right: 21,
                zIndex: 1000,
                color: colors.secondaryBlue,
                fontSize: 14,
                fontWeight: 500,
                cursor: "pointer",
              }}
            >
              + Add New Category
            </Text>
            <Form.Item
              label="Reward Category"
              name="reward_category"
              rules={[{ required: true, message: "Select Category" }]}
            >
              <Select
                showSearch
                placeholder="Select Category"
                size="large"
                optionFilterProp="children"
                notFoundContent="No options found"
              >
                {categories?.map((item) => (
                  <Option key={item?.ARLC_KeyID} value={item.ARLC_KeyID}>
                    {item.ARLC_Title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Add Description"
              name="description"
              rules={[
                { required: true, message: "Please enter a description" },
              ]}
            >
              <TextArea
                style={{ minHeight: 150 }}
                size="large"
                placeholder="Description"
              />
            </Form.Item>
            <Form.Item
              label="Gift Purchase Source"
              name="reward_source"
            >
              <Input
                type="text"
                size="large"
                placeholder="Gift Purchase Source"

              />
            </Form.Item>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col span={12}>
                <Form.Item
                  label="Quantity"
                  name="quantity"
                  rules={[
                    { required: true, message: "Please select a quantity" },
                  ]}
                >
                  <Input
                    type="number"
                    size="large"
                    min={0}
                    placeholder="Select Quantity"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Cost"
                  name="cost"
                >
                  <InputNumber
                    type='number'
                    size="large"
                    min={0}
                    controls={false}
                    placeholder="Cost"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Redeem On Points"
                  name="radeem_on_points"
                  rules={[{ required: true, message: "Please select a point" }]}
                >
                  <Input
                    type="number"
                    size="large"
                    min={0}
                    placeholder="Select Point"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Start date"
                  name="start_date"
                  rules={[{ required: true, message: "Please enter a date" }]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                    size="large"
                    format="MM-DD-YYYY"
                  // defaultValue={dayjs('12-30-2023', 'MM-DD-YYYY')}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  label="Expiry date"
                  name="expiry_date"
                  rules={[
                    { required: false, message: "Please enter a date" },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          value &&
                          new Date(getFieldValue("start_date")) <=
                          new Date(getFieldValue("expiry_date"))
                        ) {
                          return Promise.resolve();
                        }
                        if (!value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "Expiry Date must be greater than start date"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Select Date"
                    size="large"
                    format="MM-DD-YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Status"
              name="status"
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <Select placeholder="Active" size="large">
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Featured"
              name="featured"
              rules={[{ required: true, message: "Please select a status" }]}
            >
              <Select placeholder="Yes" size="large">
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>
            <div>
              <Form.Item
                label="Maximum Redeemable Quantity"
                name="redeemablequantity"
                rules={[{ required: false }]}
              >
                <Input
                  type="number"
                  size="large"
                  min={0}
                  placeholder="Maximum Redeemable Quantity"
                />
              </Form.Item>
              <Form.Item
                label="Admin Comment"
                name="comment"
              >
                <TextArea
                  style={{ minHeight: 150 }}
                  size="large"
                  placeholder="Add Admin Comment"
                />
              </Form.Item>
            </div>
            <Row>
              <Col
                span={24}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 50,
                }}
              >
                {(id === "create" ||
                  (form.getFieldValue("saveType") === 1 &&
                    id !== "create")) && (
                    <Button
                      style={{
                        backgroundColor: colors.white,
                        color: colors.black,
                        borderColor: colors.black,
                      }}
                      type="primary"
                      onClick={() => {
                        form.setFieldsValue({ saveType: 1 });
                      }}
                      htmlType="submit"
                    >
                      Save as draft
                    </Button>
                  )}
                <Space direction="horizontal" size={20}>
                  <Button
                    style={{ color: colors.black }}
                    type="default"
                    onClick={() => {
                      form.resetFields();
                      navigate(
                        `${RoutesLookup.REWARD_INVENTORY}${commonPaginationParams}`
                      );
                    }}
                  >
                    Cancel
                  </Button>
                  <Form.Item style={{ display: "none" }} name="saveType">
                    <Input />
                  </Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldsValue({ saveType: 2 });
                    }}
                    htmlType="submit"
                  >
                    {form.getFieldValue("saveType") === undefined ||
                      (id !== "create" && form.getFieldValue("saveType") !== 2)
                      ? "Publish"
                      : "Save"}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-row" span={12}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Row style={{ width: "100%" }}>
                <Col span={24}>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Form.Item name="reward_images" style={{ margin: 0 }}>
                      <RewardImages form={form} featuredImagePath={featuredImagePath} imagesPath={imagesPath} />
                    </Form.Item>
                    <Form.Item name="reward_image" />
                  </div>
                </Col>
              </Row>
            </div>

            <div style={{ padding: 50 }}>
              <div>
                <Title
                  level={5}
                  style={{ color: colors.black, textAlign: "left" }}
                >
                  Add Variant
                </Title>
                <div
                  onClick={() => setChangeIcon(!changeIcon)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    marginTop: -6,
                  }}
                >
                  <Text>Upload Variant image and assign name to variant </Text>
                  <Image
                    style={{ position: "relative", left: 0 }}
                    preview={false}
                    src={changeIcon ? downArrow : rightArrow}
                    alt="icon"
                  />
                </div>
              </div>
              {changeIcon && (
                <div style={{ marginTop: 16 }}>
                  <Form.List name="veriante">
                    {(fields, { add, remove }) => (
                      <>
                        <div
                          style={{
                            padding: 20,
                            backgroundColor: colors.bgGrey,
                            borderRadius: 8,
                          }}
                        >
                          {/* 🔥🔥🔥 */}
                          {fields?.map(({ key, name, ...restField }, index) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                              key={key}
                            >
                              <Form.Item
                                style={{
                                  background: "white",
                                  width: 60,
                                  height: 60,
                                  borderRadius: 6,
                                  paddingTop: form.getFieldValue("veriante")[
                                    index
                                  ]?.image
                                    ? 0
                                    : 16,
                                  cursor: "pointer",
                                  textAlign: "center",
                                }}
                                {...restField}
                                name={[name, "image"]}
                              >
                                <Upload
                                  className="variant"
                                  name="avatar"
                                  showUploadList={false}
                                  beforeUpload={(file) => {
                                    const fileExtension =
                                      file.name.split(".")[1];
                                    const isCorrectType =
                                      file.type === "image/jpeg" ||
                                      fileExtension === "jpeg" ||
                                      file.type === "image/png" ||
                                      fileExtension === "png" ||
                                      file.type === "image/jpg" ||
                                      fileExtension === "jpg";
                                    if (!isCorrectType) {
                                      message.error(
                                        "You can only upload jpg/jpeg/png images!"
                                      );
                                      console.log(
                                        "error val",
                                        form.getFieldValue("veriante")[index]
                                          ?.image?.image
                                      );
                                      return;
                                    }
                                    const reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onload = () => {
                                      sendBase64Data(
                                        reader.result.split(",")[1],
                                        index,
                                        file?.name,
                                        form.getFieldValue("veriante")
                                      );
                                    };
                                    reader.readAsDataURL(file);
                                    return false;
                                  }}
                                  onChange={handleChange}
                                >
                                  {form.getFieldValue("veriante")[index]
                                    ?.image ? (
                                    <Image
                                      src={
                                        form.getFieldValue("veriante")[index]
                                          ?.image
                                      }
                                      alt="avatar"
                                      preview={false}
                                      style={{
                                        borderRadius: 6,
                                        width: 60,
                                        height: 60,
                                      }}
                                    />
                                  ) : (
                                    <div>
                                      {loading ? (
                                        <LoadingOutlined />
                                      ) : (
                                        <Image
                                          preview={false}
                                          alt="icon"
                                          src={variantIcon}
                                          width={20}
                                          height={20}
                                        />
                                      )}
                                    </div>
                                  )}
                                </Upload>
                              </Form.Item>

                              <Form.Item
                                {...restField}
                                style={{
                                  marginLeft: 16,
                                  marginRight: 6,
                                  width: "76%",
                                }}
                                name={[name, "title"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing variant",
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder="Add Variant Name"
                                />
                              </Form.Item>
                              <Form.Item>
                                <Button
                                  style={{ padding: 3 }}
                                  type="text"
                                  onClick={() => {
                                    let data = previewUrl;
                                    data.splice(index, 1);
                                    setImageUrl(data);
                                    remove(name);
                                  }}
                                >
                                  <Image preview={false} src={trashImg} />
                                </Button>
                              </Form.Item>
                            </div>
                          ))}
                        </div>
                        <Button
                          style={{
                            color: colors.primaryGreen,
                            fontWeight: 500,
                            marginTop: 18,
                          }}
                          htmlType="button"
                          type="dashed"
                          onClick={add}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add Variant
                        </Button>
                      </>
                    )}
                  </Form.List>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default AddNewRewardForm;
