import { useContext, useEffect, useState } from "react";
import AgentLayout from "../../../AdminPortal/Layouts/Layout";
import {
  Row,
  Col,
  Typography,
  Avatar,
  Spin,
  Form,
  Input,
  Space,
  Image,
  Divider,
  Button,
  Tag,
  Tabs,
  message,
  Popover,
  Upload,
} from "antd";
import useSWR from "swr";
import { PlusOutlined, SendOutlined } from "@ant-design/icons";
import { colors } from "../../../AdminPortal/utils/colors";
import AdminNoteModal from "../../../AdminPortal/features/ManageAgent/AdminNoteModal";
import "./style.css";
import profileIcon from "../../../../assets/icons/profile.svg";
import alertImage from "../../../../assets/Primaryfill.png";
import { formateDateWithTime } from "../../../AdminPortal/utils/helpers";
import UserContext from "../../../Contexts/UserContext";
import axios from "axios";
import { isMobile } from "react-device-detect";

import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import {
  ADD_NOTES_API,
  CART_HISTORY_API,
  UPLOAD_IMAGE_API,
  AGENT_NOTES,
  EDIT_AGENT_API,
  GET_AGENT_BY_ID_API,
} from '../../../API/paths';

const { Text, Title } = Typography;

const AgentDetail = () => {
  const [isEditDetails, setIsEditDetails] = useState(false);
  const { auth } = useContext(UserContext);
  const [toglleNote, setTogleNote] = useState(false);
  const [noteModalOpen, setNoteModal] = useState(false);
  const [noteId, setNoteId] = useState();
  const [uploadedImageUrl, setUploadedImageUrl] = useState();
  const [loader, setLoading] = useState(false);
  const cart_history = BASE_URL + CART_HISTORY_API;
  const { data: redeem_history, isValidating: loadingPage } = useSWR({
    url: cart_history,
  });
  const singleNote = (noteId) => {
    setNoteId(noteId);
    setNoteModal(true);
  };
  const sendRewardImage = async (base64Image, fileName) => {
    const value = {
      Image_String: base64Image,
      Image_Name: fileName,
    };
    try {
      const response = await axios.post(
        `${BASE_URL}${UPLOAD_IMAGE_API}`,
        value
      );
      setUploadedImageUrl(response?.data);
      changeImage(response?.data);
    } catch (e) {
      console.log(e);
    }
  };
  const handleFileUpload = (file) => {
    const fileExtension = file.name.split(".")[1];
    const isCorrectType =
      file.type === "image/jpeg" ||
      fileExtension === "jpeg" ||
      file.type === "image/png" ||
      fileExtension === "png" ||
      file.type === "image/jpg" ||
      fileExtension === "jpg";
    if (!isCorrectType) {
      message.error("You can only upload jpg/jpeg/png images!");
      return;
    }
    const isCorrectSize = file.size / 1024 / 1024 <= 10;
    if (!isCorrectSize) {
      message.error("File must be smaller than 10MB!");
    }
    if (isCorrectType && isCorrectSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        sendRewardImage(reader.result.split(",")[1], file?.name);
      };
      return false;
    }
  };

  const editClick = () => setIsEditDetails(!isEditDetails);

  const { data: agent, mutate } = useSWR({
    url: BASE_URL + GET_AGENT_BY_ID_API + "?KeyId=" + auth?.result?.ACAG_KeyID,
  });
  const {
    data: notes,
    isValidating: loading,
    mutate: notesMuate,
  } = useSWR({
    url: BASE_URL + AGENT_NOTES + "?KeyId=" + auth?.result?.ACAG_KeyID,
  });
  notes?.result?.sort(
    (a, b) => {
      const aDate = new Date(a.AGNO_zalt_altered_date).getTime()
      const bDate = new Date(b.AGNO_zalt_altered_date).getTime()
      return aDate - bDate
    });

  const [form] = Form.useForm();
  const notesForm = async (values) => {
    const notes_data = {
      AGNO_Agent_ACAG_KeyID: auth?.result?.ACAG_KeyID,
      AGNO_Notes: values?.notes,
    };
    notesMuate(async () => {
      try {
        const response = await axios.post(ADD_NOTES_API, notes_data);
        if (response?.data?.success) {
          message.success(response?.data?.message);
          setTogleNote(false);
        } else {
          message.error(response?.data?.message);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      email: agent?.result?.ACAG_Email,
      phone: agent?.result?.ACAG_Phone,
      agencyName: agent?.result?.ACAG_Agency_Name,
      address: agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line1,
      address2: agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line2,
      city: agent?.result?.tbl_MSAD_Addresses?.MSAD_City,
      state: agent?.result?.tbl_MSAD_Addresses?.MSAD_State,
      zip: agent?.result?.tbl_MSAD_Addresses?.MSAD_ZipCode,
    });
    setUploadedImageUrl(agent?.result?.ACAG_Image_Path);
  }, [agent, form]);
  const Details = () => {
    return (
      <>
        <Row
          style={{ marginTop: 30 }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>
                Email Address
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {agent?.result?.ACAG_Email}
              </Text>
            </Space>
          </Col>
          <Col className="gutter-row" span={8}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>
                Phone Number
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {agent?.result?.ACAG_Phone}
              </Text>
            </Space>
          </Col>
          <Col className="gutter-row" span={12}></Col>
        </Row>
        <Row
          style={{ marginTop: 30 }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>Agency Name</Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                  textTransform: "capitalize",
                }}
              >
                {agent?.result?.ACAG_Agency_Name}
              </Text>
            </Space>
          </Col>
          {/* <Col style={{ display: "none" }} className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>Password </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                ********
              </Text>
            </Space>
          </Col> */}
          {/* </Row>
        <Row
          style={{ marginTop: 30 }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        > */}
          <Col className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>
                Street Address 1{" "}
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line1}
              </Text>
            </Space>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 30 }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>
                Street Address 2
              </Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line2}
              </Text>
            </Space>
          </Col>
          <Col className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>City</Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {agent?.result?.tbl_MSAD_Addresses?.MSAD_City}
              </Text>
            </Space>
          </Col>
        </Row>
        <Row
          style={{ marginTop: 30 }}
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
        >
          <Col className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>State</Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {" "}
                {agent?.result?.tbl_MSAD_Addresses?.MSAD_State}
              </Text>
            </Space>
          </Col>
          <Col className="gutter-row" span={12}>
            <Space direction="vertical">
              <Text style={{ fontSize: 14, fontWeight: 500 }}>ZIP</Text>
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: colors.black,
                }}
              >
                {agent?.result?.tbl_MSAD_Addresses?.MSAD_ZipCode}
              </Text>
            </Space>
          </Col>
        </Row>
      </>
    );
  };
  const changeImage = (val) => {
    const updateData = {
      ACAG_KeyID: agent?.result?.ACAG_KeyID,
      ACAG_Email: agent?.result?.ACAG_Email,
      ACAG_Phone: agent?.result?.ACAG_Phone,
      ACAG_Agency_Name: agent?.result?.ACAG_Agency_Name,
      ACAG_Points_Earned: agent?.result?.ACAG_Points_Earned,
      ACAG_Points_Redeemed: agent?.result?.ACAG_Points_Redeemed,
      ACAG_Image_String: val ? val : agent?.result?.ACAG_Image_String,
      ACAG_Is_Active: agent?.result?.ACAG_Is_Active,
      /* ACAG_Password: agent?.result?.ACAG_Password, */
      tbl_MSAD_Addresses: {
        MSAD_City: agent?.result?.tbl_MSAD_Addresses?.MSAD_City,
        MSAD_State: agent?.result?.tbl_MSAD_Addresses?.MSAD_State,
        MSAD_ZipCode: agent?.result?.tbl_MSAD_Addresses?.MSAD_ZipCode,
        MSAD_Address_Line1:
          agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line1,
        MSAD_Address_Line2:
          agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line2,
      },
    };

    mutate(async () => {
      setLoading(true);
      try {
        const response = await axios.put(EDIT_AGENT_API, updateData);
        setLoading(false);
        if (response?.data?.success) {
          message.success(response?.data?.message);
        } else {
          message.error(response?.data?.message);
        }
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    });
  };

  const EditDetails = () => {
    const onFinish = (values) => {
      const updateData = {
        ACAG_KeyID: agent?.result?.ACAG_KeyID,
        ACAG_Email: values?.email,
        ACAG_Phone: values?.phone,
        ACAG_Agency_Name: values?.agencyName,
        ACAG_Points_Earned: agent?.result?.ACAG_Points_Earned,
        ACAG_Points_Redeemed: agent?.result?.ACAG_Points_Redeemed,
        ACAG_Image_String: uploadedImageUrl
          ? uploadedImageUrl
          : agent?.result?.ACAG_Image_Path,
        ACAG_Is_Active: agent?.result?.ACAG_Is_Active,
        /* ACAG_Password: values?.ACAG_Password="", */
        tbl_MSAD_Addresses: {
          MSAD_City: values?.city,
          MSAD_State: values?.state,
          MSAD_ZipCode: values?.zip,
          MSAD_Address_Line1: values?.address,
          MSAD_Address_Line2: values?.address2,
        },
      };

      mutate(async () => {
        setLoading(true);
        try {
          const response = await axios.put(EDIT_AGENT_API, updateData);
          setLoading(false);
          if (response?.data?.success) {
            message.success(response?.data?.message);
          } else {
            message.error(response?.data?.message);
          }
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      });
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    return (
      <Form
        form={form}
        name="basic"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row
          gutter={24}
          style={
            isMobile ? { marginTop: 30 } : { marginTop: 30, marginRight: 50 }
          }
          className="custom-input-height"
        >
          <Col span={12}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  message: "Please enter your email!",
                },
              ]}
            >
              <Input disabled placeholder={agent?.result?.ACAG_Email} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number!",
                },
              ]}
            >
              <Input
                type="number"
                min={0}
                placeholder={agent?.result?.ACAG_Phone}
                value={agent?.result?.ACAG_Phone}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Agency Name"
              name="agencyName"
              rules={[
                {
                  message: "Please enter your agency name!",
                },
              ]}
            >
              <Input disabled placeholder="Normandy Partners" />
            </Form.Item>
          </Col>
          <Col style={{ display: "none" }} span={12}>
            <Form.Item
              label="Password"
              name="ACAG_Password"
              rules={[
                {
                  message: "Please enter your Password!",
                },
                {
                  min: 4,
                  message: "Password should contain at least 4 characters.",
                },
              ]}
            >
              <Input.Password placeholder="Password" size="small" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Street address 1"
              name="address"
              rules={[
                {
                  required: true,
                  message: "Please enter your address 1!",
                },
              ]}
            >
              <Input placeholder="Mr John Smith. 132, My street, Kingston..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Street address 2"
              name="address2"
              rules={[
                {
                  message: "Please enter your address 2!",
                },
              ]}
            >
              <Input placeholder="Mr John Smith. 132, My street, Kingston..." />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please enter your city!",
                },
              ]}
            >
              <Input placeholder="Houston" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please enter your state!",
                },
              ]}
            >
              <Input placeholder="state" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Zip"
              name="zip"
              rules={[
                {
                  required: true,
                  message: "Please enter your zip code!",
                },
              ]}
            >
              <Input placeholder="0123" />
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            float: "right",
            marginRight: isMobile ? 0 : 62,
            marginTop: isMobile ? 0 : 93,
          }}
        >
          <Form.Item>
            <Button type="text" onClick={editClick}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: 97, marginLeft: 10 }}
            >
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    );
  };

  const History = () => {
    return (
      <>
        {!isMobile ? (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th
                  style={{
                    float: "left",
                    color: colors.black,
                    fontWeight: 600,
                  }}
                >
                  This Month Rewards
                </th>
                <th style={{ color: colors.black, fontWeight: 600 }}>
                  Quantity
                </th>
                <th style={{ color: colors.black, fontWeight: 600 }}>
                  Points Redeem
                </th>
                <th style={{ color: colors.black, fontWeight: 600 }}>
                  Accumulative Points
                </th>
                <th style={{ color: colors.black, fontWeight: 600 }}>
                  Order Status
                </th>
                <th style={{ color: colors.black, fontWeight: 600 }}>
                  Time & Date
                </th>
                <th style={{ color: colors.black, fontWeight: 600 }}>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {redeem_history?.result?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                      borderRadius: 7,
                    }}
                  >
                    <td style={{ padding: 5 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Image
                          preview={false}
                          style={{
                            border: "1px solid" + colors.grayNeutral300,
                            borderRadius: 7,
                          }}
                          width={57}
                          height={57}
                          src={item?.tbl_ARLR_Rewards?.ARLR_Image_Path}
                          alt="Picture"
                        />
                        <div style={{ marginLeft: 8 }}>
                          <Space direction="vertical" size={0}>
                            <Text style={{ fontWeight: 500 }}>
                              {item?.tbl_ARLR_Rewards?.ARLR_Title}
                            </Text>
                          </Space>
                        </div>
                      </div>
                    </td>

                    <td style={{ textAlign: "center", fontWeight: 600 }}>
                      <Text>{item?.ARIT_Quantity}</Text>
                    </td>
                    <td style={{ textAlign: "center", fontWeight: 600 }}>
                      <Text>{item?.ARIT_Norm_Points}</Text>
                    </td>
                    <td style={{ textAlign: "center", fontWeight: 600 }}>
                      <Text>{item?.ARIT_Accumulative_Points}</Text>
                    </td>
                    <td style={{ textAlign: "center", fontWeight: 600 }}>
                      <Tag
                        style={{
                          border: "none",
                          textAlign: "center",
                          borderRadius: 10,
                          color: colors.grey900,
                          backgroundColor:
                            item?.ARIT_Dispatch_Status === 1
                              ? colors.orange500
                              : item?.ARIT_Dispatch_Status === 2
                                ? colors?.secondaryBlue
                                : item?.ARIT_Dispatch_Status === 3
                                  ? colors.green500
                                  : item?.ARIT_Dispatch_Status === 4
                                    ? colors.red300
                                    : "",
                        }}
                      >
                        {item?.ARIT_Dispatch_Status === 1
                          ? "Ordered"
                          : item?.ARIT_Dispatch_Status === 2
                            ? "Partially shipped"
                            : item?.ARIT_Dispatch_Status === 3
                              ? "Shipped"
                              : item?.ARIT_Dispatch_Status === 4
                                ? "Canceled"
                                : ""}
                      </Tag>
                    </td>
                    <td style={{ textAlign: "center", fontWeight: 600 }}>
                      {formateDateWithTime(item?.ARIT_Redemption_Date)}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item?.ARIT_Approved_Status === 3 ? (
                        <Popover
                          color="#FFF4F4"
                          content={item?.ARIT_Cancel_Reason}
                          title={
                            <>
                              <Image src={alertImage} />
                              <Text
                                style={{ color: colors.black, fontWeight: 500 }}
                              >
                                {" "}
                                Reason
                              </Text>
                            </>
                          }
                          trigger="hover"
                          placement="leftTop"
                        >
                          <Text
                            style={{
                              fontWeight: 600,
                              color:
                                item?.ARIT_Approved_Status === 1
                                  ? colors.orange
                                  : item?.ARIT_Approved_Status === 2
                                    ? colors.green200
                                    : item?.ARIT_Approved_Status === 3
                                      ? colors.red200
                                      : item?.ARIT_Approved_Status === 4
                                        ? colors.black
                                        : item?.ARIT_Approved_Status === 5
                                          ? "blue"
                                          : "",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {!isMobile && (
                              <span
                                style={{
                                  fontSize: 40,
                                  marginTop: -22,
                                  marginRight: 3,
                                }}
                              >
                                .{" "}
                              </span>
                            )}
                            {item?.ARIT_Approved_Status === 1
                              ? "Ordered"
                              : item?.ARIT_Approved_Status === 2
                                ? "Shipped"
                                : item?.ARIT_Approved_Status === 3
                                  ? "Rejected"
                                  : item?.ARIT_Approved_Status === 4
                                    ? "Partially Shipped"
                                    : item?.ARIT_Approved_Status === 5
                                      ? "On-Hold"
                                      : ""}
                          </Text>
                        </Popover>
                      ) : (
                        <Text
                          style={{
                            fontWeight: 600,
                            color:
                              item?.ARIT_Approved_Status === 1
                                ? colors.orange
                                : item?.ARIT_Approved_Status === 2
                                  ? colors.green200
                                  : item?.ARIT_Approved_Status === 3
                                    ? colors.red200
                                    : item?.ARIT_Approved_Status === 4
                                      ? colors.black
                                      : item?.ARIT_Approved_Status === 5
                                        ? "blue"
                                        : "",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {
                            <span
                              style={{
                                fontSize: 40,
                                marginTop: -22,
                                marginRight: 3,
                              }}
                            >
                              .{" "}
                            </span>
                          }
                          {item?.ARIT_Approved_Status === 1
                            ? "Ordered"
                            : item?.ARIT_Approved_Status === 2
                              ? "Shipped"
                              : item?.ARIT_Approved_Status === 3
                                ? "Rejected"
                                : item?.ARIT_Approved_Status === 4
                                  ? "Partially Shipped"
                                  : item?.ARIT_Approved_Status === 5
                                    ? "On-Hold"
                                    : ""}
                        </Text>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <>
            {redeem_history?.result?.map((item, index) => {
              return (
                <div
                  style={{
                    marginTop: index !== 0 && 20,
                    boxShadow:
                      "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                    borderRadius: 7,
                    paddingLeft: 16,
                    paddingBottom: 16,
                    paddingTop: 16,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      // onClick={handleModal}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: 57,
                        }}
                      >
                        <Image
                          style={{
                            border: "1px solid" + colors.grayNeutral300,
                            borderRadius: 7,
                          }}
                          preview={false}
                          width={57}
                          height={57}
                          src={item?.tbl_ARLR_Rewards?.ARLR_Image_Path}
                          alt="Picture"
                        />
                      </div>
                      <div style={{ marginLeft: 8 }}>
                        <Space direction="vertical" size={0}>
                          <Text
                            style={{
                              color: colors.black,
                              fontWeight: 500,
                            }}
                          >
                            {item?.tbl_ARLR_Rewards?.ARLR_Title}
                          </Text>
                        </Space>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      marginTop: 16,
                      paddingRight: 16,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <p>Quantity</p>
                      <div
                        style={{
                          marginTop: 10,
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        <span style={{ padding: 5 }}>
                          {item?.ARIT_Quantity}
                        </span>
                      </div>
                    </div>
                    <div>
                      <p>Radeem Points</p>
                      <div
                        style={{
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        <Text>{item?.ARIT_Norm_Points}</Text>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 16,
                      paddingRight: 16,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <p>Accumulative Points</p>
                      <div
                        style={{
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        <Text>{item?.ARIT_Accumulative_Points}</Text>
                      </div>
                    </div>
                    <div>
                      <p style={{ textAlign: "center" }}>Order Status</p>
                      <div
                        style={{
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        <Tag
                          style={{
                            border: "none",
                            textAlign: "center",
                            borderRadius: 10,
                            color: colors.grey900,
                            backgroundColor:
                              item?.ARIT_Dispatch_Status === 1
                                ? colors.orange500
                                : item?.ARIT_Dispatch_Status === 2
                                  ? colors?.secondaryBlue
                                  : item?.ARIT_Dispatch_Status === 3
                                    ? colors.green500
                                    : item?.ARIT_Dispatch_Status === 4
                                      ? colors.red300
                                      : "",
                          }}
                        >
                          {item?.ARIT_Dispatch_Status === 1
                            ? "Ordered"
                            : item?.ARIT_Dispatch_Status === 2
                              ? "Partially shipped"
                              : item?.ARIT_Dispatch_Status === 3
                                ? "Shipped"
                                : item?.ARIT_Dispatch_Status === 4
                                  ? "Canceled"
                                  : ""}
                        </Tag>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: 16,
                      paddingRight: 16,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <p>Time & Date</p>
                      <div
                        style={{
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        <Text>
                          {formateDateWithTime(item?.ARIT_Redemption_Date)}
                        </Text>
                      </div>
                    </div>
                    <div>
                      <p>Status</p>
                      <div
                        style={{
                          textAlign: "center",
                          color: colors.black,
                        }}
                      >
                        {item?.ARIT_Approved_Status === 3 ? (
                          <Popover
                            color="#FFF4F4"
                            content={item?.ARIT_Cancel_Reason}
                            title={
                              <>
                                <Image src={alertImage} />
                                <Text
                                  style={{
                                    color: colors.black,
                                    fontWeight: 500,
                                  }}
                                >
                                  {" "}
                                  Reason
                                </Text>
                              </>
                            }
                            trigger="hover"
                            placement="leftTop"
                          >
                            <Text
                              style={{
                                fontWeight: 600,
                                color:
                                  item?.ARIT_Approved_Status === 1
                                    ? colors.orange
                                    : item?.ARIT_Approved_Status === 2
                                      ? colors.green200
                                      : item?.ARIT_Approved_Status === 3
                                        ? colors.red200
                                        : item?.ARIT_Approved_Status === 4
                                          ? colors.black
                                          : item?.ARIT_Approved_Status === 5
                                            ? "blue"
                                            : "",
                              }}
                            >
                              {!isMobile && (
                                <span style={{ fontSize: 40 }}>. </span>
                              )}
                              {item?.ARIT_Approved_Status === 1
                                ? "Ordered"
                                : item?.ARIT_Approved_Status === 2
                                  ? "Shipped"
                                  : item?.ARIT_Approved_Status === 3
                                    ? "Rejected"
                                    : item?.ARIT_Approved_Status === 4
                                      ? "Partially Shipped"
                                      : item?.ARIT_Approved_Status === 5
                                        ? "On-Hold"
                                        : ""}
                            </Text>
                          </Popover>
                        ) : (
                          <Text
                            style={{
                              fontWeight: 600,
                              color:
                                item?.ARIT_Approved_Status === 1
                                  ? colors.orange
                                  : item?.ARIT_Approved_Status === 2
                                    ? colors.green200
                                    : item?.ARIT_Approved_Status === 3
                                      ? colors.red200
                                      : item?.ARIT_Approved_Status === 4
                                        ? colors.black
                                        : item?.ARIT_Approved_Status === 5
                                          ? "blue"
                                          : "",
                            }}
                          >
                            {item?.ARIT_Approved_Status === 1
                              ? "Ordered"
                              : item?.ARIT_Approved_Status === 2
                                ? "Shipped"
                                : item?.ARIT_Approved_Status === 3
                                  ? "Rejected"
                                  : item?.ARIT_Approved_Status === 4
                                    ? "Partially Shipped"
                                    : item?.ARIT_Approved_Status === 5
                                      ? "On-Hold"
                                      : ""}
                          </Text>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </>
    );
  };
  const items = [
    {
      key: "1",
      label: `Profile`,
      children: (
        <>
          {" "}
          <div style={{ marginTop: 25 }}>
            {isMobile ? (
              <div>
                <Col className="gutter-row" span={isMobile ? 24 : 18}>
                  <div>
                    <div>
                      {/* upload image also implemented */}
                      <Image
                        width={60}
                        height={60}
                        style={{ borderRadius: "100%" }}
                        src={uploadedImageUrl ? uploadedImageUrl : profileIcon}
                        preview={false}
                      />
                      {isEditDetails && (
                        <Upload
                          showUploadList={false}
                          style={{ padding: 0, height: "60px !important" }}
                          beforeUpload={(file) => handleFileUpload(file)}
                        >
                          <Button
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                              setIsEditDetails(true);
                            }}
                          >
                            {!isEditDetails ? "Edit Profile" : "Change Image"}
                          </Button>
                        </Upload>
                      )}
                      {!isEditDetails && (
                        <Button
                          style={{ marginLeft: 20 }}
                          onClick={() => {
                            setIsEditDetails(true);
                          }}
                        >
                          {!isEditDetails ? "Edit Profile" : "Change Image"}
                        </Button>
                      )}
                    </div>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        marginTop: 8,
                        color: colors.grey500,
                      }}
                    >
                      {auth?.result?.ACAG_Name}
                    </p>
                  </div>
                  {!isEditDetails ? <Details /> : <EditDetails />}
                </Col>
                <Col
                  style={{ marginTop: isMobile ? 40 : 0 }}
                  className="gutter-row"
                  span={isMobile ? 24 : 6}
                >
                  <div
                    style={{
                      marginTop: isMobile ? 60 : 0,
                      backgroundColor: colors.bgGrey,
                    }}
                  >
                    <div style={{ padding: 10 }}>
                      <Title level={5} style={{ color: colors.black }}>
                        Contact us with questions or concerns
                      </Title>
                      <Divider />
                      <Spin spinning={false}>
                        <div
                          style={{
                            height: 314,
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          {notes?.result?.length > 0 ? (
                            notes?.result.map((item) => (
                              <div
                                style={{ paddingTop: 1, paddingBottom: 1 }}
                                onClick={() => singleNote(item?.AGNO_KeyID)}
                              >
                                <Text style={{ cursor: "pointer" }}>
                                  {item?.AGNO_Notes}
                                </Text>
                                <br />
                                <Text
                                  style={{
                                    float: "right",
                                    fontSize: 10,
                                    fontWeight: 400,
                                    color: colors.black,
                                    marginRight: 4,
                                    marginTop: 4,
                                    marginBottom: 4,
                                  }}
                                >
                                  {formateDateWithTime(
                                    item?.AGNO_zalt_altered_date
                                  )}
                                </Text>
                                <Divider style={{ padding: 0, margin: 0 }} />
                              </div>
                            ))
                          ) : (
                            <Spin
                              spinning={loading}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            />
                          )}
                        </div>
                        {!toglleNote ? (
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => setTogleNote(true)}
                            style={{ width: "100%", marginTop: 20 }}
                            type="primary"
                          >
                            Add Note
                          </Button>
                        ) : (
                          ""
                        )}
                        {toglleNote ? (
                          <Form
                            name="basic"
                            onFinish={notesForm}
                            layout="vertical"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Item
                              label={
                                <Space>
                                  {" "}
                                  <Avatar
                                    icon={<SendOutlined />}
                                    style={{
                                      backgroundColor: colors.green200,
                                      color: colors.black,
                                    }}
                                  />
                                  <span style={{ color: colors.black }}>
                                    Need an update? Let us know here.
                                  </span>
                                </Space>
                              }
                              name="notes"
                              rules={[
                                {
                                  required: false,
                                  message: "Please input your notes!",
                                },
                              ]}
                            >
                              <Input.TextArea
                                rows={4}
                                placeholder="Write your notes here"
                              />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType="submit"
                              >
                                Send Note
                              </Button>
                            </Form.Item>
                          </Form>
                        ) : (
                          ""
                        )}
                      </Spin>
                    </div>
                  </div>
                </Col>
              </div>
            ) : (
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col className="gutter-row" span={18}>
                  <div>
                    <div>
                      {/* upload image also implemented */}
                      <Image
                        width={60}
                        height={60}
                        style={{ borderRadius: "100%" }}
                        src={uploadedImageUrl ? uploadedImageUrl : profileIcon}
                        preview={false}
                      />
                      {isEditDetails && (
                        <Upload
                          showUploadList={false}
                          style={{ padding: 0, height: "60px !important" }}
                          beforeUpload={(file) => handleFileUpload(file)}
                        >
                          <Button
                            style={{ marginLeft: 20 }}
                            onClick={() => {
                              setIsEditDetails(true);
                            }}
                          >
                            {!isEditDetails ? "Edit Profile" : "Change Image"}
                          </Button>
                        </Upload>
                      )}
                      {!isEditDetails && (
                        <Button
                          style={{ marginLeft: 20 }}
                          onClick={() => {
                            setIsEditDetails(true);
                          }}
                        >
                          {!isEditDetails ? "Edit Profile" : "Change Image"}
                        </Button>
                      )}
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      marginTop: 8,
                      color: colors.grey500,
                    }}
                  >
                    {auth?.result?.ACAG_Name}
                  </p>
                  {!isEditDetails ? <Details /> : <EditDetails />}
                </Col>
                <Col className="gutter-row" span={6}>
                  <div
                    style={{
                      backgroundColor: colors.bgGrey,
                    }}
                  >
                    <div style={{ padding: 10 }}>
                      <Title level={5} style={{ color: colors.black }}>
                        Contact us with questions or concerns
                      </Title>
                      <Divider />
                      <Spin spinning={false}>
                        <div
                          style={{
                            height: 314,
                            overflowY: "scroll",
                            overflowX: "hidden",
                          }}
                        >
                          {notes?.result?.length > 0 ? (
                            notes?.result.map((item) => (
                              <div
                                style={{ paddingTop: 1, paddingBottom: 1 }}
                                onClick={() => singleNote(item?.AGNO_KeyID)}
                              >
                                <Text style={{ cursor: "pointer" }}>
                                  {item?.AGNO_Notes}
                                </Text>
                                <br />
                                <Text
                                  style={{
                                    float: "right",
                                    fontSize: 10,
                                    fontWeight: 400,
                                    color: colors.black,
                                    marginRight: 4,
                                    marginTop: 4,
                                    marginBottom: 4,
                                  }}
                                >
                                  {formateDateWithTime(
                                    item?.AGNO_zalt_altered_date
                                  )}
                                </Text>
                                <Divider style={{ padding: 0, margin: 0 }} />
                              </div>
                            ))
                          ) : (
                            <Spin
                              spinning={loading}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                              }}
                            />
                          )}
                        </div>
                        {!toglleNote ? (
                          <Button
                            icon={<PlusOutlined />}
                            onClick={() => setTogleNote(true)}
                            style={{ width: "100%", marginTop: 20 }}
                            type="primary"
                          >
                            Add Note
                          </Button>
                        ) : (
                          ""
                        )}
                        {toglleNote ? (
                          <Form
                            name="basic"
                            onFinish={notesForm}
                            layout="vertical"
                            style={{ marginTop: 10 }}
                          >
                            <Form.Item
                              label={
                                <Space>
                                  {" "}
                                  <Avatar
                                    icon={<SendOutlined />}
                                    style={{
                                      backgroundColor: colors.green200,
                                      color: colors.black,
                                    }}
                                  />
                                  <span style={{ color: colors.black }}>
                                    Need an update? Let us know here.
                                  </span>
                                </Space>
                              }
                              name="notes"
                              rules={[
                                {
                                  required: false,
                                  message: "Please input your notes!",
                                },
                              ]}
                            >
                              <Input.TextArea
                                rows={4}
                                placeholder="Write your notes here"
                              />
                            </Form.Item>

                            <Form.Item>
                              <Button
                                type="primary"
                                style={{ width: "100%" }}
                                htmlType="submit"
                              >
                                Send Note
                              </Button>
                            </Form.Item>
                          </Form>
                        ) : (
                          ""
                        )}
                      </Spin>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </>
      ),
    },
    {
      key: "2",
      label: `Order History`,
      children: <History />,
    },
  ];

  return (
    <AgentLayout>
      <Spin spinning={loadingPage || loader}>
        <div
          style={{
            paddingLeft: isMobile ? 14 : 60,
            paddingRight: isMobile ? 14 : 60,
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Title level={5} style={{ color: colors.black }}>
              Here is your Profile Detail
            </Title>
            <Tag>
              <p
                style={{
                  color: colors.primaryBlue,
                  fontWeight: 600,
                  fontSize: 16,
                }}
              >
                <span style={{ fontSize: 14 }}>Total Points:</span>{" "}
                {JSON.parse(localStorage.getItem("totalPoints"))}
              </p>
            </Tag>
          </div>
          <Tabs style={{ marginTop: 20 }} defaultActiveKey="1" items={items} />
        </div>
      </Spin>

      <AdminNoteModal
        notes={notes?.result}
        id={noteId}
        setNoteModal={setNoteModal}
        noteModalOpen={noteModalOpen}
      />
    </AgentLayout>
  );
};
export default AgentDetail;
