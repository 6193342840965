/* eslint-disable no-unused-vars */
import { useState, useContext, useEffect } from "react";
import AgentLayout from "../../../AdminPortal/Layouts/Layout";
import {
  Row,
  Col,
  Typography,
  Modal,
  Space,
  Select,
  Form,
  Divider,
  Button,
  Tag,
  Input,
  Image,
  Spin,
  Pagination,
  message,
  Carousel,
} from "antd";
import NoImageAvailable from "../../../../assets/reqest.png";
import useSWR from "swr";
import axios from "axios";
import {
  generateSearchParamsGetter,
  getNewSearchParamStringWithArgs,
  getSearchParams,
} from "../../../AdminPortal/utils/helpers";
import { SearchOutlined } from "@ant-design/icons";
import { colors } from "../../../AdminPortal/utils/colors";
import { CartContext } from "../../../Contexts/CartContext";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "react-router-dom";
import ItemCard from "../../components/Card/ItemCard";
import UserContext from "../../../Contexts/UserContext";
import DropDownComp from "../../components/DropDown";
import { isMobile } from "react-device-detect";
import sortBy from "lodash/sortBy";

import { BASE_URL } from "../../../Constants/EnvironmentVariableProvider";
import {
  REWARDS_FILTER_DATA_API,
  GET_REWARD_BYID,
  ADD_FAVORITE_API,
  AGENT_TOTAL_POINTS_API,
  ALL_CATEGORIES_API,
} from "../../../API/paths";
import { DATAGRID_DEFAULT_PAGE_SIZE } from "../../../Constants/ApplicationConstants";

const { Text, Title, Link } = Typography;
const { Option } = Select;

const Dashboard = () => {
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const { auth } = useContext(UserContext);
  const [singleReward, setSingleReward] = useState(null);
  let [spObj, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: DATAGRID_DEFAULT_PAGE_SIZE.toString(),
  });
  spObj.get('page') === null && spObj.set('page', '1');
  spObj.get('pageSize') === null && spObj.set('pageSize', DATAGRID_DEFAULT_PAGE_SIZE.toString());

  const searchParams = new URLSearchParams(window.location.search);
  const category_keyids_list = searchParams.get("category_keyids");

  let [id, page, pageSize, search, statusType, category_keyids] =
    getSearchParams(spObj);

  const [modalOpen, setModalopen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  console.log('category_keyids_list', category_keyids_list)
  const [selectedCategory, setSelectedCategory] = useState(
    (category_keyids_list?.length ?? 0) > 0
      ? category_keyids_list.split(",").map((item) => parseInt(item))
      : []
  );
  useEffect(() => {
    (category_keyids_list?.length ?? 0) === 0 && setSelectedCategory([])
  }, [category_keyids_list])

  const [value, setValue] = useState("All");
  const [sliderValue, setSliderValue] = useState(null);
  const [filterVal, setFilterVal] = useState(1);

  const [variant_form] = Form.useForm();

  const getNewSearchParamString = generateSearchParamsGetter(spObj);

  const debounceFetcher = useDebouncedCallback((search) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({ page: 1, pageSize, search, category_keyids: category_keyids_list })
    );
  }, 600);
  const rewardApiUrl =
    BASE_URL +
    REWARDS_FILTER_DATA_API +
    getNewSearchParamString() +
    `&orderBy=${filterVal}` +
    `&pointRange=${value === "All" ? "" : value === "Custom Range" ? sliderValue : value
    }` +
    `&statusType=1,4,5&isFavoriteRequired=true`;
  const categoryApiUrl = BASE_URL + ALL_CATEGORIES_API;
  const {
    data: rewards,
    isValidating: loading,
    mutate: mutateReward,
  } = useSWR({
    url: rewardApiUrl,
  });
  const agentId = auth?.result?.ACAG_KeyID;
  const { data: agnetPoints, isValidating: pointsLoading } = useSWR({
    url: `${AGENT_TOTAL_POINTS_API}?KeyId=${agentId}`,
  });

  useEffect(() => {
    localStorage.setItem("totalPoints", JSON.stringify(agnetPoints));
  }, [agnetPoints]);

  const { data: category, isValidating: categoryloading } = useSWR({
    url: categoryApiUrl,
  });
  const onPaginationChange = (page, pageSize) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page,
        pageSize,
        search,
        statusType,
        category_keyids: category_keyids_list,
      })
    );
  };
  const handleFilterChange = (e) => {
    setFilterVal(e);
  };
  const handleSearch = (e) => {
    debounceFetcher(e.target.value);
    setSearchState(e.target.value);
  };
  const ModalContoller = async (
    rewardId,
    quantity,
    Agent_Redeemed_Quantity,
    ARLR_Redeemable_Quantity
  ) => {
    if (quantity < 1) {
      message.error("The reward item is no longer available to redeem");
      return;
    }

    if (
      ARLR_Redeemable_Quantity &&
      Agent_Redeemed_Quantity >= ARLR_Redeemable_Quantity
    ) {
      message.error("You have already redeemed the available quantity.");
      return;
    }
    try {
      setModalopen(true);
      setModalLoading(true);
      const response = await axios.get(`${GET_REWARD_BYID}?Id=${rewardId}`);
      const result = response?.data?.result;
      result?.tbl_ARRV_Reward_Variants?.forEach((item, index) => {
        if (index === 0) {
          item["status"] = true;
        } else {
          item["status"] = false;
        }
      });
      variant_form.setFieldsValue({
        variant_id:
          result?.tbl_ARRV_Reward_Variants[0]?.ARRV_Reward_Variant_KeyID,
      });
      setModalLoading(false);
      setSingleReward(result ? result : null);
    } catch (e) {
      setModalLoading(false);
    }
  };
  const addFav = async (reward_id, fav_status) => {
    const fav_data = {
      ARFA_Agent_ACAG_KeyID: auth?.result?.ACAG_KeyID,
      ARFA_Reward_ARLR_KeyID: reward_id,
      ARFA_zalt_Altered_Type: !fav_status,
    };

    mutateReward(async () => {
      try {
        const response = await axios.post(ADD_FAVORITE_API, fav_data);
        if (response?.data?.success) {
          message.success(
            !fav_status
              ? "Reward has been added to favorites"
              : "Remove from favorites"
          );
        } else {
          message.error(response?.data?.message);
        }
      } catch (e) {
        console.log(e);
      }
    });
  };
  const handleVariantChange = (values) => {
    if (!values) {
      return;
    }
    let dataCopy = { ...singleReward };
    const index = dataCopy?.tbl_ARRV_Reward_Variants?.findIndex(
      (item) => item?.ARRV_Reward_Variant_KeyID === values
    );
    dataCopy?.tbl_ARRV_Reward_Variants?.forEach((item, item_index) => {
      if (item_index === index) {
        item.status = true;
      } else {
        item.status = false;
      }
    });
    setSingleReward(dataCopy);
  };

  const categoryHandleMultiple = (multiple_cat_ids) => {
    if (multiple_cat_ids.length === 0) {
      setSelectedCategory([]);
      setSearchParams(
        getNewSearchParamStringWithArgs({
          page: 1,
          pageSize,
          search,
          statusType,
          category_keyids: "",
        })
      );
      return;
    } else {
      let category_keyids = "";

      multiple_cat_ids?.forEach((item, index) => {
        category_keyids +=
          item + (index !== multiple_cat_ids?.length - 1 ? "%2C" : "");
      });

      setSelectedCategory(multiple_cat_ids);
      setSearchParams(
        getNewSearchParamStringWithArgs({
          page: 1,
          pageSize,
          search,
          statusType,
          category_keyids,
        })
      );
    }
  };

  useEffect(() => {
    if (mutateReward) mutateReward();
  }, [search, pageSize, page, mutateReward, category_keyids, value, filterVal]);

  const getCategoryOption = (category) => {
    let categoryOption = [];
    if (category !== null && category !== undefined && category.length > 0) {
      sortBy(category, ["Reward_Quantity"])
        .reverse()
        .forEach((item) => {
          categoryOption.push({
            value: item?.ARLC_KeyID,
            label: item?.ARLC_Title,
            quantity: item?.Reward_Quantity,
          });
        });
    }
    return categoryOption;
  };

  const categoryOption = getCategoryOption(category?.result);

  let imageSrc = singleReward?.ARLR_Images_Path ? [...singleReward.ARLR_Images_Path] : [];
  if (singleReward?.ARLR_Image_Path) {
    imageSrc.unshift(singleReward?.ARLR_Image_Path);
  }
  imageSrc = (imageSrc?.length ?? 0) === 0 ? [NoImageAvailable] : imageSrc;

  const handleCancelClick = () => {
    imageSrc = [NoImageAvailable];
    setModalopen(false);
  };

  const [searchState, setSearchState] = useState(search ? search : "");
  useEffect(() => {
    setSearchState(search)
  }, [search])

  return (
    <Spin
      spinning={loading || categoryloading}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <AgentLayout>
        <div
          style={{
            paddingLeft: isMobile ? 14 : 60,
            paddingRight: isMobile ? 14 : 60,
            marginTop: 28,
            marginBottom: 20,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={5} style={{ color: colors.black }}>
                It's time to go shopping! Use your points to get some great
                merchandise!
              </Title>
              <Tag>
                <p
                  style={{
                    color: colors.primaryBlue,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <span style={{ fontSize: 14 }}>Total Points:</span>{" "}
                  {agnetPoints}
                </p>
              </Tag>
            </div>
            <Link
              style={{ fontWeight: 600 }}
              onClick={() => {
                navigate("/agent-dashboard/points-detail");
              }}
            >
              See how points work
            </Link>
            <Divider />
          </div>
          <Row style={{ marginTop: 20, marginBottom: 28 }}>
            <Col span={16}>
              <Row gutter={{ xs: 8, sm: 14, md: 14, lg: 14 }}>
                <Col
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={8}
                  xxl={4}
                  style={{
                    display: "flex",
                    alignItems: "end",
                    marginBottom: isMobile ? 20 : 0,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      onChange={handleSearch}
                      size="large"
                      placeholder="Search Rewards..."
                      defaultValue={search}
                      value={searchState}
                      allowClear
                      prefix={
                        <SearchOutlined
                          style={{
                            color: "rgba(0,0,0,.45)",
                          }}
                        />
                      }
                    />
                  </div>
                </Col>
                {!isMobile ? (
                  <Col>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      <Col span={12}>
                        <Select
                          size="large"
                          defaultValue="Default"
                          style={{
                            width: "100%",
                          }}
                          onChange={handleFilterChange}
                          options={[
                            {
                              value: "1",
                              label: "Default",
                            },
                            {
                              value: "2",
                              label: "Popular",
                            },
                            {
                              value: "3",
                              label: "Featured",
                            },
                            {
                              value: "4",
                              label: "Newest",
                            },
                            {
                              value: "5",
                              label: "Oldest",
                            },
                            {
                              value: "6",
                              label: "Points Ascending",
                            },
                            {
                              value: "7",
                              label: "Points Descending",
                            },
                          ]}
                        />
                      </Col>
                      <Col className="gutter-row" span={12}>
                        <DropDownComp
                          setValue={setValue}
                          setSliderValue={setSliderValue}
                          value={value}
                        />
                      </Col>
                    </div>
                  </Col>
                ) : (
                  <>
                    <Col span={24}>
                      <Select
                        size="large"
                        defaultValue="Default"
                        style={{
                          width: "100%",
                          marginBottom: 20,
                        }}
                        onChange={handleFilterChange}
                        options={[
                          {
                            value: "1",
                            label: "Default",
                          },
                          {
                            value: "2",
                            label: "Popular",
                          },
                          {
                            value: "3",
                            label: "Featured",
                          },
                          {
                            value: "4",
                            label: "Newest",
                          },
                          {
                            value: "5",
                            label: "Oldest",
                          },
                          {
                            value: "6",
                            label: "Points Ascending",
                          },
                          {
                            value: "7",
                            label: "Points Descending",
                          },
                        ]}
                      />
                    </Col>
                    <Col className="gutter-row" span={24}>
                      <DropDownComp
                        setValue={setValue}
                        setSliderValue={setSliderValue}
                        value={value}
                      />
                    </Col>
                  </>
                )}
                <Col
                  style={{ display: "flex", alignItems: "end" }}
                  className="gutter-row"
                  span={8}
                ></Col>
              </Row>
            </Col>
            <Col span={8}>
              <Select
                placeholder="All Category"
                allowClear
                value={selectedCategory}
                mode="multiple"
                size="large"
                style={{ width: "100%" }}
                onChange={(value) => categoryHandleMultiple(value)}
                showSearch={false}
              >
                {categoryOption.map((item) => (
                  <Option key={item.value} value={item.value}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                      }}
                    >
                      <span>{item.label}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {rewards?.result?.map((item, index) => {
              return (
                <Col
                  key={"agent-dashboard-rewards" + index}
                  className="gutter-row"
                  xs={24}
                  sm={12}
                  md={8}
                  lg={6}
                  xl={6}
                  xxl={4}
                >
                  <ItemCard
                    rewardId={item?.ARLR_KeyID}
                    addFav={addFav}
                    quantity={item?.ARLR_Quantity}
                    category_title={item?.tbl_ARLC_Categories?.ARLC_Title}
                    favorite={item?.IsFavorite}
                    productName={item?.ARLR_Title}
                    src={item?.ARLR_Image_Path}
                    loading={loading}
                    index={index}
                    norm_points={item?.ARLR_Norm_Points}
                    ModalContoller={ModalContoller}
                    Agent_Redeemed_Quantity={item?.Agent_Redeemed_Quantity}
                    ARLR_Redeemable_Quantity={item?.ARLR_Redeemable_Quantity}
                  />
                </Col>
              );
            })}
          </Row>

          {rewards?.result?.length > 0 ? (
            <Pagination
              style={{
                float: "right",
                marginBottom: 20,
                position: "relative",
                marginTop: "16px",
              }}
              showSizeChanger
              showTotal={(total, range) => `Showing ${range[0]} to ${range[1]} of ${total} results`}
              onChange={onPaginationChange}
              current={Number(page)}
              total={rewards?.totalRecords}
              className="pagination-dropdown-custom pagination-component"
              pageSize={Number(pageSize)}
            />
          ) : (
            ""
          )}
        </div>

        <Modal
          open={modalOpen}
          footer={null}
          width="80vw"
          onCancel={handleCancelClick}
        >
          <Spin spinning={modalLoading}>
            <div style={{ backgroundColor: colors.bgGrey }}>
              <Row align="middle">
                <Col
                  span={isMobile ? 24 : 12}
                  style={{ backgroundColor: colors.white }}
                >
                  {modalLoading ? null :
                    <Carousel style={{
                      height: isMobile ? 350 : 580,
                      width: "100%",
                      backgroundColor: "rgb(246, 248, 251)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: 'center'
                    }}>
                      {imageSrc.map((item) => (
                        <div key={item}>
                          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgb(246, 248, 251)" }}>
                            <img style={{ height: 350, width: 280, objectFit: "contain" }} src={item} alt="link" />
                          </div>
                        </div>
                      ))
                      }
                    </Carousel>}
                </Col>
                <Col span={isMobile ? 24 : 12}>
                  <div style={{ padding: "0 24px" }}>
                    <Space
                      direction="vertical"
                      style={{ width: "100%", paddingTop: "60px" }}
                    >
                      <Text
                        style={{
                          color: colors.black,
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                      >
                        {singleReward?.ARLR_Title}
                      </Text>
                      <Text>
                        <pre
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            fontFamily: "inherit",
                            maxHeight: "300px",
                            overflowY: "auto",
                          }}
                        >
                          {singleReward?.ARLR_Description}
                        </pre>
                      </Text>
                      <Divider style={{ marginTop: 5 }} />
                      <Form
                        form={variant_form}
                        layout="vertical"
                        style={{ marginTop: -10 }}
                      >
                        {singleReward?.tbl_ARRV_Reward_Variants?.length < 1 ||
                          !singleReward?.tbl_ARRV_Reward_Variants ? (
                          ""
                        ) : (
                          <Form.Item
                            label="Select Variant"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            name="variant_id"
                          >
                            <Select
                              style={{ width: "100%" }}
                              onChange={handleVariantChange}
                              disabled={
                                singleReward?.tbl_ARRV_Reward_Variants?.length <
                                1 || !singleReward?.tbl_ARRV_Reward_Variants
                              }
                            >
                              {singleReward?.tbl_ARRV_Reward_Variants?.map(
                                (item) => (
                                  <Option
                                    key={"agent-dashboard-options" + item}
                                    value={item?.ARRV_Reward_Variant_KeyID}
                                  >
                                    {item?.ARRV_Title}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        )}
                      </Form>
                      {!(
                        singleReward?.tbl_ARRV_Reward_Variants?.length < 1 ||
                        !singleReward?.tbl_ARRV_Reward_Variants
                      ) && <Divider style={{ marginTop: -10 }} />}
                      <Text
                        style={{
                          float: "right",
                          fontWeight: 600,
                          fontSize: 16,
                        }}
                      >
                        Total Reward Points
                      </Text>
                      <Text
                        style={{
                          float: "right",
                          fontWeight: 600,
                          fontSize: 16,
                          color: colors.black,
                        }}
                      >
                        {singleReward?.ARLR_Norm_Points}
                      </Text>
                      <Button
                        onClick={() => addToCart(singleReward, setModalopen)}
                        type="primary"
                        disabled={singleReward?.ARLR_Quantity < 1}
                        style={{ width: "100%", marginTop: 10 }}
                      >
                        Add to cart
                      </Button>
                      <Button
                        type="text"
                        onClick={handleCancelClick}
                        style={{
                          width: "100%",
                          color: colors.black,
                          background: colors.white,
                          marginBottom: 10,
                        }}
                      >
                        Cancel
                      </Button>
                    </Space>
                  </div>
                </Col>
              </Row>
            </div>
          </Spin>
        </Modal>
      </AgentLayout>
    </Spin>
  );
};
export default Dashboard;
