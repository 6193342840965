import React, { useEffect } from "react";
import { ConfigProvider, message } from "antd";
import { SWRConfig } from "swr";
import { CartContext } from "./App/Contexts/CartContext";
import usePersistentState from "./App/AdminPortal/hooks/usePersistentState";
import UserContext from "./App/Contexts/UserContext";
import { fetcher } from "./App/AdminPortal/utils/fetcher";
import { theme } from "./App/AdminPortal/utils/theme";
import initAxiosGlobalConfigs, { setAxiosInterceptors } from "./App/API/axios.Config";
import { PublicClientApplication } from "@azure/msal-browser";
import { getMsalConfig } from "./authConfig";
import "./App.css";
import laggy from "./App/middlewares/laggy";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { getRoleFromAuth, getUserType } from './utils/commonUtils';
import { RoutesLookup } from './App/Routes/RoutesLookup';
import { DEFAULT_USER_TYPE, USER_TYPES } from './App/Constants/ApplicationConstants';
import Router from './App/Routes/Router';

const MsalWrapperHOC = (props) => {
  const { configType, children } = props;
  const msalInstance = new PublicClientApplication(getMsalConfig(configType));
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

const AppContainer = () => {
  const { instance } = useMsal();
  const [auth, setAuth] = usePersistentState("auth", {
    initialValue: {},
    isSession: false
  });
  const [cart, setCart] = usePersistentState("cart", {
    initialValue: [],
    isSession: false
  });

  const userType = getUserType(getRoleFromAuth(auth))

  const getConfigType = () => {
    if (window.location.pathname === RoutesLookup.ADMIN_LOGIN || userType === USER_TYPES.ADMIN) {
      return USER_TYPES.ADMIN
    } else if (window.location.pathname === RoutesLookup.AGENT_LOGIN || userType === USER_TYPES.AGENT) {
      return USER_TYPES.AGENT
    }
    return DEFAULT_USER_TYPE
  }
  let configType = getConfigType()

  initAxiosGlobalConfigs(auth);
  setAxiosInterceptors(instance);

  useEffect(() => {
    if (!auth?.token) {
      localStorage.setItem("auth", JSON.stringify({ data: "{}" }));
    }
  }, [auth]);

  function addToCart(item, setModalopen) {
    if (item === -1) {
      setCart([]);
      return;
    }
    if (
      item?.ARLR_Redeemable_Quantity &&
      item?.Agent_Redeemed_Quantity >= item?.ARLR_Redeemable_Quantity
    ) {
      setModalopen(false);
      message.error("Maximum order quantity exceeded.");
      return false;
    }
    let data = [...cart];
    let flag = false;
    let Quantity = 0;
    let filterData = data?.filter(
      (singleReward) => singleReward?.ARLR_KeyID === item?.ARLR_KeyID
    );
    filterData?.forEach((item) => {
      Quantity = item?.qty + Quantity;
    });
    let itemVariant = item?.tbl_ARRV_Reward_Variants?.filter(
      (singleVr) => singleVr?.status
    );
    if (filterData?.length > 0) {
      if (
        (filterData[0].qty + 1 > filterData[0]?.ARLR_Redeemable_Quantity &&
          filterData[0]?.ARLR_Redeemable_Quantity) ||
        filterData[0].qty + 1 > filterData[0]?.ARLR_Quantity ||
        Quantity + 1 > filterData[0]?.ARLR_Quantity ||
        (Quantity + 1 > filterData[0]?.ARLR_Redeemable_Quantity &&
          filterData[0]?.ARLR_Redeemable_Quantity)
      ) {
        setModalopen(false);
        message.error("Maximum order quantity exceeded.");
        return false;
      }
      if (
        filterData[0]?.tbl_ARRV_Reward_Variants?.length < 1 ||
        !filterData[0]?.tbl_ARRV_Reward_Variants
      ) {
        if (
          (filterData[0].qty + 1 > filterData[0]?.ARLR_Redeemable_Quantity &&
            filterData[0]?.ARLR_Redeemable_Quantity) ||
          filterData[0].qty + 1 > filterData[0]?.ARLR_Quantity
        ) {
          setModalopen(false);
          message.error("Maximum order quantity exceeded.");
          return false;
        }
        filterData[0].qty++;
        setCart(data);
        setModalopen(false);
        message.success("The reward has been added to your cart");
        return true;
      }
      for (let j = 0; j < filterData.length; j++) {
        if (
          (filterData[j].qty + 1 > filterData[j]?.ARLR_Redeemable_Quantity &&
            filterData[j]?.ARLR_Redeemable_Quantity) ||
          filterData[j].qty + 1 > filterData[j]?.ARLR_Quantity
        ) {
          setModalopen(false);
          message.error("Maximum order quantity exceeded.");
          return false;
        }
      }
      for (let i = 0; i < filterData.length; i++) {
        // eslint-disable-next-line no-loop-func
        filterData[i]?.tbl_ARRV_Reward_Variants?.forEach((singleVariant) => {
          if (
            singleVariant?.ARRV_Reward_Variant_KeyID ===
            itemVariant[0]?.ARRV_Reward_Variant_KeyID &&
            singleVariant?.status === true
          ) {
            if (
              (filterData[i].qty + 1 >
                filterData[i]?.ARLR_Redeemable_Quantity &&
                filterData[i]?.ARLR_Redeemable_Quantity) ||
              filterData[i].qty + 1 > filterData[i]?.ARLR_Quantity
            ) {
              setModalopen(false);
              message.error("Maximum order quantity exceeded.");
              flag = true;
              return false;
            }
            filterData[i].qty++;
            flag = true;
            setCart(data);
            setModalopen(false);
            message.success("The reward has been added to your cart.");
            return true;
          }
        });
      }
    } else {
      item["qty"] = 1;
      data.push(item);
      setCart(data);
      setModalopen(false);
      message.success("The reward has been added to your cart.");
      return true;
    }
    if (
      (!flag && filterData[0]?.tbl_ARRV_Reward_Variants?.length < 1) ||
      !flag
    ) {
      item["qty"] = 1;
      data.push(item);
      setCart(data);
      setModalopen(false);
      message.success("The reward has been added to your cart.");
      return true;
    }
  }
  function deleteItemCart(index) {
    let dataCopy = [...cart];
    dataCopy.splice(index, 1);
    setCart(dataCopy);
  }

  const ConditionalCartProvider = (props) => {
    const { children } = props
    return userType === USER_TYPES.ADMIN ? (
      <>{children}</>
    ) : (
      <CartContext.Provider value={{ cart, addToCart, deleteItemCart }}>
        {children}
      </CartContext.Provider>
    );
  };

  return (
    <SWRConfig
      value={{
        fetcher: fetcher,
        onError: (error, key) => {
          if (error) {
            // TODO:  Add Proper error handling in this middleware
            message.error("Internal server Error!", error);
          }
        },
        use: [laggy],
      }}
    >
      <UserContext.Provider value={{ auth, setAuth, instance }}>
        <ConfigProvider theme={theme}>
          <MsalWrapperHOC configType={configType}>
            <ConditionalCartProvider>
              <Router />
            </ConditionalCartProvider>
          </MsalWrapperHOC>
        </ConfigProvider>
      </UserContext.Provider>
    </SWRConfig>
  );
};

export default AppContainer;
