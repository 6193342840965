import React, { useContext, useEffect } from 'react'
import getDefaultLoginRoutesForRole from './DefaultLoginRoutesForRole'
import UserContext from '../Contexts/UserContext';
import { Navigate, useNavigate } from 'react-router';
import { RoutesLookup } from './RoutesLookup';
import { getRoleFromAuth } from '../../utils/commonUtils';

function BasePageResolver() {
    const { auth } = useContext(UserContext);
    const navigate = useNavigate()

    const role = getRoleFromAuth(auth)
    const defaultRoute = getDefaultLoginRoutesForRole(role)

    const isLoginRoute = window.location.pathname === RoutesLookup.ADMIN_LOGIN || window.location.pathname === RoutesLookup.AGENT_LOGIN

    useEffect(() => {
        if (isLoginRoute) {
            return
        }
        navigate(defaultRoute)
    }, [defaultRoute, isLoginRoute, navigate])

    return <Navigate to={defaultRoute} replace />
}

export default BasePageResolver