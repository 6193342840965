import React, { useEffect, useState } from 'react'
import uploadIcon from "../../../../../assets/icons/uploadIcon.svg";
import Upload from 'antd/es/upload';
import Typography from 'antd/es/typography';
import Image from 'antd/es/image';
import { colors } from "../../../utils/colors";
import message from 'antd/es/message';
import { UPLOAD_IMAGE_API } from '../../../../API/paths';
import { BASE_URL } from '../../../../Constants/EnvironmentVariableProvider';
import axios from 'axios';
import Modal from 'antd/es/modal';
import StarOutlined from '@ant-design/icons/lib/icons/StarOutlined';
import StarFilled from '@ant-design/icons/lib/icons/StarFilled';
const { Dragger } = Upload;

function RewardImages({ form, featuredImagePath, imagesPath }) {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [featuredImage, setFeaturedImage] = useState();

    useEffect(() => {
        setFeaturedImage(() => featuredImagePath)
    }, [featuredImagePath])

    useEffect(() => {
        const newFileList = imagesPath?.map(
            (url) => ({
                url,
                status: 'done'
            })
        )
        setFileList(() => newFileList)
    }, [imagesPath])

    const handleCancel = () => setPreviewOpen(false);
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const handleRemove = (file) => {
        setFileList((prevFileList) => {
            const newFileList = [...(prevFileList ?? [])]
            const removeFileIndex = newFileList.findIndex(f => f.url === file.url)
            newFileList.splice(removeFileIndex, 1);
            form.setFieldValue("reward_images", newFileList.map(f => f.url))
            return newFileList;
        })
    }

    const handleFileUpload = (file) => {
        const fileExtension = file.name.split(".")[1];
        const isCorrectType =
            file.type === "image/jpeg" ||
            fileExtension === "jpeg" ||
            file.type === "image/png" ||
            fileExtension === "png" ||
            file.type === "image/jpg" ||
            fileExtension === "jpg";
        if (!isCorrectType) {
            message.error("You can only upload jpg/jpeg/png images!");
            return;
        }
        const isCorrectSize = file.size / 1024 / 1024 <= 10;
        if (!isCorrectSize) {
            message.error("File must be smaller than 10MB!");
        }
        if (isCorrectType && isCorrectSize) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                sendRewardImage(reader.result.split(",")[1], file?.name, file?.uid);
            };
            return false;
        }
    };
    const setFileProperties = (uid, { status, url }) => {
        setFileList((prevFileList) => {
            const newFileList = [...(prevFileList ?? [])]
            const uidFileIndex = newFileList.findIndex(file => file.uid === uid)
            if (status) {
                newFileList[uidFileIndex]["status"] = status
            }
            if (url) {
                newFileList[uidFileIndex]["url"] = url
            }
            return newFileList
        })
    }
    const sendRewardImage = async (base64Image, fileName, uid) => {
        const value = {
            Image_String: base64Image,
            Image_Name: fileName,
        };
        const fileInstance = {
            uid,
            status: 'uploading'
        }
        setFileList((prevFileList) => {
            const newFileList = [...(prevFileList ?? []), fileInstance]
            return newFileList
        })
        axios.post(
            `${BASE_URL}${UPLOAD_IMAGE_API}`,
            value
        ).then((response) => {
            setFileProperties(uid, { status: 'done', url: response?.data });
            message.success("Image upload successfully");
            const rewards_images = form.getFieldValue("reward_images") ?? []
            form.setFieldValue("reward_images", [...rewards_images, response?.data])
        }).catch((e) => {
            console.log(e);
            setFileProperties(uid, { status: 'error', url: null });
        })
    };

    const getIsFeaturedImage = (file) => {
        const isNonExistentFeaturedImage = featuredImage && (fileList?.findIndex(f => f.url === featuredImage) ?? -1) === -1
        const isAtZeroIndex = (fileList?.findIndex(f => f.url === file.url) ?? 0) === 0
        const isFeaturedImage = (featuredImage && featuredImage === file.url) ||
            (!featuredImage && isAtZeroIndex) ||
            (featuredImage && isAtZeroIndex && isNonExistentFeaturedImage)
        if (isFeaturedImage) {
            setFeaturedImage(() => file.url)
            form.setFieldValue("reward_image", file.url)
        }
        return isFeaturedImage
    }

    return (
        <>
            <Dragger
                multiple
                fileList={fileList}
                showUploadList={true}
                listType='picture-card'
                onRemove={handleRemove}
                onPreview={handlePreview}
                itemRender={(originNode, file, fileList, actions) => {
                    return (
                        <div style={{ position: "relative", height: "100%" }}>
                            <div style={{ height: "100%" }}>
                                {originNode}
                            </div>
                            <div style={{ position: "absolute", top: 10, zIndex: 10, left: 6, cursor: "pointer" }}>
                                {(
                                    getIsFeaturedImage(file)
                                ) ?
                                    file.status === "done" &&
                                    <span onClick={() => {
                                        setFeaturedImage(() => null)
                                        form.setFieldValue("reward_image", null)
                                    }}>
                                        <StarFilled style={{ color: 'gold', fontSize: '18px' }} />
                                    </span>
                                    :
                                    file.status === "done" &&
                                    <span
                                        onClick={() => {
                                            setFeaturedImage(() => file.url)
                                            form.setFieldValue("reward_image", file.url)
                                        }}
                                    >
                                        <StarOutlined style={{ fontSize: '18px' }} />
                                    </span>
                                }
                            </div>
                        </div>
                    );
                }}
                style={{
                    border: "none",
                    backgroundColor: colors.white,
                }}
                beforeUpload={handleFileUpload}
            >
                <div>
                    <Image
                        src={uploadIcon}
                        alt="upload"
                        preview={false}
                        style={{ objectFit: "cover" }}
                        width={36}
                        height={36}
                    />
                    <div style={{ marginTop: 10 }}>
                        <Typography.Text className="ant-upload-text" style={{
                            marginRight: 5,
                            color: colors.secondaryBlue,
                            fontSize: 16
                        }}>
                            Upload a file
                        </Typography.Text>
                        <Typography.Text className="ant-upload-text" style={{
                            fontSize: 16
                        }}>
                            or drag and drop
                        </Typography.Text>
                    </div>
                    <Typography.Text className="ant-upload-hint">
                        PNG, JPG, up to 10MB
                    </Typography.Text>
                </div>
            </Dragger >
            <Modal open={previewOpen} title='Image Preview' footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    )
}

export default RewardImages