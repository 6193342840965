export const USER_ROLE = {
  SUPER_ADMIN: "SuperAdmin",
  ADMIN: "Admin",
  AGENT: "Agent",
};
export const USER_TYPES = {
  ADMIN: "admin",
  AGENT: "agent",
};
export const DEFAULT_USER_TYPE = USER_TYPES.AGENT;
export const DATAGRID_DEFAULT_PAGE_SIZE = 100;

export const QUOTING_PORTAL_LINK = "https://quoting.normandyins.com/PUMAA/#/";
