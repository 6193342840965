import { useState, useContext, useRef } from "react";
import AgentLayout from "../../../AdminPortal/Layouts/Layout";
import {
  Row,
  Col,
  Typography,
  Space,
  Form,
  Divider,
  Button,
  Input,
  Tag,
  Image,
  Modal,
  Carousel,
  Select,
  message,
  Spin,
  Popconfirm,
  // Tooltip,
} from "antd";
import useSWR from "swr";
import axios from "axios";
import { DeleteOutlined } from "@ant-design/icons";
import { colors } from "../../../AdminPortal/utils/colors";
import { CartContext } from "../../../Contexts/CartContext";
import { useNavigate } from "react-router";
import { city } from "./City";
import { state } from "./State";
import rightArrow from "../../../../assets/icons/rightArrow.svg";
import downArrow from "../../../../assets/icons/downArrow.svg";
import UserContext from "../../../Contexts/UserContext";
import { isMobile } from "react-device-detect";
import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import { RoutesLookup } from '../../../Routes/RoutesLookup';
import {
  AGENT_REWARD_POST,
  GET_AGENT_BY_ID_API
} from '../../../API/paths';

const { Text, Title } = Typography;

const contentStyle = {
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  border: "1px solid #E5E7EB",
};

const AgentCart = () => {
  const carouselRef = useRef();

  const { cart, addToCart, deleteItemCart } = useContext(CartContext);
  const { auth } = useContext(UserContext);
  const { data: agent, isValidating: addressloading } = useSWR({
    url: BASE_URL + GET_AGENT_BY_ID_API + "?KeyId=" + auth?.result?.ACAG_KeyID,
  });
  const navigate = useNavigate();
  // const [modalOpen, setModalopen] = useState(false);
  const [showDetailedProduct, setShowDetailedProduct] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [email_form] = Form.useForm();
  const [address_form] = Form.useForm();
  const [data, setData] = useState(cart);
  const [changeIconEmail, setChangeIconEmail] = useState(true);
  const [changeIconAddress, setChangeIconAddress] = useState(true);
  email_form.setFieldsValue({
    email: agent?.result?.ACAG_Email,
  });
  address_form.setFieldsValue({
    street_address: agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line1,
    street_address2: agent?.result?.tbl_MSAD_Addresses?.MSAD_Address_Line2,
    city: agent?.result?.tbl_MSAD_Addresses?.MSAD_City,
    state: agent?.result?.tbl_MSAD_Addresses?.MSAD_State,
    zip: agent?.result?.tbl_MSAD_Addresses?.MSAD_ZipCode,
  });
  const detailBtnEmailHandler = () => {
    setChangeIconEmail(!changeIconEmail);
  };
  const detailBtnAddrressHandler = () => {
    setChangeIconAddress(!changeIconAddress);
  };

  const totalRedeemPoints = () => {
    let total = 0;
    data?.forEach((item) => {
      total = total + item.ARLR_Norm_Points * item.qty;
    });
    return total;
  };
  const deleteItem = (index) => {
    deleteItemCart(index);
    let dataCopy = [...data];
    dataCopy.splice(index, 1);
    setData(dataCopy);
  };
  const handleQuantity = (
    index,
    type,
    reward_quantity,
    Redeemable_Quantity,
    itemId
  ) => {
    let dataCopy = [...data];
    let Quantity = 0;
    let filterData = data?.filter(
      (singleReward) => singleReward?.ARLR_KeyID === itemId
    );
    if (filterData?.length > 1) {
      filterData?.forEach((item) => {
        Quantity = item?.qty + Quantity;
      });
      if (
        (Quantity + 1 > Redeemable_Quantity && Redeemable_Quantity) ||
        Quantity + 1 > reward_quantity
      ) {
        message.error("Maximum order quantity exceeded.");
        return;
      }
    }

    type === "+"
      ? reward_quantity <= dataCopy[index].qty ||
        (Redeemable_Quantity <= dataCopy[index].qty && Redeemable_Quantity)
        ? message.error("Maximum order quantity exceeded.")
        : dataCopy[index].qty++
      : dataCopy[index].qty > 1
        ? dataCopy[index].qty-- // eslint-disable-next-line no-self-assign
        : (dataCopy[index].qty = dataCopy[index].qty);
    setData(dataCopy);
  };
  const handleModal = (index) => {
    setShowDetailedProduct(index);
  };
  async function handleFormSubmit() {
    if (cart?.length < 1) {
      message.warning("Your Cart is Empty.");
      return;
    }
    if (JSON.parse(localStorage.getItem("totalPoints")) < totalRedeemPoints()) {
      message.warning("You have insufficient points for this transaction");
      return;
    }
    const activeVariant = (variant_array) => {
      const selected_variant = variant_array?.filter((item) => item?.status);
      if (!selected_variant) {
        return 0;
      }
      return selected_variant[0]?.ARRV_Reward_Variant_KeyID;
    };
    const formData1 = email_form.getFieldsValue();
    const formData2 = address_form.getFieldsValue();
    if (!formData1?.email) {
      message.warning("Please Enter Email Address");
      return;
    }

    if (
      !formData2?.city ||
      !formData2?.state ||
      !formData2?.street_address ||
      //!formData2?.street_address2 ||
      !formData2?.zip
    ) {
      message.warning("Please Enter Shipping Address");
      return;
    }
    const combinedFormData = { ...formData1, ...formData2 };
    let reward_data = [];
    cart?.forEach((item) => {
      const data = {
        ARIT_Reward_ARLR_keyId: item?.ARLR_KeyID,
        ARIT_Reward_Variant_ARRV_KeyID: activeVariant(
          item?.tbl_ARRV_Reward_Variants
        ),
        ARIT_Norm_Points: item?.ARLR_Norm_Points,
        ARIT_Accumulative_Points: item?.ARLR_Norm_Points * item?.qty,
        ARIT_Quantity: item?.qty,
      };
      reward_data.push(data);
    });
    const values = {
      ARAG_Agent_AGAC_keyId: auth?.result?.ACAG_KeyID,
      ARAG_Accumulative_Points: totalRedeemPoints(),
      ARAG_Address_MSAD_KeyID: 0,
      ARAG_Shipping_Email: combinedFormData?.email,
      tbl_ARIT_Agent_Rewards_Items: reward_data,
      tbl_MSAD_Addresses: {
        MSAD_City: combinedFormData?.city,
        MSAD_State: combinedFormData?.state,
        MSAD_ZipCode: combinedFormData?.zip,
        MSAD_Address_Line1: combinedFormData?.street_address,
        MSAD_Address_Line2: combinedFormData?.street_address2,
      },
    };

    setLoading(true);
    try {
      const response = await axios.post(AGENT_REWARD_POST, values);
      setLoading(false);
      if (response?.data?.success) {
        message.success(
          "Our reward request has been successfully created and is being reviewed by our team"
        );
        addToCart(-1);

        navigate(RoutesLookup.AGENT_DASHBOARD);
      } else {
        message.error(response?.data?.message);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }
  // from https://react-slick.neostack.com/docs/example/custom-arrows
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <Image preview={false} src="/right-arrow.svg" alt="avatar" />
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <Image
          style={{
            rotate: "180deg",
          }}
          preview={false}
          src="/right-arrow.svg"
          alt="avatar"
        />
      </div>
    );
  };

  const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <Spin
      spinning={loading}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <AgentLayout>
        <div
          style={{
            paddingLeft: isMobile ? 14 : 60,
            paddingRight: isMobile ? 14 : 60,
            marginTop: 28,
            marginBottom: 20,
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Title level={5} style={{ color: colors.black }}>
                Here is your Cart
              </Title>
              <Tag>
                <p
                  style={{
                    color: colors.primaryBlue,
                    fontWeight: 600,
                    fontSize: 16,
                  }}
                >
                  <span style={{ fontSize: 14 }}>Total Points:</span>{" "}
                  {JSON.parse(localStorage.getItem("totalPoints"))}
                </p>
              </Tag>
            </div>
          </div>
          {isMobile ? (
            <div>
              <div style={{ width: "100%" }}>
                {data?.length > 0 ? (
                  <div style={{ marginBottom: 16 }}>
                    <tr>
                      <th style={{ float: "left" }}>Rewards</th>
                    </tr>
                  </div>
                ) : (
                  <p>Cart is empty </p>
                )}
                <div>
                  {data?.map((item, index) => {
                    return (
                      <div
                        key={"agent-cart-details" + index}
                        style={{
                          marginTop: index !== 0 && 20,
                          boxShadow:
                            "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                          borderRadius: 7,
                          paddingLeft: 16,
                          paddingBottom: 16,
                          paddingTop: 16,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            onClick={() => {
                              handleModal(index);
                            }}
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: 57,
                              }}
                            >
                              <Image
                                style={{
                                  border: "1px solid" + colors.grayNeutral300,
                                  borderRadius: 7,
                                }}
                                preview={false}
                                width={57}
                                height={57}
                                src={item?.ARLR_Image_Path}
                                alt="icon"
                              />
                            </div>
                            <div style={{ marginLeft: 8 }}>
                              <Space direction="vertical" size={0}>
                                <Text
                                  style={{
                                    color: colors.black,
                                    fontWeight: 500,
                                  }}
                                >
                                  {item?.ARLR_Title}
                                </Text>
                                <Text>{item?.ARLR_Description}</Text>
                              </Space>
                            </div>
                          </div>
                          <Popconfirm
                            title="Are you sure you want to  delete this item from cart?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteItem(index)}
                          >
                            <Button type="text">
                              <DeleteOutlined style={{ color: colors.red }} />
                            </Button>
                          </Popconfirm>
                        </div>

                        <div
                          style={{
                            marginTop: 16,
                            paddingRight: 16,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p>Quantity</p>
                            <div
                              style={{
                                marginTop: 10,
                                textAlign: "center",
                                color: colors.black,
                              }}
                            >
                              <Button
                                onClick={() => handleQuantity(index, "-")}
                              >
                                -
                              </Button>

                              <span style={{ padding: 5 }}>{item?.qty}</span>

                              <Button
                                onClick={() =>
                                  handleQuantity(
                                    index,
                                    "+",
                                    item?.ARLR_Quantity,
                                    item?.ARLR_Redeemable_Quantity,
                                    item?.ARLR_KeyID
                                  )
                                }
                              >
                                +
                              </Button>
                            </div>
                          </div>
                          <div>
                            <p>Radeem Points</p>
                            <div
                              style={{
                                textAlign: "center",
                                color: colors.black,
                              }}
                            >
                              <Text>{item?.ARLR_Norm_Points}</Text>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: 16,
                            paddingRight: 16,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <p>Variant</p>
                            <div style={{ textAlign: "center" }}>
                              {data[index]?.tbl_ARRV_Reward_Variants?.map(
                                (item) => (
                                  <div key={item?.ARRV_Reward_Variant_KeyID}>
                                    {item?.status && (
                                      <Text>{item?.ARRV_Title}</Text>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div>
                            <p>Total Radeem Points</p>
                            <div
                              style={{
                                textAlign: "center",
                                color: colors.black,
                              }}
                            >
                              <Text>{item?.ARLR_Norm_Points * item?.qty}</Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Col span={24}>
                <div style={{ backgroundColor: colors.bgGrey, marginTop: 25 }}>
                  <Spin spinning={addressloading}>
                    <div style={{ padding: 10 }}>
                      <Title level={4} style={{ color: colors.black }}>
                        Order Summary
                      </Title>
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <Text style={{ fontWeight: 600 }}>Total Products:</Text>
                        <Text style={{ fontWeight: 600 }}>{data?.length}</Text>
                      </div>
                      <div>
                        <div>
                          <Button
                            style={{
                              fontWeight: 600,
                              padding: 0,
                              paddingRight: 5,
                            }}
                            onClick={detailBtnEmailHandler}
                            type="link"
                          >
                            Add Email Address
                          </Button>

                          <Image
                            style={{ marginBottom: 3 }}
                            preview={false}
                            src={changeIconEmail ? downArrow : rightArrow}
                            alt="icon"
                          />
                        </div>
                        {changeIconEmail && (
                          <Form
                            form={email_form}
                            layout="vertical"
                            autoComplete="off"
                          >
                            <Form.Item
                              name="email"
                              label={
                                <label style={{ color: "#737373" }}>
                                  Email
                                </label>
                              }
                              style={{ marginTop: "8px" }}
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                },
                              ]}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>
                          </Form>
                        )}

                        <Space direction="horizontal" size={6}>
                          <Button
                            style={{
                              fontWeight: 600,
                              padding: 0,
                              paddingRight: 5,
                            }}
                            onClick={detailBtnAddrressHandler}
                            type="link"
                          >
                            Add Shipping Address
                          </Button>

                          <Image
                            style={{ marginBottom: 3 }}
                            preview={false}
                            src={changeIconAddress ? downArrow : rightArrow}
                            alt="icon"
                          />
                        </Space>
                        {changeIconAddress && (
                          <Form
                            form={address_form}
                            layout="vertical"
                            autoComplete="off"
                          >
                            <Form.Item
                              name="street_address"
                              label={
                                <label style={{ color: "#404040" }}>
                                  Street Address 1
                                </label>
                              }
                              style={{ marginTop: "8px" }}
                              rules={[
                                {
                                  message: "Street Address 1 is required",
                                  required: true,
                                  type: "string",
                                },
                              ]}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="street_address2"
                              label={
                                <label style={{ color: "#404040" }}>
                                  Street Address 2
                                </label>
                              }
                              style={{ marginTop: -16 }}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>

                            <Row gutter={14} style={{ marginTop: -20 }}>
                              <Col span={12}>
                                <Form.Item
                                  name="city"
                                  label={
                                    <label style={{ color: "#404040" }}>
                                      City
                                    </label>
                                  }
                                  style={{ marginTop: "8px" }}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select a City"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={city}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name="state"
                                  label={
                                    <label style={{ color: "#404040" }}>
                                      State
                                    </label>
                                  }
                                  style={{ marginTop: "8px" }}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select a State"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={state}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.Item
                              name="zip"
                              label={
                                <label style={{ color: "#404040" }}>Zip</label>
                              }
                              style={{ marginTop: -14 }}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>
                          </Form>
                        )}
                      </div>
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <Text style={{ fontWeight: 600 }}>
                          Total Reward Points
                        </Text>
                        <Text style={{ color: colors.black, fontWeight: 600 }}>
                          {totalRedeemPoints()}
                        </Text>
                      </div>
                      <Button
                        style={{ width: "100%", marginBottom: 20 }}
                        type="primary"
                        onClick={handleFormSubmit}
                      >
                        Redeem Now!
                      </Button>
                    </div>
                  </Spin>
                </div>
              </Col>
            </div>
          ) : (
            <Row
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col className="gutter-row" span={18}>
                <div style={{ height: "80vh", overflow: "auto" }}>
                  <table style={{ width: "99%" }}>
                    <thead>
                      <tr>
                        <th style={{ float: "left" }}>Rewards</th>
                        <th></th>
                        <th>Variant</th>
                        <th>Quantity</th>
                        <th>Redeem Points</th>
                        <th>Total Redeem Points</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((item, index) => {
                        return (
                          <tr
                            key={"agent-cart-redeempoints" + index}
                            style={{
                              boxShadow:
                                "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
                              borderRadius: 7,
                            }}
                          >
                            <td style={{ padding: 5, width: "10%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  cursor: "pointer",
                                  padding: "0px 20px",
                                }}
                                onClick={() => {
                                  handleModal(index);
                                }}
                              >
                                <Image
                                  style={{
                                    border: "1px solid" + colors.grayNeutral300,
                                    borderRadius: 7,
                                    objectFit: "contain",
                                  }}
                                  preview={false}
                                  width={57}
                                  height={57}
                                  src={item?.ARLR_Image_Path}
                                  alt="icon"
                                />
                              </div>
                            </td>
                            <td style={{ width: "30%" }}>
                              <pre
                                style={{
                                  backgroundColor: "transparent",
                                  border: "none",
                                  fontFamily: "inherit",
                                  whiteSpace: "pre-wrap",
                                  padding: "20px 0px",
                                }}
                              >
                                {item?.ARLR_Title}
                              </pre>
                            </td>
                            <td style={{ textAlign: "center", width: "20%" }}>
                              {data[index]?.tbl_ARRV_Reward_Variants?.map(
                                (item) => (
                                  <div key={item?.ARRV_Reward_Variant_KeyID}>
                                    {item?.status && (
                                      <Text>{item?.ARRV_Title}</Text>
                                    )}
                                  </div>
                                )
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: colors.black,
                                width: "15%",
                              }}
                            >
                              <Button
                                onClick={() => handleQuantity(index, "-")}
                              >
                                -
                              </Button>
                              <span style={{ padding: 5 }}>{item?.qty}</span>
                              <Button
                                onClick={() =>
                                  handleQuantity(
                                    index,
                                    "+",
                                    item?.ARLR_Quantity,
                                    item?.ARLR_Redeemable_Quantity,
                                    item?.ARLR_KeyID
                                  )
                                }
                              >
                                +
                              </Button>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: colors.black,
                                width: "5%",
                              }}
                            >
                              <Text>{item?.ARLR_Norm_Points}</Text>
                            </td>
                            <td
                              style={{
                                textAlign: "center",
                                color: colors.black,
                                width: "10%",
                              }}
                            >
                              <Text>{item?.ARLR_Norm_Points * item?.qty}</Text>
                            </td>
                            <td style={{ textAlign: "left", width: "10%" }}>
                              <Popconfirm
                                title="Are you sure you want to  delete this item from cart?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => deleteItem(index)}
                              >
                                <Button type="text">
                                  <DeleteOutlined
                                    style={{ color: colors.red }}
                                  />
                                </Button>
                              </Popconfirm>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div
                  style={{
                    backgroundColor: colors.bgGrey,
                    marginTop: 25,
                    height: "80vh",
                    overflow: "auto",
                  }}
                >
                  <Spin spinning={addressloading}>
                    <div style={{ padding: 10 }}>
                      <Title level={4} style={{ color: colors.black }}>
                        Order Summary
                      </Title>
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <Text style={{ fontWeight: 600 }}>Total Products:</Text>
                        <Text style={{ fontWeight: 600 }}>{data?.length}</Text>
                      </div>
                      <div>
                        <div>
                          <Button
                            style={{
                              fontWeight: 600,
                              padding: 0,
                              paddingRight: 5,
                            }}
                            onClick={detailBtnEmailHandler}
                            type="link"
                          >
                            Add Email Address
                          </Button>

                          <Image
                            style={{ marginBottom: 3 }}
                            preview={false}
                            src={changeIconEmail ? downArrow : rightArrow}
                            alt="icon"
                          />
                        </div>
                        {changeIconEmail && (
                          <Form
                            form={email_form}
                            layout="vertical"
                            autoComplete="off"
                          >
                            <Form.Item
                              name="email"
                              label={
                                <label style={{ color: "#737373" }}>
                                  Email
                                </label>
                              }
                              style={{ marginTop: "8px" }}
                              rules={[
                                {
                                  required: true,
                                  type: "email",
                                },
                              ]}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>
                          </Form>
                        )}

                        <Space direction="horizontal" size={6}>
                          <Button
                            style={{
                              fontWeight: 600,
                              padding: 0,
                              paddingRight: 5,
                            }}
                            onClick={detailBtnAddrressHandler}
                            type="link"
                          >
                            Add Shipping Address
                          </Button>

                          <Image
                            style={{ marginBottom: 3 }}
                            preview={false}
                            src={changeIconAddress ? downArrow : rightArrow}
                            alt="icon"
                          />
                        </Space>
                        {changeIconAddress && (
                          <Form
                            form={address_form}
                            layout="vertical"
                            autoComplete="off"
                          >
                            <Form.Item
                              name="street_address"
                              label={
                                <label style={{ color: "#404040" }}>
                                  Street Address 1
                                </label>
                              }
                              style={{ marginTop: "8px" }}
                              rules={[
                                {
                                  required: true,
                                  type: "string",
                                },
                              ]}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>

                            <Form.Item
                              name="street_address2"
                              label={
                                <label style={{ color: "#404040" }}>
                                  Street Address 2
                                </label>
                              }
                              style={{ marginTop: -16 }}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>

                            <Row gutter={14} style={{ marginTop: -20 }}>
                              <Col span={12}>
                                <Form.Item
                                  name="city"
                                  label={
                                    <label style={{ color: "#404040" }}>
                                      City
                                    </label>
                                  }
                                  style={{ marginTop: "8px" }}
                                >
                                  <Input style={{ width: "100%" }} />
                                  {/*   <Select
                                    placeholder="S"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={city}
                                  /> */}
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name="state"
                                  label={
                                    <label style={{ color: "#404040" }}>
                                      State
                                    </label>
                                  }
                                  style={{ marginTop: "8px" }}
                                >
                                  <Select
                                    showSearch
                                    placeholder="Select a State"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      (option?.label ?? "")
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    options={state}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.Item
                              name="zip"
                              label={
                                <label style={{ color: "#404040" }}>Zip</label>
                              }
                              style={{ marginTop: -14 }}
                            >
                              <Input style={{ width: "100%" }} />
                            </Form.Item>
                          </Form>
                        )}
                      </div>
                      <Divider />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: 8,
                        }}
                      >
                        <Text style={{ fontWeight: 600 }}>
                          Total Reward Points
                        </Text>
                        <Text style={{ color: colors.black, fontWeight: 600 }}>
                          {totalRedeemPoints()}
                        </Text>
                      </div>
                      <Button
                        style={{ width: "100%", marginBottom: 20 }}
                        type="primary"
                        onClick={handleFormSubmit}
                      >
                        Redeem Now!
                      </Button>
                    </div>
                  </Spin>
                </div>
              </Col>
            </Row>
          )}
        </div>
        <Modal
          open={showDetailedProduct !== undefined}
          afterOpenChange={() => {
            carouselRef.current.goTo(showDetailedProduct, undefined);
          }}
          footer={null}
          width={900}
          onCancel={() => {
            handleModal(undefined);
          }}
        >
          <div
            className={isMobile ? "carousel-mobile-style" : "carousel-style"}
          >
            <>
              <Row justify="center">
                <Col span={24}>
                  <Carousel
                    ref={carouselRef}
                    arrows
                    {...settings}
                    dots={false}
                    style={{ padding: isMobile ? 20 : 45 }}
                  >
                    {cart?.map((item) => {
                      return (
                        <div
                          key={"agent-cart-carousel" + item}
                          style={{
                            marginLeft: 49,
                          }}
                        >
                          <div style={contentStyle}>
                            <div style={{ width: "100%" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Image
                                  src={item?.ARLR_Image_Path}
                                  alt="avatar"
                                  height={186}
                                  width={196}
                                  preview={false}
                                  style={{
                                    marginTop: 24,
                                    textAlign: "center",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>

                              <Divider />
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  marginLeft: 20,
                                  marginRight: 20,
                                  paddingBottom: 30,
                                }}
                              >
                                <Typography.Text
                                  className="modal-text-style"
                                  style={{
                                    textAlign: "left",
                                    paddingRight: "40px",
                                  }}
                                >
                                  {item?.ARLR_Title}
                                </Typography.Text>

                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "end",
                                  }}
                                >
                                  <Typography.Text className="modal-price-text-style">
                                    {item?.ARLR_Norm_Points}
                                  </Typography.Text>
                                  <Typography.Text className="modal-price-2text-style">
                                    Reward points
                                  </Typography.Text>
                                </div>
                              </div>
                              <div
                                style={{
                                  maxHeight: "300px",
                                  overflowY: "scroll",
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign: "start",
                                  width: isMobile ? 300 : "100%",
                                }}
                              >
                                <Typography.Text className="modal-2text-style">
                                  <pre
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                      fontFamily: "inherit",
                                      padding: "0px 14px",
                                    }}
                                  >
                                    {item?.ARLR_Description}
                                  </pre>
                                </Typography.Text>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </Col>
              </Row>
            </>
          </div>
          <div style={{ marginTop: 44, textAlign: "center" }}>
            <Typography.Text className="modal-bottom-first-text-style">
              Wow! You have great taste!
            </Typography.Text>
          </div>
          <div
            style={{
              marginTop: 8,
              textAlign: "center",
              paddingBottom: 80,
              marginLeft: isMobile ? 24 : 148,
              marginRight: isMobile ? 24 : 148,
            }}
          >
            <Typography.Text className="modal-bottom-second-text-style">
              We're sending a note to our team who will make sure we have all we
              need to get this out to you quickly. We can't wait to see what you
              pick next time!
            </Typography.Text>
          </div>
        </Modal>
      </AgentLayout>
    </Spin>
  );
};
export default AgentCart;
