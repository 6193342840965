import { message } from "antd";
import axios from "axios";
import { codeMessage } from "../AdminPortal/utils/request";
import { USER_TYPES } from '../Constants/ApplicationConstants';
import { getRoleFromAuth, getUserType } from '../../utils/commonUtils';
import { RoutesLookup } from '../Routes/RoutesLookup';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const apiLocation = process.env.REACT_APP_BASE_URL;

if (!apiLocation) {
  throw new Error("REACT_APP_BASE_URL is not found");
}

axios.interceptors.response.use((response) => response, manageErrorConnection);

// setAxiosInterceptors();
console.log(`API LOCATION IS ${apiLocation}`);

const initAxiosGlobalConfigs = (auth) => {
  if (auth && auth.result) {
    let loggedInUserId = 0;

    if (
      getUserType(getRoleFromAuth(auth)) === USER_TYPES.ADMIN
    ) {
      loggedInUserId = auth.result.ORAD_KeyID;
    } else {
      loggedInUserId = auth.result.ACAG_KeyID;
    }

    axios.defaults.baseURL = apiLocation;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.common["logged-in-user-id"] = loggedInUserId;
  }
};
const refreshAccessToken = async (msalInstance, account) => {
  const accessTokenRequest = {
    scopes: [],
    account
  };
  try {
    const token = await msalInstance.acquireTokenSilent(accessTokenRequest);
    return token;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return msalInstance.acquireTokenPopup(accessTokenRequest)
        .then((resp) => {
          return resp.idToken;
        })
        .catch((err) => {
          return null;
        });
    } else {
      console.error(error);
    }
  }
};
async function setAxioAuth(msalInstance) {
  const account = JSON.parse(localStorage.getItem('account'))
  msalInstance.setActiveAccount(account)
  refreshAccessToken(msalInstance, account).then((token) => {
    if (token) {
      const account = token.account
      const idToken = token.idToken
      localStorage.setItem('account', JSON.stringify(account))
      localStorage.setItem('token', JSON.stringify(idToken))
      msalInstance.setActiveAccount(account);
      axios.defaults.headers.common["Authorization"] = "Bearer " + idToken;
      axios.defaults.headers.common["session-token"] = idToken;
    }
  })
}
export function setAxiosInterceptors(msalInstance) {
  axios.interceptors.request.use(
    async (config) => {
      if (!config.headers["Authorization"]) {
        const idToken = JSON.parse(localStorage.getItem('token'))
        if (idToken) {
          config.headers["Authorization"] = "Bearer " + idToken;
          config.headers["session-token"] = idToken
          axios.defaults.headers.common["Authorization"] = "Bearer " + idToken;
          axios.defaults.headers.common["session-token"] = idToken;
        }
      }
      setAxioAuth(msalInstance)
      return config;
    },
    (error) => Promise.reject(error)
  );
}

export const clearAxiosAuth = () => {
  axios.defaults.headers.common["Authorization"] = "";
  axios.defaults.headers.common["session-token"] = "";
  axios.defaults.headers.common["logged-in-user-id"] = "";
}

export default initAxiosGlobalConfigs;

function manageErrorConnection(err) {
  if (err.response && err.response.status !== 400) {
    let errorMessage = codeMessage[err?.response?.status];
    if (err.response && err.response.status === 401) {
      window.location.href = RoutesLookup.SESSION_TIMEOUT;
    } else {
      message.error(errorMessage);
    }
    return Promise.reject(new Error(errorMessage));
  } else if (err.code === "ECONNREFUSED") {
    return "nevermind";
  } else {
    return Promise.reject(err);
  }
}
