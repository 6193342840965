export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const ADB2C_WORKFLOW_AUTHORITY_URI = process.env.REACT_APP_ADB2C_WORKFLOW_AUTHORITY_URI;
export const ADB2C_WORKFLOW_FORGOT_PASSWORD = process.env.REACT_APP_ADB2C_WORKFLOW_FORGOT_PASSWORD;
export const ADB2C_AUTHORITY_DOMAIN = process.env.REACT_APP_ADB2C_AUTHORITY_DOMAIN;
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID;
export const ADB2C_CLIENT_SECRET = process.env.REACT_APP_ADB2C_CLIENT_SECRET;
export const ADB2C_SCOPE_AGENT_API = process.env.REACT_APP_ADB2C_SCOPE_AGENT_API;
export const ADB2C_SCOPE_ADMIN_API = process.env.REACT_APP_ADB2C_SCOPE_ADMIN_API;
export const ADB2C_SCOPE_SUPERADMIN_API = process.env.REACT_APP_ADB2C_SCOPE_SUPERADMIN_API;
export const ADB2C_ADMIN_REDIRECT_URI = process.env.REACT_APP_ADB2C_ADMIN_REDIRECT_URI;
export const ADB2C_AGENT_REDIRECT_URI = process.env.REACT_APP_ADB2C_AGENT_REDIRECT_URI;
export const ADB2C_ADMIN_LOGOUT_REDIRECT_URL = process.env.REACT_APP_ADB2C_ADMIN_LOGOUT_REDIRECT_URL;
export const ADB2C_AGENT_LOGOUT_REDIRECT_URL = process.env.REACT_APP_ADB2C_AGENT_LOGOUT_REDIRECT_URL;
export const REACT_APP_ADB2C_GENERAL_LOGIN_REDIRECT = process.env.REACT_APP_ADB2C_GENERAL_LOGIN_REDIRECT;
export const ADB2C_WORKFLOW_SIGN_UP_SIGN_IN_AGENT = process.env.REACT_APP_ADB2C_WORKFLOW_SIGN_UP_SIGN_IN_AGENT;
export const ADB2C_WORKFLOW_SIGN_UP_SIGN_IN_ADMIN = process.env.REACT_APP_ADB2C_WORKFLOW_SIGN_UP_SIGN_IN_ADMIN;
export const AD_CLIENT_ID = process.env.REACT_APP_AD_CLIENT_ID;
export const AD_AUTHORITY = process.env.REACT_APP_AD_AUTHORITY;
export const ADB2C_SCOPES = [ADB2C_SCOPE_AGENT_API, ADB2C_SCOPE_ADMIN_API, ADB2C_SCOPE_SUPERADMIN_API];