import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import useSWR from "swr";
import AdminAppLayout from "../../Layouts/AdminLayout";
import {
  Button,
  message,
  Typography,
  Divider,
  Row,
  Col,
  Form,
  Input,
  Image,
  Upload,
  Spin,
} from "antd";
import "./style.css";
import { colors } from "../../utils/colors";
import profileIcon from "../../../../assets/icons/profile.svg";
import UserContext from "../../../Contexts/UserContext";
import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import {
  ADMIN_PUT_API,
  GET_ADMINS_BY_ID_API,
  UPLOAD_IMAGE_API,
} from '../../../API/paths';

const { Title } = Typography;
const Profile = () => {
  const { auth } = useContext(UserContext);
  const [toggle, setToggle] = useState(true);
  const [pageLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [uploadedImageUrl, setUploadedImageUrl] = useState();
  const {
    data,
    isValidating: loading,
    mutate,
  } = useSWR({
    url: BASE_URL + GET_ADMINS_BY_ID_API + "?Id=" + auth?.result?.ORAD_KeyID,
  });

  const sendRewardImage = async (base64Image, fileName) => {
    const formValues = form.getFieldsValue();

    const value = {
      Image_String: base64Image,
      Image_Name: fileName,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        `${BASE_URL}${UPLOAD_IMAGE_API}`,
        value
      );
      if (formValues?.phone) {
        setUploadedImageUrl(response?.data);
      }
      handleSubmit(response?.data);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  const handleFileUpload = (file) => {
    const fileExtension = file.name.split(".")[1];
    const isCorrectType =
      file.type === "image/jpeg" ||
      fileExtension === "jpeg" ||
      file.type === "image/png" ||
      fileExtension === "png" ||
      file.type === "image/jpg" ||
      fileExtension === "jpg";
    if (!isCorrectType) {
      message.error("You can only upload jpg/jpeg/png images!");
      return;
    }
    const isCorrectSize = file.size / 1024 / 1024 <= 10;
    if (!isCorrectSize) {
      message.error("File must be smaller than 10MB!");
    }
    if (isCorrectType && isCorrectSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        sendRewardImage(reader.result.split(",")[1], file?.name);
      };
      return false;
    }
  };

  const handleSubmit = (image) => {
    const value = form.getFieldsValue();

    if (
      value?.phone === null ||
      value?.phone === undefined ||
      value?.phone === ""
    ) {
      message.error("Please enter correct details");
      setLoading(false);
    } else {
      let dataCopy = { ...data?.result };
      /* dataCopy.ORAD_Password = value?.password; */
      dataCopy.ORAD_Phone = value?.phone;
      dataCopy.ORAD_Image_Path = image ? image : dataCopy?.ORAD_Image_Path;
      delete dataCopy.ORAD_zalt_altered_date;
      mutate(async () => {
        try {
          let response = await axios.put(BASE_URL + ADMIN_PUT_API, dataCopy);
          if (response?.data?.success) {
            setLoading(false);
            message.success(response?.data?.message);
            setToggle(true);
          } else {
            message.error(response?.data?.message);
            setLoading(false);
          }
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      });
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      phone: data?.result?.ORAD_Phone,
      /* password: data?.result?.ORAD_Password, */
    });
    setUploadedImageUrl(data?.result?.ORAD_Image_Path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutate, data]);

  return (
    <AdminAppLayout>
      <Spin spinning={loading || pageLoading}>
        <div
          style={{
            paddingLeft: 60,
            paddingRight: 60,
            marginTop: 56,
            marginBottom: 20,
          }}
        >
          <Title level={5} style={{ color: colors.black }}>
            Here is your Profile Detail
          </Title>

          <Divider />

          <div>
            <div>
              <Image
                width={60}
                height={60}
                style={{ borderRadius: "100%" }}
                src={uploadedImageUrl ? uploadedImageUrl : profileIcon}
                preview={false}
              />
              <Upload
                showUploadList={false}
                beforeUpload={(file) => handleFileUpload(file)}
              >
                <Button
                  style={{
                    color: colors.primaryGreen,
                    fontWeight: 500,
                    marginLeft: 20,
                  }}
                >
                  Change Image
                </Button>
              </Upload>
            </div>
          </div>

          <p
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: colors.grey500,
              marginTop: -4,
            }}
          >
            {data?.result?.ORAD_Name}
          </p>

          <Row>
            <Col span={8}>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: 12, alignItems: "center" }}>
                <Title level={5} style={{ color: colors.black }}>
                  Security
                </Title>
                {toggle && (
                  <Button type="primary" onClick={() => setToggle(false)}>
                    Edit
                  </Button>
                )}
              </div>
              <Divider />
              <Form
                form={form}
                name="validateOnly"
                layout="vertical"
                autoComplete="off"
              >
                <Form.Item
                  style={{ display: "none" }}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please enter password",
                    },
                    {
                      min: 4,
                      message: "Password should contain at least 4 characters.",
                    },
                  ]}
                  label="Password"
                >
                  <Input.Password disabled={toggle} type="password" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please enter phone number.",
                    },
                  ]}
                  label="Phone Number"
                >
                  <Input disabled={toggle} type="number" />
                </Form.Item>

                {!toggle && (
                  <Button
                    type="primary"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Update
                  </Button>
                )}
              </Form>
            </Col>
          </Row>

        </div>
      </Spin>
    </AdminAppLayout>
  );
};

export default Profile;
