import { DEFAULT_USER_TYPE, USER_ROLE, USER_TYPES } from '../App/Constants/ApplicationConstants'

export const getUserType = (role) => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
        return USER_TYPES.ADMIN
    }
    else if (role === USER_ROLE.AGENT) {
        return USER_TYPES.AGENT
    }
    return DEFAULT_USER_TYPE
}

export const getRoleFromAuth = (auth) => {
    if (auth?.result) {
        if (auth.result.ORAD_RoleName === USER_ROLE.SUPER_ADMIN) {
            return USER_ROLE.SUPER_ADMIN
        } else if (auth.result.ORAD_RoleName === USER_ROLE.ADMIN) {
            return USER_ROLE.ADMIN
        }
        return USER_ROLE.AGENT
    }
    return undefined
}