import React, { useContext, useState } from "react";
import {
  Button,
  Layout,
  Image,
  Space,
  Popconfirm,
  Badge,
  Avatar,
  Drawer,
} from "antd";
import useSWR from "swr";
import quotingIcon from "../../../assets/icons/quoting-icon.svg";
import {
  HeartOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  MenuOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { CartContext } from "../../Contexts/CartContext";

import { colors } from "../utils/colors";
import { useMsal } from "@azure/msal-react";
import navIcon from "../../../assets/logo.png";
import "./Layout.css";
import UserContext from "../../Contexts/UserContext";
import profileIcon from "../../../assets/icons/profile.svg";
import { isMobile } from "react-device-detect";
import Footer from "./footer";
import { msalLogoutUsers } from "../../../utils/manageUserData";
import { GET_AGENT_BY_ID } from "../../API/paths";
import { RoutesLookup } from "../../Routes/RoutesLookup";
import { QUOTING_PORTAL_LINK } from "../../Constants/ApplicationConstants";

const { Header, Content } = Layout;

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const { cart } = useContext(CartContext);
  const { auth, setAuth } = useContext(UserContext);
  const { instance: msalInstance } = useMsal();
  const agentId = auth?.result?.ACAG_KeyID;
  const { data: agent } = useSWR({
    url: `${GET_AGENT_BY_ID}?KeyId=${agentId}`,
  });
  const profileImg = agent?.result?.ACAG_Image_Path
    ? agent?.result?.ACAG_Image_Path
    : profileIcon;

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  // Dropdown items while hover on Home Link
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          paddingLeft: isMobile ? 14 : 32,
          paddingRight: isMobile ? 14 : 32,
          background: colors.white,
          borderBottom: "1px solid #CFD9E0",
        }}
      >
        {isMobile ? (
          <>
            <div
              style={{
                marginTop: 5,
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div onClick={showDrawer} style={{ marginBottom: -10 }}>
                <MenuOutlined style={{ fontSize: 24 }} />
              </div>

              <Button
                type="link"
                onClick={() => {
                  navigate(RoutesLookup.AGENT_DASHBOARD);
                }}
              >
                <Image
                  height={26}
                  style={{ marginBottom: 10 }}
                  width={176}
                  preview={false}
                  src={navIcon}
                />
              </Button>
            </div>

            <Drawer placement="left" onClose={onClose} open={open}>
              <div style={{ float: "left" }}>
                <Space direction="vertical" size={20}>
                  {[
                    {
                      title: "My Favorites",
                      icon: <HeartOutlined style={{ fontSize: 25 }} />,
                      action: () => {
                        navigate(RoutesLookup.AGENT_FAVORITE);
                        onClose();
                      },
                    },
                    {
                      title: `${auth?.result?.ACAG_Name}`,
                      icon: <UserOutlined style={{ fontSize: 25 }} />,
                      action: () => {
                        navigate(RoutesLookup.AGENT_DETAIL);
                        onClose();
                      },
                    },
                    {
                      title: "Cart",
                      icon: <ShoppingCartOutlined style={{ fontSize: 25 }} />,
                      action: () => {
                        navigate(RoutesLookup.AGENT_CART);
                        onClose();
                      },
                    },
                  ].map((item, index) => (
                    <Badge
                      key={"app-layout-badge2" + index}
                      offset={[-8, 0]}
                      count={index === 2 ? cart?.length : ""}
                    >
                      <Button
                        onClick={item.action}
                        key={item.title}
                        type="link"
                        className="f-s"
                        icon={item.icon}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {item.title}
                      </Button>
                    </Badge>
                  ))}
                  <Popconfirm
                    title="Logout"
                    description="Are you sure to logout?"
                    onConfirm={() => {
                      msalLogoutUsers(
                        msalInstance,
                        auth,
                        setAuth,
                        setOpen,
                        navigate
                      );
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Space direction="horizontal" size={12}>
                      <Avatar
                        src={profileImg}
                        style={{
                          backgroundColor: colors.purple500,
                          verticalAlign: "middle",
                          cursor: "pointer",
                          marginLeft: 18,
                        }}
                        size="large"
                      />
                      <p>
                        <b
                          onClick={() => {
                            msalLogoutUsers(
                              msalInstance,
                              auth,
                              setAuth,
                              setOpen,
                              navigate
                            );
                          }}
                        >
                          Logout
                        </b>
                      </p>
                    </Space>
                  </Popconfirm>
                </Space>
              </div>
            </Drawer>
          </>
        ) : (
          <>
            <div style={{ float: "left" }}>
              <Space direction="horizontal">
                <Button
                  type="link"
                  onClick={() => {
                    navigate(RoutesLookup.AGENT_DASHBOARD);
                  }}
                >
                  <Image
                    height={26}
                    style={{ marginBottom: 10 }}
                    width={176}
                    preview={false}
                    src={navIcon}
                  />
                </Button>
              </Space>
            </div>

            <Space direction="horizontal" size={48} style={{ float: "right" }}>
              <Space direction="horizontal" size={48}>
                {[
                  {
                    title: "Quoting",
                    textStyles: {
                      color: "#199E59",
                    },
                    icon: (
                      <img
                        alt="quoting-icon"
                        src={quotingIcon}
                        style={{
                          width: 12,
                          marginBottom: -2,
                        }}
                      />
                    ),
                    action: () => {
                      window.open(QUOTING_PORTAL_LINK, "_blank");
                    },
                  },
                  {
                    title: "Home",
                    icon: <HomeOutlined />,
                    action: () => {
                      navigate(RoutesLookup.AGENT_DASHBOARD);
                    },
                  },
                  {
                    title: "My Favorites",
                    icon: <HeartOutlined />,
                    action: () => {
                      navigate(RoutesLookup.AGENT_FAVORITE);
                    },
                  },
                  {
                    title: `My Profile/Orders`,
                    icon: <UserOutlined />,
                    action: () => {
                      navigate(RoutesLookup.AGENT_DETAIL);
                    },
                  },
                  {
                    title: "Cart",
                    icon: <ShoppingCartOutlined />,
                    action: () => {
                      navigate(RoutesLookup.AGENT_CART);
                    },
                  },
                ].map((item, index) => (
                  <Badge
                    key={"app-layout-badge2" + index}
                    offset={[-5, 0]}
                    count={index === 4 ? cart?.length : ""}
                  >
                    <Button
                      onClick={item.action}
                      key={item.title}
                      type="link"
                      className="f-s"
                      icon={item.icon}
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      <span style={item.textStyles}>{item.title}</span>
                    </Button>
                  </Badge>
                ))}
              </Space>
              <Popconfirm
                title="Logout"
                description="Are you sure to logout?"
                onConfirm={() => {
                  msalLogoutUsers(
                    msalInstance,
                    auth,
                    setAuth,
                    setOpen,
                    navigate
                  );
                }}
                okText="Yes"
                cancelText="No"
              >
                <Avatar
                  src={agent?.result?.ACAG_Image_Path}
                  icon={<UserOutlined />}
                  style={{
                    backgroundColor: colors.purple500,
                    verticalAlign: "middle",
                    cursor: "pointer",
                  }}
                  size="medium"
                />
              </Popconfirm>
            </Space>
          </>
        )}
      </Header>
      <Content
        style={{
          backgroundColor: "white",
        }}
      >
        {children}
      </Content>
      <div>
        <Footer></Footer>
      </div>
    </Layout>
  );
};
export default AppLayout;
