import React, { useState, useEffect, useRef } from "react";
import {
  Space,
  Typography,
  Button,
  Input,
  Row,
  Col,
  message,
  Modal,
  Form,
  Card,
  Alert,
  Spin,
} from "antd";
import { useNavigate } from "react-router";
import useSWR from "swr";
import axios from "axios";
import { useDebouncedCallback } from "use-debounce";
import { useSearchParams } from "react-router-dom";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { colors } from "../../utils/colors";
import TableComponent from "../../components/Table/TableComponent";
import {
  formateDateWithTime,
  generateSearchParamsGetter,
  getNewSearchParamStringWithArgs,
  getSearchParams,
} from "../../utils/helpers";
import AdminAppLayout from "../../Layouts/AdminLayout";
import { BASE_URL } from "../../../Constants/EnvironmentVariableProvider";
import { RoutesLookup } from "../../../Routes/RoutesLookup";
import EditForm from "./form";
import { getColumns } from "./columns";
import {
  AGENTS_FILTER_DATA_API,
  EDIT_AGENT_API,
  AGENT_NOTES,
  FILE_DOWNLOAD_API,
  FILE_DOWNLOAD_API_ALL,
} from "../../../API/paths";
import AdminNoteModal from "./AdminNoteModal";
import { DATAGRID_DEFAULT_PAGE_SIZE } from "../../../Constants/ApplicationConstants";

const { Text } = Typography;

const ManageAgent = () => {
  const [form] = Form.useForm();
  const tableRef = useRef(null);
  let [spObj, setSearchParams] = useSearchParams({
    page: "1",
    pageSize: DATAGRID_DEFAULT_PAGE_SIZE.toString(),
  });
  spObj.get('page') === null && spObj.set('page', '1');
  spObj.get('pageSize') === null && spObj.set('pageSize', DATAGRID_DEFAULT_PAGE_SIZE.toString());

  const [notes, setNotes] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [notesLoading, setNotesLoading] = useState(false);
  const [noteId, setNoteId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [noteModalOpen, setNoteModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const navigate = useNavigate();

  let [id, page, pageSize, filterName, statusType] = getSearchParams(spObj);

  const handleSearch = (e) => {
    debounceFetcher(e.target.value);
    setSearchState(e.target.value);
  };

  const getNewSearchParamString = generateSearchParamsGetter(spObj);

  const debounceFetcher = useDebouncedCallback((search) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({ page: 1, pageSize, search, statusType })
    );
  }, 600);

  const redirect = (id) => {
    navigate(`${RoutesLookup.MANAGE_AGENT}${RoutesLookup.AGENT_PROFILE}/${id}`);
  };

  const editDetail = (agentId) => {
    setSearchParams(`${getNewSearchParamString()}&id=${agentId}`);
    setModalOpen(true);
  };
  const {
    data: agents,
    isValidating: loading,
    mutate,
  } = useSWR({
    url: BASE_URL + AGENTS_FILTER_DATA_API + getNewSearchParamString(),
  });

  const getNotesById = async (agentId) => {
    try {
      setNotesLoading(true);
      const response = await axios.get(`${AGENT_NOTES}?KeyId=${agentId}`);
      const sortedNotes = response?.data?.result?.sort(
        (a, b) => {
          const aDate = new Date(a.AGNO_zalt_altered_date).getTime()
          const bDate = new Date(b.AGNO_zalt_altered_date).getTime()
          return aDate - bDate
        });

      setNotes(sortedNotes);
      setNotesLoading(false);
    } catch (e) {
      setNotesLoading(false);
      console.log(e);
    }
  };

  const singleNote = (noteId) => {
    setNoteId(noteId);
    setNoteModal(true);
  };
  async function downloadFile(value) {
    let selectedFileUrls = "ACAG_KeyIDs=";
    selectedRows?.forEach((item, index) => {
      selectedFileUrls +=
        item?.ACAG_KeyID + (index !== selectedRows?.length - 1 ? "%2C" : "");
    });
    const seletedAgentsUrl = `${FILE_DOWNLOAD_API}?${selectedFileUrls}`;
    try {
      setFileLoading(true);
      const response = await axios.get(
        value === "all" ? FILE_DOWNLOAD_API_ALL : seletedAgentsUrl
      );
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setFileLoading(false);
    } catch (e) {
      console.log(e);
      setFileLoading(false);
    }
  }

  useEffect(() => {
    if (mutate) mutate();
  }, [filterName, pageSize, page, mutate, statusType]);

  useEffect(() => {
    setActiveTab(parseInt(statusType ?? 0))
  }, [statusType])

  const [searchState, setSearchState] = useState(filterName ? filterName : '')
  useEffect(() => {
    setSearchState(filterName)
  }, [filterName])

  const popoverContent = () => {
    return (
      <Spin spinning={notesLoading}>
        <div
          style={{
            width: 396,
            height: 314,
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {notes?.length > 0 ? (
            notes.map((item) => (
              <div
                className="hoverAffect"
                key={"manage-agent-notes-" + item}
                onClick={() => singleNote(item?.AGNO_KeyID)}
              >
                <p style={{ cursor: "pointer", padding: 10, marginTop: 7 }}>
                  {item?.AGNO_Notes}
                </p>
                <p
                  style={{
                    textAlign: "right",
                    fontSize: 10,
                    fontWeight: 400,
                    color: colors.black,
                    marginRight: 4,
                  }}
                >
                  {formateDateWithTime(item?.AGNO_zalt_altered_date)}
                </p>
              </div>
            ))
          ) : (
            <p>No record found</p>
          )}
        </div>
      </Spin>
    );
  };
  const onPaginationChange = async (page, pageSize) => {
    setSearchParams(
      getNewSearchParamStringWithArgs({
        page,
        pageSize,
        statusType,
        search: filterName,
      })
    );
  };

  const columns = getColumns({
    editDetail,
    popoverContent,
    redirect,
    getNotesById,
    setIsEdit,
  });

  const handleSubmit = (values) => {
    let updateData = { ...values };
    const data = { ACAG_KeyID: id, tbl_MSAD_Addresses: updateData };
    mutate(async () => {
      try {
        const response = await axios.put(EDIT_AGENT_API, data);
        message.success(response?.data?.message);
        setModalOpen(false);
        form.resetFields();
      } catch (e) {
        console.log(e);
      }
    });
  };

  return (
    <AdminAppLayout>
      <Spin
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        spinning={fileLoading}
      >
        <div
          style={{
            paddingLeft: 60,
            paddingRight: 60,
            marginTop: 56,
            marginBottom: 20,
          }}
        >
          <Row
            style={{ marginBottom: 15 }}
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            <Col className="gutter-row" span={6}>
              <div>
                <Card style={{ background: colors.bgGrey }}>
                  <Space direction="vertical">
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        color: colors.black,
                      }}
                    >
                      Total Agents
                    </Text>
                    <Text
                      style={{
                        fontSize: 30,
                        fontWeight: 600,
                        color: colors.black,
                      }}
                    >
                      {agents?.totalRecords}
                    </Text>
                  </Space>
                </Card>
              </div>
            </Col>
            <Col className="gutter-row" span={6}></Col>
            <Col className="gutter-row" span={6}></Col>
            <Col className="gutter-row" span={6}></Col>
          </Row>
          <Row
            style={{ marginTop: 30, marginBottom: 28 }}
            gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          >
            <Col className="gutter-row" span={8}>
              <div>
                <Input
                  size="large"
                  placeholder="Search Agent..."
                  defaultValue={filterName}
                  value={searchState}
                  onChange={handleSearch}
                  allowClear
                  prefix={
                    <SearchOutlined
                      style={{
                        color: "rgba(0,0,0,.45)",
                      }}
                    />
                  }
                />
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Space direction="horizontal" size={40}>
                  {[
                    {
                      btnText: "All",
                      total: agents?.allCount,
                      onClick: () => {
                        setActiveTab(0);
                        setSearchParams(
                          getNewSearchParamStringWithArgs({
                            page: 1,
                            pageSize,
                            statusType: 0,
                          })
                        );
                      },
                    },
                    {
                      btnText: "Active",
                      total: agents?.activeCount,
                      onClick: () => {
                        setActiveTab(1);
                        setSearchParams(
                          getNewSearchParamStringWithArgs({
                            page: 1,
                            pageSize,
                            statusType: 1,
                          })
                        );
                      },
                    },
                    {
                      btnText: "Inactive",
                      total: agents?.inActiveCount,
                      onClick: () => {
                        setActiveTab(2);
                        setSearchParams(
                          getNewSearchParamStringWithArgs({
                            page: 1,
                            pageSize,
                            statusType: 2,
                          })
                        );
                      },
                    },
                  ].map((item, i) => (
                    <Button
                      style={{
                        color: activeTab === i && colors.grey900,
                        backgroundColor:
                          activeTab === i && colors.grayNeutral100,
                      }}
                      key={item.btnText}
                      onClick={item.onClick}
                      type="text"
                    >
                      {item.btnText} ({item.total})
                    </Button>
                  ))}
                </Space>
              </div>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button
                onClick={() => downloadFile("all")}
                type="primary"
                icon={<DownloadOutlined />}
                size={"middle"}
              >
                Export all Agents Profile
              </Button>
            </Col>
          </Row>

          {/* Table component */}
          <div>
            <TableComponent
              loading={loading}
              rowSelection={true}
              setSelectedRows={setSelectedRows}
              data={agents?.result}
              totalRecords={agents?.totalRecords}
              columns={columns}
              tableRef={tableRef}
              border
              onPaginationChange={onPaginationChange}
              pagination={{
                totalPage: agents?.totalPages * pageSize,
                pageSize: pageSize,
              }}
              isServerPagination // true for serverSide pagination
            />
          </div>
          {/* Selected agent notification */}
          {selectedRows?.length > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                bottom: 46,
              }}
            >
              <Alert
                style={{
                  width: "30%",
                  backgroundColor: colors.primaryGreen,
                  color: colors.white,
                }}
                message={`${selectedRows?.length} Agents Selected`}
                action={
                  <Space>
                    <Button
                      style={{ color: colors.white }}
                      onClick={() => setSelectedRows([])}
                      size="small"
                      type="text"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={downloadFile}
                      style={{
                        color: colors.primaryGreen,
                        background: colors.white,
                      }}
                      size="small"
                      type="text"
                    >
                      Export
                    </Button>
                  </Space>
                }
              />
            </div>
          ) : (
            ""
          )}

          {/* Edit detail model */}
          <Modal
            onCancel={() => {
              setModalOpen(false);
              form.resetFields();
            }}
            closable={true}
            open={modalOpen}
            footer={null}
          >
            <EditForm
              id={id}
              form={form}
              handleSubmit={handleSubmit}
              listLoading={loading}
              closeModal={setModalOpen}
              isEdit={isEdit}
            />
          </Modal>

          {/* Single note display modal */}
          <AdminNoteModal
            notes={notes}
            id={noteId}
            setNoteModal={setNoteModal}
            noteModalOpen={noteModalOpen}
          />
        </div>
      </Spin>
    </AdminAppLayout>
  );
};
export default ManageAgent;
