import { Button, Form, Input, Row, Col, Spin } from "antd";
import axios from "axios";
import React, { useEffect } from "react";

import useForceUpdate from "../../hooks/useForceUpdate";
import useLoading from "../../hooks/useLoading";
import { colors } from "../../utils/colors";

import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import { GET_AGENT_BY_ID_API } from '../../../API/paths';

const CategoryForm = ({
  id,
  form,
  handleSubmit,
  closeModal,
  listLoading,
  isEdit,
}) => {
  const forceUpdate = useForceUpdate();
  const [fetchAgent, loading] = useLoading(async () => {
    form.resetFields();
    try {
      const response = await axios.get(
        `${BASE_URL}${GET_AGENT_BY_ID_API}?KeyId=${id}`
      );
      const data = response?.data?.result?.tbl_MSAD_Addresses;
      if (data) {
        form.setFieldsValue({
          MSAD_Address_Line1: data?.MSAD_Address_Line1,
          MSAD_Address_Line2: data?.MSAD_Address_Line2,
          MSAD_State: data?.MSAD_State,
          MSAD_ZipCode: data?.MSAD_ZipCode,
          MSAD_City: data?.MSAD_City,
        });
        forceUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    if (id) {
      fetchAgent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div>
      <Spin spinning={listLoading || loading}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          style={{ padding: 30 }}
        >
          <Form.Item
            style={{ marginTop: 10 }}
            label="Street address 1"
            name="MSAD_Address_Line1"
            rules={[
              {
                required: true,
                message: "Please enter a street address",
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
          <Form.Item
            label="Street address 2"
            name="MSAD_Address_Line2"
            rules={[
              {
                required: true,
                message: "Please enter a street address",
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="City"
                name="MSAD_City"
                rules={[
                  {
                    required: true,
                    message: "Please enter a city name",
                  },
                ]}
              >
                <Input type="textarea" />
              </Form.Item>
            </Col>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="State"
                name="MSAD_State"
                rules={[
                  {
                    required: true,
                    message: "Please enter a state name",
                  },
                ]}
              >
                <Input type="textarea" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="ZIP"
            name="MSAD_ZipCode"
            rules={[
              {
                required: true,
                message: "Please enter zip code",
              },
            ]}
          >
            <Input type="textarea" />
          </Form.Item>
          <div style={{ textAlign: "right" }}>
            <Button
              onClick={() => {
                closeModal(false);
                form.resetFields();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              style={{ background: colors.primaryGreen, color: colors.white }}
            >
              Save
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default CategoryForm;
