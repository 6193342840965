import { Typography, Divider, Collapse } from "antd";
import React from "react";
import "./style.css";
import AgentLayout from "../../../AdminPortal/Layouts/Layout";
import { isMobile } from "react-device-detect";
import { colors } from "../../../AdminPortal/utils/colors";

const { Title } = Typography;
const { Panel } = Collapse;
const detail = [
  {
    question: " What is the Normandy Loyalty Points Program?",
    answer:
      "The Loyalty Points Program is our way of rewarding our loyal agents. Every time you bind an account with Normandy, or one of our partners, you earn points. These points can be redeemed for free products and other rewards.",
  },
  {
    question: "How do I earn points?",
    answer:
      "With each bind! Simply bind an account with Normandy, or one of our partners, and earn points. It's that easy!",
  },
  {
    question: " How many points do I earn for each bind?",
    answer:
      "The number of points you earn for each purchase depends on the net premium amount. The minimum net premium needed to earn points is $1,000. The maximum number of points you can earn on an individual policy is 2,500 points.",
    answersList: {
      intro: "Example of premium size to points earned:",
      answers: [
        "$1,000 = 10",
        "$5,000 = 50",
        "$7,500 = 75",
        "$15,000 = 150",
        "$75,000 = 750",
      ],
    },
  },
  {
    question: "How do I redeem my points?",
    answer:
      "To redeem your points, simply log in to your account and go to the Loyalty Points Program page. From there, you can choose which reward you'd like to redeem. Once you've selected your reward, the points will be deducted from your account and you'll receive confirmation once your reward has been processed",
  },
  {
    question: "Can I transfer my points to someone else?",
    answer:
      "No. Points are non-transferable and can only be redeemed by the account holder",
  },
  {
    question: " How do I know how many points I have?",
    answer:
      "You can check your point balance at any time by logging in to your account and visiting the Loyalty Points Program page.",
  },
];

const PointsDetail = () => {
  return (
    <AgentLayout>
      <div
        style={{
          paddingLeft: isMobile ? 14 : 60,
          paddingRight: isMobile ? 14 : 60,
          marginTop: 28,
          marginBottom: 20,
        }}
      >
        <Title level={3} style={{ color: colors.black, fontWeight: 700 }}>
          HOW POINTS WORK
        </Title>
        <Divider />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "60%", minHeight: "100vh" }}>
            <div
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: colors.black,
                marginLeft: 16,
                marginBottom: 12,
              }}
            >
              Welcome to the Normandy Loyalty Points Program FAQ page! Below are
              answers to some common questions about our program. If you have a
              question that isn't answered below, please contact our customer
              service team for assistance.
            </div>
            <Collapse defaultActiveKey={[0, 1, 2, 3, 4, 5, 6]} ghost>
              {detail?.map((item, index) => {
                return (
                  <Panel
                    header={
                      <p
                        style={{
                          fontWeight: 600,
                          fontSize: 16,
                          color: colors.black,
                        }}
                      >
                        {item?.question}
                      </p>
                    }
                    key={"points-details-panel" + index}
                  >
                    <p>{item?.answer}</p>
                    {item?.answersList && (
                      <p className="answer-list-intro">
                        {item.answersList.intro}
                      </p>
                    )}
                    {item?.answersList && (
                      <ul className="answer-list-items">
                        {item?.answersList.answers.map(
                          (answerListItem, answerListIndex) => (
                            <li key={`answerListIndex-${answerListIndex}`}>
                              {answerListItem}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </Panel>
                );
              })}
            </Collapse>
            <div
              style={{
                color: colors.black,
                padding: 17,
              }}
            >
              <Title
                level={3}
                style={{
                  fontWeight: 600,
                  fontSize: 16,
                  color: colors.black,
                }}
              >
                A few other points:
              </Title>

              <p>
                Loyalty points have no cash value. To participate in the
                program, the agency you represent must be in good standing with
                Normandy Insurance Company. Normandy reserves the right to make
                changes to the program at any time and to cancel or adjust
                points without notice.
              </p>
            </div>
            <div
              style={{
                fontWeight: 600,
                fontSize: 16,
                color: colors.black,
                padding: 17,
              }}
            >
              We hope this FAQ page has been helpful in answering your questions
              about our Loyalty Points Program. If you have any further
              questions, please don't hesitate to reach out to our customer
              service team.
            </div>
          </div>
        </div>
      </div>
    </AgentLayout>
  );
};

export default PointsDetail;
