import React, { useContext, useState } from "react";
import useSWR from "swr";
import {
  LogoutOutlined,
  UserOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { Button, Layout, Image, Space, Avatar, Dropdown, Modal } from "antd";
import { useNavigate, useLocation } from "react-router";

import UserContext from "../../Contexts/UserContext";
import { colors } from "../utils/colors";
import { useMsal } from "@azure/msal-react";

import "./Layout.css";

import navIcon from "../../../assets/logo.png";
import bellIcon from "../../../assets/icons/bell.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import Footer from "./footer";
import { msalLogoutUsers } from "../../../utils/manageUserData";
import { BASE_URL } from "../../Constants/EnvironmentVariableProvider";
import { RoutesLookup } from "../../Routes/RoutesLookup";
import { GET_ADMINS_BY_ID_API } from "../../API/paths";
import { USER_ROLE } from "../../Constants/ApplicationConstants";
import { getRoleFromAuth } from '../../../utils/commonUtils';

const { Header, Content } = Layout;

const AdminAppLayout = ({ children }) => {
  const location = useLocation();
  const { auth, setAuth } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const { instance: msalInstance } = useMsal();
  const role = getRoleFromAuth(auth)
  const isSuperAdmin = role === USER_ROLE.SUPER_ADMIN

  let activeStatus = 0;
  switch (location.pathname) {
    case "/reward-inventory":
      activeStatus = 0;
      break;
    case "/review-requests":
      isSuperAdmin
        ? (activeStatus = 1)
        : (activeStatus = 0);
      break;
    case "/manage-agent": case "/manage-agent/agent-dashboard":
      activeStatus = 2;
      break;
    case "/category":
      activeStatus = 3;
      break;
    case "/admin":
      activeStatus = 4;
      break;
    case "/report":
      activeStatus = 5;
      break;
    case "/points-form":
      activeStatus = 6;
      break;
    case "/profile":
      activeStatus = -1;
      break;
    default:
      const result = location.pathname.includes("/manage-agent/agent-profile");
      if (result) {
        activeStatus = 2;
        break;
      }
      activeStatus = 0;
  }
  const navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: (
        <p
          onClick={() => {
            navigate(`${RoutesLookup.PROFILE}`);
          }}
        >
          <UserOutlined style={{ marginRight: 8 }} /> Profile
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p onClick={() => setOpen(true)}>
          <LogoutOutlined style={{ marginRight: 8 }} /> Logout
        </p>
      ),
    },
  ];
  const { data } = useSWR({
    url: BASE_URL + GET_ADMINS_BY_ID_API + "?Id=" + auth?.result?.ORAD_KeyID,
  });

  const [status, setStatus] = useState(activeStatus);
  const profileImg = data?.result?.ORAD_Image_Path
    ? data?.result?.ORAD_Image_Path
    : profileIcon;
  const superAdmin = [
    {
      title: "Reward Inventory",
      onClick: () => {
        navigate(RoutesLookup.REWARD_INVENTORY);
      },
    },
    {
      title: "Review Requests",
      onClick: () => {
        navigate(RoutesLookup.REVIEW_REQUESTS);
      },
    },
    {
      title: "Manage Agent",
      onClick: () => {
        navigate(RoutesLookup.MANAGE_AGENT);
      },
    },
    {
      title: "Category",
      onClick: () => {
        navigate(RoutesLookup.CATEGORY);
      },
    },
    {
      title: "Admins",
      onClick: () => {
        navigate(RoutesLookup.ADMIN);
      },
    },
    {
      title: "Report",
      onClick: () => {
        navigate(`${RoutesLookup.REPORT}`);
      },
    },
    {
      title: "Points From",
      onClick: () => {
        navigate(`${RoutesLookup.POINTS_FROM}`);
      },
    },
  ];
  const admin = [
    {
      title: "Review Requests",
      onClick: () => {
        navigate(RoutesLookup.REVIEW_REQUESTS);
      },
    },
  ];
  const result = isSuperAdmin ? superAdmin : admin;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          padding: 0,
          background: colors.white,
          borderBottom: "1px solid #CFD9E0",
        }}
      >
        <div style={{ float: "left" }}>
          <Space
            direction="horizontal"
            style={{
              paddingLeft: 32,
              paddingRight: 32,
            }}
          >
            <Button
              type="link"
              onClick={() => {
                navigate(RoutesLookup.REWARD_INVENTORY);
                setStatus(0);
              }}
            >
              <Image
                style={{ marginBottom: 10 }}
                height={26}
                width={176}
                preview={false}
                src={navIcon}
              />
            </Button>
          </Space>
        </div>
        <Modal
          open={open}
          centered
          closable={false}
          footer={null}
          onOk={() => {
            msalLogoutUsers(msalInstance, auth, setAuth, setOpen, navigate);
          }}
          onCancel={() => setOpen(false)}
        >
          <div style={{ padding: 20 }}>
            <div style={{ display: "flex" }}>
              <ExclamationCircleFilled
                style={{ fontSize: 20, color: "orange" }}
              />
              <p style={{ fontSize: 16, fontWeight: 600, marginLeft: 8 }}>
                {" "}
                Logout
              </p>
            </div>
            <p style={{ fontSize: 14, fontWeight: 400, marginLeft: 25 }}>
              {" "}
              Are you sure to logout?
            </p>
            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button onClick={() => setOpen(false)} style={{ marginRight: 8 }}>
                No
              </Button>
              <Button
                onClick={() => {
                  msalLogoutUsers(
                    msalInstance,
                    auth,
                    setAuth,
                    setOpen,
                    navigate
                  );
                }}
                type="primary"
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        <Space direction="horizontal">
          {result?.map((item, index) => (
            <div className={status === index ? "border-botm" : ""}>
              <Button
                className={status === index ? "active" : "inactive"}
                onClick={() => item.onClick()}
                key={item.title}
                type="link"
                style={{
                  fontSize: 14,
                  fontWight: 500,
                }}
              >
                {item.title}
              </Button>
            </div>
          ))}
        </Space>
        <div style={{ float: "right" }}>
          <Space
            direction="horizontal"
            style={{
              paddingRight: 24,
            }}
          >
            <Image preview={false} src={bellIcon} alt="icon" />
            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow
            >
              <Avatar
                src={profileImg}
                style={{
                  backgroundColor: colors.purple500,
                  verticalAlign: "middle",
                  cursor: "pointer",
                  border: `2px solid ${activeStatus === -1 ? colors.primaryGreen : colors.white
                    }`,
                }}
                size="large"
              />
            </Dropdown>
          </Space>
        </div>
      </Header>
      <Content
        style={{
          backgroundColor: "#FFFFFF",
        }}
      >
        {children}
      </Content>
      <div>
        <Footer></Footer>
      </div>
    </Layout>
  );
};

export default AdminAppLayout;
