import React, { useContext } from 'react';
import {
    Route,
    useLocation,
    Navigate,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import UserContext from "../Contexts/UserContext";
import { getRouteByRole } from './routes';
import { getRoleFromAuth } from '../../utils/commonUtils';
import getDefaultLoginRoutesForRole from './DefaultLoginRoutesForRole';

function ApplicationRoutes(auth) {
    const role = getRoleFromAuth(auth)
    const defaultRoute = getDefaultLoginRoutesForRole(role)
    const routeByRole = getRouteByRole(role)

    function RequireAuth({ children }) {
        const { auth: authInfo } = useContext(UserContext);
        let location = useLocation();
        if (authInfo?.token) {
            return <>{children}</>;
        }
        return <Navigate to={defaultRoute} state={{ from: location }} replace />;
    }

    const router = createBrowserRouter(createRoutesFromElements(
        routeByRole.map(
            (route) => (
                <Route
                    path={route.path}
                    element={!route.requiredAuth ? route.element :
                        <RequireAuth>
                            {route.element}
                        </RequireAuth>
                    }
                />
            ))
    ))
    return router
}

export default ApplicationRoutes