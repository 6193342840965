import { theme, Image, Button, Typography, Spin, message } from "antd";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import logoImg from "../../../assets/confetti.jpg";
import logo from "../../../assets/logo.png"
import { useMsal } from "@azure/msal-react";
import { isMobile } from "react-device-detect";
import UserContext from '../../Contexts/UserContext';
import { colors } from '../../AdminPortal/utils/colors';
import getDefaultLoginRoutesForRole from '../../Routes/DefaultLoginRoutesForRole';
import { getRoleFromAuth } from '../../../utils/commonUtils';
import debounce from 'lodash/debounce';
import { EventType } from '@azure/msal-browser';
import { LoginByRole, doClear } from '../../../utils/manageUserData';
import { USER_TYPES } from '../../Constants/ApplicationConstants';
import { ADB2C_WORKFLOW_AUTHORITY_URI, ADB2C_WORKFLOW_FORGOT_PASSWORD, ADB2C_AGENT_LOGOUT_REDIRECT_URL, ADB2C_ADMIN_LOGOUT_REDIRECT_URL } from '../../Constants/EnvironmentVariableProvider';
import { RoutesLookup } from '../../Routes/RoutesLookup';

const { Title } = Typography;

const Login = () => {
    const userType = window.location.pathname === RoutesLookup.ADMIN_LOGIN ? USER_TYPES.ADMIN : USER_TYPES.AGENT

    const { setAuth, auth } = useContext(UserContext);
    const { instance: msalInstance } = useMsal();
    const [isPageLoading, setPageLoading] = useState(false);
    const navigate = useNavigate();
    const { token } = theme.useToken();
    const colorBgLoginLayout = token['colorBgLoginLayout']


    const addEventCallback = () => {
        msalInstance.addEventCallback((event) => {
            if (event.eventType === EventType.LOGIN_SUCCESS &&
                (event.payload && event.payload['idToken'])) {
                const account = event.payload['account']
                localStorage.setItem('account', JSON.stringify(account))
                msalInstance.setActiveAccount(account)
                LoginByRole(
                    event.payload['idToken'],
                    setAuth,
                    navigate,
                    msalInstance,
                    userType
                );
            } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
                setAuth({})
            }
            else if (
                event.eventType === EventType.LOGIN_FAILURE &&
                event.error &&
                event.error['errorCode'] === "access_denied" &&
                event.error['errorMessage'].includes("AADB2C90118")
            ) {
                const passwordResetAuthority = ADB2C_WORKFLOW_AUTHORITY_URI + ADB2C_WORKFLOW_FORGOT_PASSWORD;
                let logoutRedirectUri = ADB2C_AGENT_LOGOUT_REDIRECT_URL
                if (userType === USER_TYPES.ADMIN) {
                    logoutRedirectUri = ADB2C_ADMIN_LOGOUT_REDIRECT_URL
                } else if (userType === USER_TYPES.AGENT) {
                    logoutRedirectUri = ADB2C_AGENT_LOGOUT_REDIRECT_URL
                }
                msalInstance.loginRedirect({
                    scopes: [],
                    authority: passwordResetAuthority,
                    redirectUri: logoutRedirectUri,
                });
            } else if (
                event.eventType === EventType.LOGIN_FAILURE ||
                (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE &&
                    (event.error && event.error['errorCode'] === "user_cancelled"))
            ) {
                loginCancelled();
            }
        });
    }

    const loginCancelled = debounce(function () {
        msalInstance.logout();
    }, 1500);

    useEffect(() => {
        if (auth.token) {
            navigate(getDefaultLoginRoutesForRole(getRoleFromAuth(auth)))
        } else {
            doClear(msalInstance);
        }
        addEventCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tryLogin = () => {
        setPageLoading(true);
        message.info("Please wait while we are redirecting you to login.");
        msalInstance.handleRedirectPromise().then(() => {
            msalInstance.loginRedirect()
        })
    };
    const handleSignInClick = () => {
        tryLogin();
    }
    return (
        <Spin spinning={isPageLoading}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    verticalAlign: "middle",
                    alignItems: "center",
                }}
            >
                <div
                    style={{
                        background: colorBgLoginLayout,
                        width: "55%",
                        height: "100vh",
                        justifyContent: "center",
                        alignItems: "center",
                        display: isMobile ? "none" : "flex",
                    }}
                >
                    <div>
                        <Image style={{ height: 400 }} preview={false} src={logoImg} />
                        <Title
                            style={{ textAlign: "center", color: colors.black, fontSize: 30 }}
                        >
                            Woohoo!
                        </Title>
                        <Title
                            level={4}
                            style={{ textAlign: "center", color: colors.black, margin: 0 }}
                        >
                            You have points...
                        </Title>
                        <Title
                            level={4}
                            style={{ textAlign: "center", color: colors.black, margin: 0 }}
                        >
                            Go pick your prize!
                        </Title>
                    </div>
                </div>
                <div
                    style={{
                        width: isMobile ? "100%" : "45%",
                        height: "100vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <div style={{ width: 350 }}>
                        <div
                            style={{ textAlign: "center", position: "relative", top: -30 }}
                        >
                            <Image
                                src={logo}
                                preview={false}
                                style={{ marginBottom: 20, objectFit: "cover" }}
                            />
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Title
                                level={3}
                                style={{
                                    marginBottom: 15,
                                    marginTop: 0,
                                    color: colors.grey900,
                                    textAlign: "center",
                                }}
                            >
                                Welcome to Normandy Loyalty Rewards
                            </Title>
                        </div>
                        <Button block type="primary" onClick={handleSignInClick}>
                            Sign-in
                        </Button>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default Login;
