import React, { useContext, useEffect } from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router";
import UserContext from '../../Contexts/UserContext';
import { getRoleFromAuth, getUserType } from '../../../utils/commonUtils';
import { RoutesLookup } from '../../Routes/RoutesLookup';
import { clearAuth, clearMsal, clearStorages } from '../../../utils/manageUserData';
import { useMsal } from '@azure/msal-react';
import { clearAxiosAuth } from '../../API/axios.Config';

function Session() {
  const { instance: msalInstance, inProgress } = useMsal();
  const { auth, setAuth } = useContext(UserContext);
  const role = getRoleFromAuth(auth)
  const userType = getUserType(role)
  const navigate = useNavigate();

  useEffect(() => {
    if (inProgress === 'none') {
      const account = msalInstance.getActiveAccount()
      clearMsal(msalInstance);
      clearStorages();
      clearAxiosAuth()
      msalInstance.logout({
        account
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inProgress])

  return (
    <>
      <Result style={{ marginTop: 100 }} title="Session Expire" />
      <div className="flex justify-center items-center flex-col w-full">
        <p className=" ">
          Your session has expired. Please log in again to continue.
        </p>
        <Button
          type="primary"
          style={{
            width: "299px",
            marginTop: "20px",
          }}
          onClick={() => {
            clearAuth(setAuth);
            clearMsal(msalInstance);
            clearStorages();
            clearAxiosAuth()
            navigate(`/${userType}${RoutesLookup.LOGIN}`);
          }}
        >
          Go to Login
        </Button>
      </div>
    </>
  );
}

export default Session;
