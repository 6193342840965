import React from 'react';
import { RoutesLookup } from './RoutesLookup';
import { Navigate } from "react-router-dom";
import RewardInventory from '../AdminPortal/features/RewardInventory'
import AddNewReward from '../AdminPortal/features/RewardInventory/AddNewReward';
import ReviewRequests from '../AdminPortal/features/ReviewRequests';
import Category from '../AdminPortal/features/Category';
import Reports from '../AdminPortal/features/Reports';
import Admin from '../AdminPortal/features/Admin';
import PointsFrom from '../AdminPortal/features/PointsFrom';
import ManageAgent from '../AdminPortal/features/ManageAgent';
import Profile from '../AdminPortal/features/Profile';
import AgentProfile from '../AdminPortal/features/ManageAgent/agentProfile';
import AgentFavorite from '../AgentPortal/features/AgentFav';
import Logout from '../Pages/Common/Logout';
import Session from '../Pages/Common/Session'
import PageNotFound from '../AdminPortal/features/PageNotFound';
import PointsDetail from "../AgentPortal/features/PointsDetails";
import AgentDetail from "../AgentPortal/features/AgentProfile";
import AgentCart from "../AgentPortal/features/AgentCart";
import Dashboard from "../AgentPortal/features/AgentDashboard";
import AgentForgotPassword from "../AgentPortal/features/AgentLogin/forgotPassword";
import AgentVerificationCode from "../AgentPortal/features/AgentLogin/verificationCode";
import AgentSetNewPassword from "../AgentPortal/features/AgentLogin/setNewPassword";
import { USER_ROLE } from '../Constants/ApplicationConstants';
import Login from '../Pages/Common/Login';
import BasePageResolver from './BasePageResolver';
import LoginRedirect from '../Pages/Common/LoginRedirect';

const ROUTE_ALLOW_POLICY = {
  ALLOW_ONLY: 'AllowOnly',
  ALLOW_ONLY_AND_UN_AUTH: 'AllowOnlyAndUnAuth',
  ALLOW_ONLY_UN_AUTH: 'AllowUnAuthOnly',
  ALLOW_COMPLEMENT: 'AllowComplement',
  ALLOW_ANY: 'AllowAny'
}

const routes = [
  {
    path: RoutesLookup.BASE,
    element: <BasePageResolver />,
  },
  {
    path: RoutesLookup.ADMIN_LOGIN,
    element: <Login />,
  },
  {
    path: RoutesLookup.AGENT_LOGIN,
    element: <Login />,
  },
  {
    path: RoutesLookup.LOGIN_REDIRECT,
    element: <LoginRedirect />
  },
  {
    path: RoutesLookup.REWARD_INVENTORY,
    element: <RewardInventory />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: `${RoutesLookup.REWARD_INVENTORY}/:id`,
    element: <AddNewReward />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: RoutesLookup.CATEGORY,
    element: <Category />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: RoutesLookup.REPORT,
    element: <Reports />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: RoutesLookup.ADMIN,
    element: <Admin />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: `${RoutesLookup.AGENT}/:id`,
    element: <Navigate to={RoutesLookup.REWARD_INVENTORY} replace />,
    role: [USER_ROLE.SUPER_ADMIN],
  },
  {
    path: RoutesLookup.POINTS_FROM,
    element: <PointsFrom />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: RoutesLookup.MANAGE_AGENT,
    element: <ManageAgent />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: `${RoutesLookup.MANAGE_AGENT}${RoutesLookup.AGENT_PROFILE}/:id`,
    element: <AgentProfile />,
    role: [USER_ROLE.SUPER_ADMIN],
    requiredAuth: true
  },
  {
    path: RoutesLookup.AGENT_FAVORITE,
    element: <AgentFavorite />,
    role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.AGENT],
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_AND_UN_AUTH,
    requiredAuth: true
  },
  {
    path: RoutesLookup.LOGOUT,
    element: <Logout />
  },
  {
    path: RoutesLookup.SESSION_TIMEOUT,
    element: <Session />,
  },
  {
    path: RoutesLookup.NOT_FOUND,
    element: <PageNotFound />,
  },
  {
    path: RoutesLookup.ANY,
    element: <Navigate to={RoutesLookup.NOT_FOUND} replace />,
  },
  {
    path: `${RoutesLookup.ADMIN}/:id`,
    element: <Navigate to={RoutesLookup.AGENT_DASHBOARD} replace />,
    role: [USER_ROLE.AGENT]
  },
  {
    path: RoutesLookup.REVIEW_REQUESTS,
    element: <ReviewRequests />,
    role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
    requiredAuth: true,
  },
  {
    path: RoutesLookup.PROFILE,
    element: <Profile />,
    role: [USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN],
    requiredAuth: true,
  },
  {
    path: RoutesLookup.FORGOT_PASSWORD,
    element: <AgentForgotPassword />,
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_UN_AUTH
  },
  {
    path: `/${RoutesLookup.VERIFICATION_CODE}/:email`,
    element: <AgentVerificationCode />,
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_UN_AUTH
  },
  {
    path: `/${RoutesLookup.NEW_PASSWORD}/:email`,
    element: <AgentSetNewPassword />,
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_UN_AUTH
  },
  {
    path: RoutesLookup.AGENT_DASHBOARD,
    element: <Dashboard />,
    role: [USER_ROLE.AGENT],
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_AND_UN_AUTH,
    requiredAuth: true
  },
  {
    path: RoutesLookup.AGENT_DETAIL,
    element: <AgentDetail />,
    role: [USER_ROLE.AGENT],
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_AND_UN_AUTH,
    requiredAuth: true
  },
  {
    path: RoutesLookup.AGENT_CART,
    element: <AgentCart />,
    role: [USER_ROLE.AGENT],
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_AND_UN_AUTH,
    requiredAuth: true
  },
  {
    path: RoutesLookup.POINTS_DETAIL,
    element: <PointsDetail />,
    role: [USER_ROLE.AGENT],
    allowPolicy: ROUTE_ALLOW_POLICY.ALLOW_ONLY_AND_UN_AUTH,
    requiredAuth: true
  }
]

const allowDefaultNoRole = true
export function getRouteByRole(role) {
  return routes.filter(
    (route) => {
      switch (route.allowPolicy) {
        case ROUTE_ALLOW_POLICY.ALLOW_ONLY:
          return route.role?.includes(role) ?? false
        case ROUTE_ALLOW_POLICY.ALLOW_ONLY_AND_UN_AUTH:
          return role === undefined || role === null || route.role?.includes(role)
        case ROUTE_ALLOW_POLICY.ALLOW_ONLY_UN_AUTH:
          return role === undefined || role === null
        case ROUTE_ALLOW_POLICY.ALLOW_COMPLEMENT:
          return !(route.role?.includes(role) ?? false)
        case ROUTE_ALLOW_POLICY.ALLOW_ANY:
          return true
        default:
          return route.role?.includes(role) ?? allowDefaultNoRole
      }
    }
  )
}

export default routes