import React from "react";
import { Card, Typography, Avatar, Image, Button, Tooltip } from "antd";
import NoImageAvailable from '../../../../assets/reqest.png'
import { colors } from "../../../AdminPortal/utils/colors";

import { HeartOutlined, HeartFilled } from "@ant-design/icons";

const { Meta } = Card;
const { Text } = Typography;

const ItemCard = ({
  ModalContoller,
  quantity,
  addFav,
  src,
  productName,
  category_title,
  index,
  norm_points,
  rewardId,
  favorite,
  Agent_Redeemed_Quantity,
  ARLR_Redeemable_Quantity,
}) => {
  return (
    <div>
      <Card
        bordered={true}
        style={{
          marginTop: 16,
          marginBottom: 32,
          background:
            quantity < 1 ||
              (ARLR_Redeemable_Quantity &&
                Agent_Redeemed_Quantity >= ARLR_Redeemable_Quantity)
              ? colors.bgGrey
              : colors.white,
          cursor: "pointer",
        }}
        key={index}
        bodyStyle={{
          padding: "16px",
        }}
        cover={
          <div>
            <div style={{ height: 240, background: colors.white }}>
              <Image
                style={{ objectFit: "contain" }}
                width={"100%"}
                preview={false}
                height={240}
                onClick={() =>
                  ModalContoller(
                    rewardId,
                    quantity,
                    Agent_Redeemed_Quantity,
                    ARLR_Redeemable_Quantity
                  )
                }
                className="card-cover-image"
                src={src?.length ? src : NoImageAvailable}
                alt="example"
              />
            </div>
            <div
              style={{
                padding: 1,
                position: "absolute",
                top: 16,
                right: 16,
                zIndex: 1000,
              }}
            >
              <Avatar
                style={{ cursor: "pointer", backgroundColor: "white" }}
                onClick={() => addFav(rewardId, favorite)}
                size={34}
                icon={
                  favorite ? (
                    <HeartFilled
                      style={{ color: colors.red, fill: colors.red }}
                    />
                  ) : (
                    <HeartOutlined style={{ color: colors.primaryBlue }} />
                  )
                }
              />
            </div>
            <div className="redeem-button-container">
              <Button
                type="text"
                onClick={() =>
                  ModalContoller(
                    rewardId,
                    quantity,
                    Agent_Redeemed_Quantity,
                    ARLR_Redeemable_Quantity
                  )
                }
                style={{
                  color: colors.primaryBlue,
                  background: colors.white,
                }}
              >
                Redeem Gift
              </Button>
            </div>
          </div>
        }
      >
        <Meta
          title={
            <Tooltip title={productName}>
              <p
                style={{
                  color:
                    quantity < 1 ||
                      (ARLR_Redeemable_Quantity &&
                        Agent_Redeemed_Quantity >= ARLR_Redeemable_Quantity)
                      ? colors.cardTextColor
                      : colors.black,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whitespace: "nowrap",
                  maxWidth: 300,
                }}
              >
                {productName}
              </p>
            </Tooltip>
          }
          description={
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "baseline",
                  paddingBottom: "6px",
                }}
              >
                <Text
                  style={{
                    color:
                      quantity < 1 ||
                        (ARLR_Redeemable_Quantity &&
                          Agent_Redeemed_Quantity >= ARLR_Redeemable_Quantity)
                        ? colors.cardTextColor
                        : colors.black,
                    fontWeight: 600,
                    fontSize: 22,
                    paddingRight: 8,
                  }}
                >
                  {norm_points}
                </Text>
                <Text>pts</Text>
              </div>
              <div>
                <Text
                  style={{
                    backgroundColor:
                      quantity < 1 ||
                        (ARLR_Redeemable_Quantity &&
                          Agent_Redeemed_Quantity >= ARLR_Redeemable_Quantity)
                        ? colors.white
                        : colors.grey800,
                    padding: "2px  4px",
                    borderRadius: 7,
                    fontWeight: 500,
                    color:
                      quantity < 1 ||
                        (ARLR_Redeemable_Quantity &&
                          Agent_Redeemed_Quantity >= ARLR_Redeemable_Quantity)
                        ? colors.cardTextColor
                        : colors.primaryBlue,
                  }}
                >
                  {category_title}
                </Text>
              </div>
            </div>
          }
        />
      </Card>
    </div>
  );
};

export default ItemCard;
