import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Spin,
  Select,
  Typography,
  Space,
} from "antd";
import axios from "axios";
import React, { useEffect } from "react";

import useForceUpdate from "../../hooks/useForceUpdate";
import useLoading from "../../hooks/useLoading";
import { colors } from "../../utils/colors";

import { BASE_URL } from '../../../Constants/EnvironmentVariableProvider';
import { GET_ADMINS_BY_ID_API } from '../../../API/paths';

const { Option } = Select;
const { Title } = Typography;

const AdminForm = ({
  id,
  form,
  handleSubmit,
  closeModal,
  listLoading,
  isEdit,
}) => {
  const forceUpdate = useForceUpdate();
  const [fetchAdmin, loading] = useLoading(async () => {
    form.resetFields();
    try {
      const response = await axios.get(
        `${BASE_URL}${GET_ADMINS_BY_ID_API}?Id=${id}`
      );
      const data = response?.data?.result;
      if (data) {
        form.setFieldsValue({
          ORAD_Email: data?.ORAD_Email,
          ORAD_Is_Active: data?.ORAD_Is_Active,
          ORAD_Name: data?.ORAD_Name,
          ORAD_Phone: data?.ORAD_Phone,
          ORAD_RoleName: data?.ORAD_RoleName,
          /* ORAD_Password: data?.ORAD_Password, */
        });
        forceUpdate();
      }
    } catch (e) {
      console.log(e);
    }
  });

  useEffect(() => {
    if (id !== "create") {
      fetchAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  return (
    <div style={{ padding: 35 }}>
      <Title level={3} style={{ color: colors.black }}>
        {id === "create" ? "Add New Admin" : "Edit Admin"}
      </Title>
      <Spin spinning={listLoading || loading}>
        <Form
          layout="vertical"
          form={form}
          style={{ marginTop: 30 }}
          onFinish={handleSubmit}
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="Admin Name"
                name="ORAD_Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input type="textarea" />
              </Form.Item>
            </Col>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="Admin Phone"
                name="ORAD_Phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone",
                  },
                ]}
              >
                <Input type="number" min={0} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="Admin Email"
                name="ORAD_Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                    type: "email",
                  },
                ]}
              >
                <Input type="textarea" />
              </Form.Item>
            </Col>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="Role"
                name="ORAD_RoleName"
                rules={[{ required: true, message: "Please enter a role" }]}
              >
                <Select placeholder="Admin" size="middle">
                  <Option value="Admin">Admin</Option>
                  <Option value="SuperAdmin">SuperAdmin</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col style={{ display: "none" }} span={12} className="gutter-row">
              <Form.Item
                label="Admin Password"
                name="ORAD_Password"
                rules={[
                  {
                    message: "Please enter password",
                  },
                  {
                    min: 4,
                    message: "Password should contain at least 4 characters.",
                  },
                ]}
              >
                <Input.Password type="password" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} className="gutter-row">
              <Form.Item
                label="Status"
                name="ORAD_Is_Active"
                rules={[{ required: true, message: "Please select a status" }]}
              >
                <Select placeholder="Active" size="middle">
                  <Option value={true}>Active</Option>
                  <Option value={false}>Inactive</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            style={{ textAlign: "right", marginTop: 10, marginBottom: 0 }}
          >
            <Space direction="horizontal" gap={2}>
              <Button
                onClick={() => {
                  closeModal();
                  form.resetFields();
                }}
                htmlType="button"
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                {id === "create" ? "Save" : "Update"}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default AdminForm;
