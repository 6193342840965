import { LogLevel } from "@azure/msal-browser";

import {
  ADB2C_WORKFLOW_AUTHORITY_URI,
  ADB2C_WORKFLOW_FORGOT_PASSWORD,
  ADB2C_AUTHORITY_DOMAIN,
  ADB2C_CLIENT_ID,
  ADB2C_CLIENT_SECRET,
  // ADB2C_SCOPES,
  ADB2C_WORKFLOW_SIGN_UP_SIGN_IN_AGENT,
  AD_CLIENT_ID,
  AD_AUTHORITY,
  ADB2C_AGENT_LOGOUT_REDIRECT_URL,
  ADB2C_ADMIN_LOGOUT_REDIRECT_URL,
  REACT_APP_ADB2C_GENERAL_LOGIN_REDIRECT,
} from './App/Constants/EnvironmentVariableProvider';
import { USER_TYPES } from './App/Constants/ApplicationConstants';

export const getMsalConfig = (userType) => {
  if (userType === USER_TYPES.AGENT) {
    const b2cPolicies = {
      authorities: {
        signUpSignIn: {
          authority:
            ADB2C_WORKFLOW_AUTHORITY_URI + ADB2C_WORKFLOW_SIGN_UP_SIGN_IN_AGENT,
        },
        forgotPassword: {
          authority:
            ADB2C_WORKFLOW_AUTHORITY_URI + ADB2C_WORKFLOW_FORGOT_PASSWORD,
        },
      },
      authorityDomain: ADB2C_AUTHORITY_DOMAIN,
    };
    b2cPolicies.authorities.signUpSignIn.authority =
      ADB2C_WORKFLOW_AUTHORITY_URI + ADB2C_WORKFLOW_SIGN_UP_SIGN_IN_AGENT;
    const msalConfig = {
      auth: {
        clientId: ADB2C_CLIENT_ID,
        clientSecret: ADB2C_CLIENT_SECRET,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        validateAuthority: true,
        postLogoutRedirectUri: ADB2C_AGENT_LOGOUT_REDIRECT_URL,
        navigateToLoginRequestUrl: false,
        redirectUri: REACT_APP_ADB2C_GENERAL_LOGIN_REDIRECT,
      },
      cache: {
        cacheLocation: "sessionStorage",
        temporaryCacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        secureCookies: false,
        claimsBasedCachingEnabled: true,
      },
      system: {
        pollIntervalMilliseconds: 1,
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
        asyncPopups: false,
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case LogLevel.Error:
                console.error(message);
                return;
              case LogLevel.Info:
                console.info(message);
                return;
              case LogLevel.Verbose:
                console.debug(message);
                return;
              case LogLevel.Warning:
                console.warn(message);
                return;
              default:
                return;
            }
          },
        },
      },
    };
    return msalConfig;
  } else {
    const msalConfig = {
      auth: {
        clientId: AD_CLIENT_ID,
        authority: AD_AUTHORITY,
        postLogoutRedirectUri: ADB2C_ADMIN_LOGOUT_REDIRECT_URL,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
    };

    return msalConfig;
  }
};
