/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownOutlined } from "@ant-design/icons";
import { Radio, Divider, Dropdown, Space, theme, Slider, Button } from "antd";
import React from "react";
import "../../../../App.css";
import './index.css';
import { colors } from "../../../AdminPortal/utils/colors";
import { isMobile } from "react-device-detect";
const { useToken } = theme;
const DropDownComp = ({ value, setValue, setSliderValue }) => {
  const { token } = useToken();

  const onChange = (e) => {
    if (e.target.value === 'Custom Range') {
      setValue(e.target.value);
      return
    }
    setValue(e.target.value);
  };
  const sliderValue = (e) => {
    if (value !== 'Custom Range') {
      return
    }
    const sliderVal = `${e[0]}-${e[1]}`
    setSliderValue(sliderVal);

  };
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    width: 404,
    height: 360,
  };

  return (
    <div className="custom-drop">
      <Dropdown
        dropdownRender={(menu) => (
          <div style={contentStyle}>
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                <Radio className="drop-down-style" value="All">
                  All
                </Radio>
                <Divider style={{ marginTop: 9, marginBottom: 0 }} />
                <Radio className="drop-down-style-second" value="0-1000">
                  0 - 1000pt
                </Radio>
                <Divider style={{ marginTop: 9, marginBottom: 0 }} />
                <Radio
                  className="drop-down-style-second"
                  value="1000-2000"
                >
                  1000 - 2000 pt
                </Radio>
                <Divider style={{ marginTop: 9, marginBottom: 0 }} />{" "}
                <Radio
                  className="drop-down-style-second"
                  value="2000-3000 "
                >
                  2000 - 3000 pt
                </Radio>
                <Divider style={{ marginTop: 9, marginBottom: 0 }} />
                <div style={{ backgroundColor: value === 'Custom Range' ? colors.white : colors.bgGrey, paddingBottom: 36 }}>
                  <Radio className="drop-down-style-second" value='Custom Range' >
                    Add Custom Range
                  </Radio>
                  <Slider
                    included
                    min={0}
                    max={10000}
                    onChange={sliderValue}
                    disabled={value !== 'Custom Range'}
                    className="slider-main-div"
                    trackStyle={{ backgroundColor: "#213E7C", height: 7 }}
                    range
                  />
                </div>
              </Space>
            </Radio.Group>
          </div>
        )}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Button style={{ height: 38, minWidth: isMobile ? "100%" : 163 }} type="primary" size="large">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img alt="normandy" src="/tick.svg" />{" "}
                <div style={{ marginLeft: 13 }}>{value}</div>{" "}
              </div>
              <div style={{ marginLeft: 5 }}>
                <DownOutlined />
              </div>
            </div>
          </Button>
        </a>
      </Dropdown>
    </div>
  );
};
export default DropDownComp;
