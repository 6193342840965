export const RoutesLookup = {
    BASE: "/",
    LOGIN: "/login",
    LOGOUT: "/logout",
    SESSION_TIMEOUT: "/session-timeout",
    NOT_FOUND: "/404",
    ANY: "*",
    LOGIN_REDIRECT: "/login-redirect",
    // Admin routes
    ADMIN_LOGIN: "/admin/login",
    REWARD_INVENTORY: "/reward-inventory",
    REVIEW_REQUESTS: "/review-requests",
    CATEGORY: "/category",
    REPORT: "/report",
    ADMIN: "/admin",
    MANAGE_AGENT: "/manage-agent",
    PROFILE: "/profile",
    AGENT_PROFILE: "/agent-profile",
    POINTS_FROM: "/points-form",
    // Agent routes
    AGENT: "/agent",
    AGENT_LOGIN: "/agent/login",
    AGENT_DASHBOARD: "/agent-dashboard",
    AGENT_DETAIL: "/agent-detail",
    AGENT_CART: "/agent-cart",
    AGENT_FAVORITE: "/agent-favorite",
    POINTS_DETAIL: "/agent-dashboard/points-detail",
    EMAIL_CODE: "Agents/EmailCode",
    FORGOT_PASSWORD: "/agent/forgotPassword",
    VALIDATE_CODE: "Agents/ValidateCode",
    VERIFICATION_CODE: "agent/verificationCode",
    NEW_PASSWORD: "Agent/newPassword",
    UPDATE_PASSWORD: "Agents/UpdatePassword",
};