import React, { useEffect } from "react";
import {
  Space,
  Typography,
  Button,
  Drawer,
  Image,
  Spin,
  Select,
  Row,
  Col,
} from "antd";
import axios from "axios";

import RewardForRedeemConfirmationCard from "../../components/RewardForRedeemConfirmationCard/RewardForRedeemConfirmationCard";
import { colors } from "../../utils/colors";
import useLoading from "../../hooks/useLoading";
import placeHolder from "../../../../assets/place_holder.jpg";
import { formatDate } from "../../utils/helpers";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import VectorImage from "../../../../assets/icons/Vector.svg";

import { BASE_URL } from "../../../Constants/EnvironmentVariableProvider";
import { AGENT_DETAIL_API } from "../../../API/paths";

const { Text } = Typography;

const SideDrawer = ({
  requests,
  id,
  open,
  onClose,
  onCancelRequest,
  onAcceptRequest,
  agentRequest,
  setAgentRequest,
  onAcceptAllRequest,
  redeemCardLoading,
  setRedeemCardLoading,
  setDisableStatus,
  disableStatus,
  orderStatusHandler,
  orderStatusVal,
  onHoldAllRequest,
  onHold,
  setHoldButtonStatus,
  holdButtonStatus,
  hold,
}) => {
  const agent = requests?.result?.find((item) => item.ARAG_KeyID === id);
  const [fetchAgentDetail, loading] = useLoading(async () => {
    try {
      setRedeemCardLoading(true);
      const response = await axios.get(
        `${BASE_URL}${AGENT_DETAIL_API}?KeyId=${id}`
      );
      if (response?.data) {
        setAgentRequest(response?.data?.result);
        const result = response?.data?.result?.filter(
          (item) =>
            item?.ARIT_Approved_Status === 2 || item?.ARIT_Approved_Status === 3
        );
        if (result?.length === response?.data?.result?.length) {
          setDisableStatus(true);
        } else {
          setDisableStatus(false);
        }
        const holdResult = response?.data?.result?.filter(
          (item) =>
            item?.ARIT_Approved_Status === 5 ||
            item?.ARIT_Approved_Status === 2 ||
            item?.ARIT_Approved_Status === 3
        );
        if (holdResult?.length === response?.data?.result?.length) {
          setHoldButtonStatus(true);
        } else {
          setHoldButtonStatus(false);
        }
      }
      setRedeemCardLoading(false);
    } catch (e) {
      console.log(e);
      setRedeemCardLoading(false);
    }
  });

  const DrawerHeader = ({ name, email, imgUrl }) => {
    return (
      <div style={{ borderBottom: `1px solid #E2E8F0`, paddingBottom: 20 }}>
        <Space>
          <Image
            width={40}
            height={40}
            style={{ borderRadius: "100%" }}
            preview={false}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = placeHolder;
            }}
            src={imgUrl}
          />
          <div style={{ marginLeft: 16 }}>
            <p style={{ fontSize: 14, fontWeight: 500, color: colors.black }}>
              {name}
            </p>
            <p style={{ fontSize: 14, fontWeight: 400 }}>{email}</p>
          </div>
        </Space>
      </div>
    );
  };

  const DrawerFooter = () => {
    return (
      <div style={{ marginBottom: 28, marginTop: 28 }}>
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row" span={8}>
            <Button
              disabled={disableStatus}
              icon={<CloseOutlined />}
              style={{
                backgroundColor: !disableStatus
                  ? "#FECACA"
                  : "rgba(0, 0, 0, 0.04)",
                color: !disableStatus ? colors.black : "rgba(0, 0, 0, 0.25)",
                height: 35,
              }}
              onClick={() => onCancelRequest("cancelAll")}
              block
            >
              Cancel all
            </Button>
          </Col>
          <Col className="gutter-row" span={8}>
            <Button
              disabled={holdButtonStatus}
              icon={
                <Image
                  style={{
                    padding: 8,
                  }}
                  preview={false}
                  src={VectorImage}
                ></Image>
              }
              style={{
                backgroundColor: !holdButtonStatus
                  ? "#EAEFF6"
                  : "rgba(0, 0, 0, 0.04)",
                color: !holdButtonStatus ? colors.black : "rgba(0, 0, 0, 0.25)",
                height: 35,
              }}
              onClick={onHoldAllRequest}
              block
            >
              On hold all
            </Button>
          </Col>
          <Col className="gutter-row" span={8}>
            <Button
              disabled={disableStatus}
              onClick={onAcceptAllRequest}
              icon={<CheckOutlined />}
              type="primary"
              block
            >
              Shipped all
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  useEffect(() => {
    if (id) {
      fetchAgentDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Drawer
      footerStyle={{ paddingLeft: 24, paddingRight: 24 }}
      bodyStyle={{ paddingLeft: 24, paddingRight: 24, paddingTop: 0 }}
      style={{ background: colors.bgGrey, zIndex: 0 }}
      footer={<DrawerFooter />}
      mask={false}
      width={546}
      headerStyle={{ borderBottom: "none", marginTop: 16 }}
      rootClassName={"drawer"}
      placement="right"
      title={
        <DrawerHeader
          name={agent?.tbl_ACAG_Agents?.ACAG_Name}
          email={agent?.tbl_ACAG_Agents?.ACAG_Email}
          imgUrl={agent?.tbl_ACAG_Agents?.ACAG_Image_Path}
        />
      }
      onClose={onClose}
      open={open}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text style={{ fontWeight: 600 }}>Order Status</Text>
        <Select
          defaultValue={"Select Order Status"}
          value={orderStatusVal}
          onChange={orderStatusHandler}
          options={[
            {
              value: "3",
              label: "Shipped",
            },
            {
              value: "2",
              label: "Partially Shipped",
            },
            {
              value: "1",
              label: "Ordered",
            },
            {
              value: "4",
              label: "Cancelled",
            },
          ]}
        />
      </div>
      <Space direction="vertical">
        <Text
          style={{
            color: colors.black,
            fontSize: 14,
            fontWeight: 500,
            marginTop: 28,
            marginBottom: 8,
          }}
        >
          Rewards for Redeem:
        </Text>
      </Space>
      <div className="hide-spinner-body">
        <Spin spinning={loading || redeemCardLoading}>
          {agentRequest?.map((CardData, index) => (
            <RewardForRedeemConfirmationCard
              id={CardData?.ARIT_KeyID}
              onCancel={onCancelRequest}
              onAccept={onAcceptRequest}
              ARIT_Cancel_Reason={CardData?.ARIT_Cancel_Reason}
              radeemedPoints={CardData?.ARIT_Norm_Points}
              availablePoints={agent?.tbl_ACAG_Agents?.ACAG_Available_Points}
              email={agent?.tbl_ACAG_Agents?.ACAG_Email}
              lastActivityDate={
                CardData?.tbl_ARLR_Rewards?.ARLR_Expiry_Date &&
                formatDate(CardData?.tbl_ARLR_Rewards?.ARLR_Expiry_Date)
              }
              productName={CardData?.tbl_ARLR_Rewards.ARLR_Title}
              requestDate={formatDate(
                CardData?.ARIT_Approved_Status === 1
                  ? CardData?.ARIT_Redemption_Date
                  : CardData?.ARIT_Approved_Status === 2
                  ? CardData?.ARIT_Approved_Date
                  : CardData?.ARIT_Approved_Status === 3
                  ? CardData?.ARIT_Cancelled_Date
                  : CardData?.ARIT_Approved_Status === 5
                  ? CardData?.ARAG_Redemption_Date
                  : ""
              )}
              key={"slide-drawer-rewardconfirm" + index}
              onHold={onHold}
              selectedVariant={CardData?.tbl_ARRV_Reward_Variants?.ARRV_Title}
              quantity={CardData?.ARIT_Quantity}
              title={CardData?.tbl_ARLR_Rewards?.ARLR_Title}
              imgSource={CardData?.tbl_ARLR_Rewards?.ARLR_Image_Path}
              name={agent?.tbl_ACAG_Agents?.ACAG_Name}
              status={CardData?.ARIT_Approved_Status}
              tbl_ORAD_Admins_ApprovedBy={
                CardData?.tbl_ORAD_Admins_ApprovedBy?.ORAD_Email
              }
              address={
                agent?.tbl_MSAD_Addresses?.MSAD_Address_Line1 +
                ", " +
                agent?.tbl_MSAD_Addresses?.MSAD_Address_Line2 +
                ", " +
                agent?.tbl_MSAD_Addresses?.MSAD_City +
                ", " +
                agent?.tbl_MSAD_Addresses?.MSAD_State +
                ", " +
                agent?.tbl_MSAD_Addresses?.MSAD_ZipCode
              }
            />
          ))}
        </Spin>
      </div>
    </Drawer>
  );
};

export default SideDrawer;
