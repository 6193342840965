import React, { useState } from "react";
import Report from "./components/report";
import AvatarIcon from "../../../../assets/icons/AvatarIcon.svg";
import FileIcon from "../../../../assets/icons/fileIcon.svg";
import AddAgentModal from "../Reports/components/addAgentModal";
import { saveAs } from "file-saver";
import {
  Button,
  Col,
  Dropdown,
  Row,
  Space,
  Spin,
  Typography,
  Form,
} from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import AdminAppLayout from "../../Layouts/AdminLayout";
import { dateFormat } from "../../utils/helpers";
import axios from "axios";

import {
  ALL_REPORTS_API_PDF,
  ALL_REPORTS_API_CSV,
  GET_AGENT_BY_ID_API
} from '../../../API/paths';

const { Text } = Typography;

function Reports() {
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const items = [
    {
      key: "1",
      label: "CSV",
    },
    {
      key: "2",
      label: "PDF",
    },
  ];
  const handleSubmit = async (values) => {
    console.log("values", values);
    if (values?.selected_agent) {
      try {
        setLoading(true);
        const response = await axios.get(
          `${GET_AGENT_BY_ID_API}?KeyId=${values?.selected_agent}`
        );
        setSelectedAgent({ ...response?.data?.result, values: values });
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    } else {
      setSelectedAgent({ values: values });
    }

    setOpen(false);
  };
  const onClick = async (val) => {
    console.log("val", val?.key);
    const agent_wise_report = `${val?.key === "2" ? ALL_REPORTS_API_PDF : ALL_REPORTS_API_CSV
      }?fromDate=${dateFormat(
        selectedAgent?.values.date[0],
        "YYYY-MM-DD"
      )}&toDate=${dateFormat(
        selectedAgent?.values.date[1],
        "YYYY-MM-DD"
      )}&agentKeyId=${selectedAgent?.values?.selected_agent}`;

    const all_agents_report = `${val?.key === "2" ? ALL_REPORTS_API_PDF : ALL_REPORTS_API_CSV
      }?fromDate=${dateFormat(
        selectedAgent?.values.date[0],
        "YYYY-MM-DD"
      )}&toDate=${dateFormat(selectedAgent?.values.date[1], "YYYY-MM-DD")}`;
    try {
      setLoading(true);
      const response = await axios.get(
        selectedAgent?.values?.selected_agent
          ? agent_wise_report
          : all_agents_report
      );
      if (val?.key === "1") {
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        saveAs(response?.data, "downloaded_file.pdf");
      }
      setLoading(false);
      setSelectedAgent(null);
      form.resetFields();
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <AdminAppLayout>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Space direction="vertical">
            <Row gutter={34}>
              <Col span={12}>
                <Report
                  open={open}
                  form={form}
                  status={true}
                  setOpen={setOpen}
                  handleSubmit={handleSubmit}
                  titleText={
                    selectedAgent?.ACAG_Name
                      ? selectedAgent?.ACAG_Name
                      : "Download Specific Agent Report"
                  }
                  iconUrl={
                    selectedAgent?.ACAG_Image_Path
                      ? selectedAgent?.ACAG_Image_Path
                      : AvatarIcon
                  }
                />
              </Col>
              <Col span={12}>
                <Report
                  open={open}
                  status={false}
                  form={form}
                  setOpen={setOpen}
                  handleSubmit={handleSubmit}
                  titleText="Download Complete Report"
                  iconUrl={FileIcon}
                />
              </Col>
            </Row>
            <Row gutter={34}>
              <Col span={24}>
                <div
                  style={{
                    background: "#F9FAFB",
                    borderRadius: 8,
                    borderColor: "#F9FAFB",
                    marginTop: 20,
                    opacity: !selectedAgent ? "70%" : "inherit",
                  }}
                >
                  <div
                    style={{
                      padding: 20,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: 14, fontWeight: 400 }}>
                      You can download report in PDF and CSV form <br /> by
                      clicking download option
                    </Text>
                    <Dropdown
                      disabled={!selectedAgent}
                      icon={<DownloadOutlined />}
                      menu={{
                        items,
                        onClick,
                      }}
                    >
                      <Button type="primary"> Download as</Button>
                    </Dropdown>
                  </div>
                </div>
              </Col>
            </Row>
          </Space>
        </div>
        <AddAgentModal
          form={form}
          status={true}
          open={open}
          setOpen={setOpen}
          handleSubmit={handleSubmit}
        />
      </AdminAppLayout>
    </Spin>
  );
}

export default Reports;
