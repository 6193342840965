import dayjs from "dayjs";

export const isPositiveInteger = (str) => {
  if (typeof str !== "string") {
    return false;
  }
  const num = Number(str);
  if (Number.isInteger(num) && num > 0) {
    return true;
  }

  return false;
};

export const formatDate = (value) => {
  dayjs.locale("en-US");
  return dayjs(value).format("MMM D, YYYY");
};

export const formateDateWithTime = (value) => {
  return dayjs(value).format("MMMM D, YYYY h:mm A");
};

export const dateFormat = (date, formate) => {
  return dayjs(date).format(formate);
};

export const formatDateDDMMYYYY = (value) => {
  dayjs.locale("en-US");
  return dayjs(dayjs(value).format("DD/MM/YYYY"), "DD/MM/YYYY");
};

export const getMonth = (date) => {
  return dayjs(date).format("MMM");
};

export const getYear = (date) => {
  return dayjs(date).format("YYYY");
};

export const getNameFormEmail = (str) => {
  return str.match(/^([^@]*)@/)[1];
};

export const getRandomString = () => {
  let r = (Math.random() + 1).toString(36).substring(7);
  return r;
};

export const getRandomNumber = () => {
  let r = Math.floor(Math.random() * 100000 + 1);
  return r;
};

export const getRandomPercentage = () => {
  let r = Math.floor(Math.random() * 100 + 1);
  return r;
};
export const getDateFromMonth = (monthName) => {
  // Create a date object with the current year and the given month
  const date = new Date(`${monthName} 1, ${new Date().getFullYear()}`);
  return { date };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getNewSearchParamStringWithArgs = ({
  page,
  pageSize,
  search,
  statusType,
  statusItem,
  category_keyids,
}) => {
  let searchParams = `?page=${page}&pageSize=${pageSize}`;
  if (search) {
    searchParams += `&filterName=${search}`;
  }
  if (statusType) {
    searchParams += `&statusType=${statusType}`;
  }
  if (statusItem) {
    searchParams += `&statusItem=${statusItem}`;
  }
  if (category_keyids) {
    searchParams += `&category_keyids=${category_keyids}`;
  }
  return searchParams;
};

export const generateSearchParamsGetter = (params) => {
  const page = params.get("page");
  const pageSize = params.get("pageSize");
  const search = params.get("filterName");
  const statusType = params.get("statusType");
  const statusItem = params.get("statusItem");
  const category_keyids = params.get("category_keyids");

  return () => {
    let paramsString = `?page=${page}&pageSize=${pageSize}`;
    if (search) {
      paramsString += `&filterName=${search}`;
    }
    if (statusType) {
      paramsString += `&statusType=${statusType}`;
    }
    if (statusItem) {
      paramsString += `&statusItem=${statusItem}`;
    }
    if (category_keyids) {
      paramsString += `&category_keyids=${category_keyids}`;
    }
    return paramsString;
  };
};

export const getSearchParams = (spObj) => {
  return [
    spObj.get("id"),
    spObj.get("page"),
    spObj.get("pageSize"),
    spObj.get("filterName"),
    spObj.get("statusType"),
    spObj.get("statusItem"),
    spObj.get("category_keyids"),
    spObj.get("open"),
  ];
};

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}
