export const GET_AGENT_BY_ID = "Agents/GetByKeyId";
export const ALL_AGENTS_API = "Agents/GetAll";
export const AGENTS_FILTER_DATA_API = "Agents/GetFilterList";
export const GET_AGENT_BY_ID_API = "Agents/GetByKeyId";
export const EDIT_AGENT_API = "Agents/Put";
export const FILE_DOWNLOAD_API = "Agents/ExportAgentsCsv";
export const FILE_DOWNLOAD_API_ALL = "Agents/ExportAllAgentsCsv";
export const AGENT_TOTAL_POINTS_API = "Agents/GetEarnedPointsByKeyId";

export const GET_ADMINS_BY_ID_API = "Admins/GetById";
export const ADMIN_PUT_API = "Admins/Put";
export const ADMINS_FILTER_DATA_API = "Admins/GetFilterList";
export const EDIT_ADMINS_API = "Admins/Put";
export const ADD_ADMIN_API = "Admins/Post";
export const LOGIN_ADMIN_API = "Admins/Validate";

export const REWARDS_FILTER_DATA_API = "Rewards/GetFilterList";
export const UPLOAD_IMAGE_API = "Rewards/UploadImage";
export const REWARD_POST_API = "Rewards/Post";
export const GET_REWARD_BYID = "Rewards/GetById";
export const PUT_REWARD_API = "Rewards/Put";

export const ALL_CATEGORIES_API = "Categories/GetAll";
export const ADD_CATEGORY_API = "Categories/Post";
export const GET_CATEGORY_BY_ID_API = "Categories/GetById";
export const DELETE_CATEGORY_API = "Categories/Delete";
export const EDIT_CATEGORY_API = "Categories/Put";
export const CATEGORY_FILTER_DATA_API = "Categories/GetFilterList";
export const TOP_CATEGORIES_API = "Categories/GetTop";

export const AGENTS_REWARDS_FILTER_DATA_API = "AgentsRewards/GetFilterList";
export const AGENT_DETAIL_API = "AgentsRewards/GetAgentRewardItems";
export const UPDATE_AGENT_ITEM_STATUS_API =
  "AgentsRewards/UpdateAgentRewardItemStatus";
export const UPDATE_AGENT_ALL_ITEM_STATUS_API =
  "AgentsRewards/UpdateAgentRewardStatus";
export const ORDER_STATUS_PUT = "AgentsRewards/UpdateAgentRewardDispatchStatus";
export const CART_HISTORY_API = "AgentsRewards/GetHistory";
export const AGENT_REWARD_POST = "AgentsRewards/Post";

export const ALL_REPORTS_API_PDF = "Reports/CompleteReportPDF";
export const ALL_REPORTS_API_CSV = "Reports/CompleteReportCSV";

export const ALL_POINTS_FROM_API = "PolicyPremium/GetAll";
export const GET_POINTS_FROM_BY_ID_API = "PolicyPremium/GetById";
export const EDIT_POINTS_FROM_API = "PolicyPremium/Put";
export const ADD_POINTS_FROM_API = "PolicyPremium/Post";
export const DELETE_POINTS_FROM_API = "PolicyPremium/Delete";

export const AGENT_NOTES = "Agent_Notes/GetByAgent_KeyId";
export const ADD_NOTES_API = "Agent_Notes/Post";

export const GET_ADMIN_CLAIM_DATA = "Auth/GetClaimData/ADMIN";
export const GET_AGENT_CLAIM_DATA = "Auth/GetClaimData/AGENT";

export const FILTER_DATA_FAV_API =
  "Agent_Reward_Favorite/GetByAgentKeyId?KeyId=";
export const ADD_FAVORITE_API = "Agent_Reward_Favorite/Post";
